import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierCommercial,
  getSupplierRoles,
  TSupplierCommercial,
  updateSupplierCommercial,
} from "@/http";
import { useAuth } from "@/store/auth";
import { useCommercials } from "@/store/commercials";
import { validateOnlyNumber } from "@/utils/keyboard";
import { isValidEmail } from "@/utils/validations/email";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { InputV2 } from "../shared/Input/InputV2";
import { SelectV2 } from "../shared/Input/SelectV2";
import { Modal } from "../shared/Modal/Modal";
import { useSubscription } from "@/hooks/useSubscription";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { PremiumFeatureIndicator } from "../subscription/PremiumFeatureIndicator";
import { FEAT_MAX_USERS, FEAT_USERS } from "@/constants/features";
import Select from "react-select";
import { useCountries } from "@/hooks/useCountry";
import { useAddresses } from "@/store/profile";
import { addressToString } from "@/utils/address";
import { singleSelectStyles } from "@/constants/input";

type TCommercialModal = {
  commercial?: any;
  onDone?: (commercial: TSupplierCommercial) => void;
  button?: ReactNode;
};
export const CommercialModal = ({
  commercial,
  onDone,
  button,
}: TCommercialModal) => {
  const [t] = useTranslation("global");
  const { getAddresses, loadingOrders, addresses } = useAddresses();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = commercial?.id
    ? {
        ...commercial,
        phone: (commercial?.phone?.toString() || "").slice(-10),
        phonePrefix: (commercial?.phone?.toString() || "").slice(0, -10),
      }
    : {};
  const methods = useForm({ defaultValues });
  const { getCommercials } = useCommercials();
  const { user } = useAuth();
  const { subscriptionValidations } = useSubscription();
  const validation = subscriptionValidations?.USERS;
  const remainingUsers = validation
    ? validation.maximum
      ? Math.max(0, validation.maximum - validation.current)
      : t("SUBSCRIPTION.UNLIMITED")
    : 0;
  const isEdit = !!commercial?.id;
  const { data, isValidating } = useSWR(
    [user.id, { page: 0, size: 1000 }, "getRoles"],
    getSupplierRoles
  );
  const roles = (data?.content || []).map(r => ({
    value: r.id || "",
    label: r.name,
  }));

  const countries = useCountries();
  const countryOptions = countries?.map(c => ({
    label: `${c.emoji} ${c.name}`,
    value: `+${c.phoneCode}`,
  }));

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        roleId,
        phonePrefix,
        warehouseId,
      } = values;
      let newCommercial;

      if (commercial?.id) {
        newCommercial = await updateSupplierCommercial(commercial.id, {
          firstName,
          lastName,
          email,
          phone: `${phonePrefix}${phone}`,
          roleId,
          warehouseId,
        });
      } else {
        newCommercial = await createSupplierCommercial({
          ...values,
          roleId,
          phone: `${phonePrefix}${phone}`,
          supplierId: user.id,
          warehouseId,
        });
      }

      getCommercials(user.id);
      setIsLoading(false);
      methods.reset(defaultValues);
      onDone?.(newCommercial);
      showToastSuccess(
        t(
          isEdit
            ? "COMMERCIALS.COMMERCIAL_UPDATED"
            : "COMMERCIALS.COMMERCIAL_CREATED"
        )
      );
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getAddresses(user.id ?? "", 0);
    }
  }, [isOpen]);

  return (
    <>
      {button ? (
        <div
          className="pointer"
          onClick={() => {
            methods.reset(defaultValues);
            setIsOpen(true);
          }}
        >
          {button}
        </div>
      ) : (
        <button
          className="primary lg"
          onClick={() => {
            methods.reset(defaultValues);
            setIsOpen(true);
          }}
        >
          {t(
            isEdit
              ? "COMMERCIALS.COMMERCIAL_EDIT"
              : "COMMERCIALS.COMMERCIAL_CREATE"
          )}
        </button>
      )}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="leftAlign">
          <h6 className="bolder">
            {t(
              isEdit
                ? "COMMERCIALS.COMMERCIAL_EDIT"
                : "COMMERCIALS.COMMERCIAL_CREATE"
            )}{" "}
            <PremiumFeatureIndicator feature={FEAT_USERS} />
            <PremiumFeatureIndicator feature={FEAT_MAX_USERS} />
          </h6>
          <div className="divider" />
          <div className="mbmd">
            {t("SUBSCRIPTION.REMAINING_USERS")}:{" "}
            <span className="purpleText bold">{remainingUsers}</span>
          </div>
          <DisabledZone when={validation?.create === false}>
            <FormProvider {...methods}>
              <div className="flexGap">
                <div className="col-6 col-sm-12 noPadding">
                  <InputV2
                    name="firstName"
                    label={t("COMMERCIALS.FIRST_NAME")}
                    required
                  />
                </div>
                <div className="col-6 col-sm-12 noPadding">
                  <InputV2 name="lastName" label={t("COMMERCIALS.LAST_NAME")} />
                </div>
              </div>
              <div className="flexGap">
                <div className="col-6 col-sm-12 noPadding">
                  <InputV2
                    name="email"
                    label={t("COMMERCIALS.EMAIL")}
                    required
                    validate={isValidEmail}
                    validMessage={t("COMMERCIALS.EMAIL_NOT_VALID")}
                  />
                </div>
                <div className="col-6 col-sm-12 noPadding">
                  <p>{t("COUNTRY")}</p>
                  <Select
                    isSearchable
                    name="phonePrefix"
                    defaultValue={countryOptions?.find(({ value }) =>
                      commercial?.phone?.startsWith(value)
                    )}
                    placeholder={t("SELECT_OPTION")}
                    required
                    options={countryOptions}
                    styles={singleSelectStyles}
                    isDisabled={!countries?.length}
                    onChange={({ value = "" }: any) => {
                      methods.setValue("phonePrefix", value);
                    }}
                  />
                </div>
              </div>
              <div className="flexGap">
                <div className="col-6 col-sm-12 noPadding">
                  <InputV2
                    name="phone"
                    type="text"
                    label={t("COMMERCIALS.PHONE")}
                    required
                    placeholder={t("COMMERCIALS.TEN_DIGITS")}
                    onKeyPress={validateOnlyNumber}
                    maxLength={10}
                    minLength={10}
                    validate={(phone: string) => phone.length === 10}
                    validMessage={t("COMMERCIALS.VALIDATIONS_PHONE")}
                  />
                </div>
                <div className="col-6 col-sm-12 noPadding">
                  <SelectV2
                    name="roleId"
                    label={t("COMMERCIALS.ROLE")}
                    required
                    choices={roles || []}
                    disabled={isValidating}
                  />
                </div>
              </div>
              <div className="flexGap">
                <div className="col-6 col-sm-12 noPadding">
                  {!loadingOrders && (
                    <SelectV2
                      name="warehouseId"
                      label={t("PURCHASES.ORDERS.WAREHOUSE")}
                      choices={addresses?.content?.map(address => ({
                        value: address?.id ?? "",
                        label: addressToString(address, true),
                      }))}
                    />
                  )}
                </div>
                <div className="col-6 col-sm-12 noPadding" />
              </div>
            </FormProvider>
          </DisabledZone>
          <div className="divider" />
          <div className="rigthAlign">
            <button disabled={isLoading} onClick={() => setIsOpen(false)}>
              {t("CLOSE")}
            </button>
            <button
              disabled={isLoading || !validation?.create}
              className="primary lg"
              onClick={methods.handleSubmit(handleSubmit)}
            >
              {t(isEdit ? "SAVE" : "CREATE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

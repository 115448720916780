import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createReversion } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEraser } from "react-icons/bi";

type TConfirmVoid = {
  creditId: number;
  transactionId: string;
  setDone: () => void;
};

export const ConfirmReversion = ({
  creditId,
  transactionId,
  setDone,
}: TConfirmVoid) => {
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await createReversion(creditId, transactionId).then(() => {
        showToastSuccess(t("CREDITS.TRANSACTIONS.REVERSION.SUCCESS"));
        setIsOpen(false);
        setDone();
      });
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BiEraser size={20} color="purple" onClick={() => setIsOpen(true)} />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <h6 className="bolder">{t("CREDITS.TRANSACTIONS.REVERSION.TITLE")}</h6>
        <div className="spacer" />
        <p>{t("CREDITS.TRANSACTIONS.REVERSION.DESCRIPTION")}</p>
        <div className="spacer" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("CANCEL")}
          </button>
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        </div>
      </Modal>
    </>
  );
};

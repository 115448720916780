import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { revertCoinsTransaction, TCoinTransaction } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbArrowsLeftRight } from "react-icons/tb";

export const RevertCoinsTransaction = ({
  transaction,
  onDone,
  sellerId,
}: {
  transaction: TCoinTransaction;
  onDone?: () => void;
  sellerId: string;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, commercial, isCommercial } = useAuth();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await revertCoinsTransaction(sellerId, transaction.id ?? "", {
        createdBy: (isCommercial ? commercial?.id : user.id) ?? "",
      });
      onDone?.();
      showToastSuccess(t("TRANSACTION_REVERTED"));
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className="mdFont outlined sm flexGap center gapSm"
        onClick={() => setIsOpen(true)}
      >
        <TbArrowsLeftRight />
        {t("REVERT")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        footer={
          <>
            <button disabled={isLoading} onClick={() => setIsOpen(false)}>
              {t("CANCEL")}
            </button>
            <button disabled={isLoading} className="primary" onClick={onSubmit}>
              {t("IM_SURE")}
            </button>
          </>
        }
      >
        <span className="bold">{t("WANNA_REVERT")}</span>
        <div className="spacer" />
      </Modal>
    </>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSupplierQuote } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

type TCancelQuote = {
  purchaseId: string;
};
export const CancelQuote = ({ purchaseId }: TCancelQuote) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await deleteSupplierQuote(purchaseId);
      showToastSuccess(t("QUOTE_DELETED"));
      setIsOpen(false);
      navigate("/purchases");
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button className="outlined" onClick={() => setIsOpen(true)}>
        {t("DELETE_QUOTE")}
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <div className="spacer" />
        <div className="spacer" />
        <h6 className="bold">{t("SURE_DELETE_QUOTE")}</h6>
        <div className="spacer" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("CANCEL")}
          </button>
          <button
            disabled={isLoading}
            className="primary lg"
            onClick={handleClick}
          >
            {t("IM_SURE")}
          </button>
        </div>
      </Modal>
    </>
  );
};

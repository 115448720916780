import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_SELL_IN } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { QUOTED } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const AcquisitionsListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  const [search, setSearch] = useSearchParams();
  const isProcurementFilter = search.get("isProcurement") === "true";
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const isQuotes = statusFilters["status"] === QUOTED;
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("ACQUISITIONS.LIST_TITLE"));
  }, [t]);

  const debouncedEffectFunction = debounce(() => {
    const payload = {
      ...statusFilters,
      status: "PENDING",
    };

    const newSearch = new URLSearchParams(search);
    newSearch.set("status", JSON.stringify(payload));
    newSearch.set("isProcurement", "true");
    setSearch(newSearch);
  }, 500);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  if (!hasFeature(FEAT_SELL_IN)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <PurchaseListActions />
      {!isQuotes && isProcurementFilter && <PurchaseList />}
    </>
  );
};

import { getSeeriApi, TCategory, TCategoryTree } from "@/http";
import useSWR from "swr";

type Props = {
  maxDeepLevel?: number;
};
type CategoryTree = TCategory & { subCategories: CategoryTree[] };
type TChoice = { id: string; name: string };
export const useCategoriesChoices = (
  { maxDeepLevel = Infinity }: Props,
  useMutate?: boolean
) => {
  const { data: categories, mutate } = useSWR("category-tree", () =>
    getSeeriApi()
      .get<CategoryTree[]>("/api/categories/v2", {
        params: { fullTree: "true" },
      })
      .then(res => res.data)
  );

  if (!categories) {
    if (useMutate) {
      return {
        data: [],
        mutate,
      };
    } else {
      return [];
    }
  }

  function getChoices(
    categories: TCategoryTree[],
    parentName: string,
    level: number
  ): TChoice[] {
    const choices = [];
    for (const category of categories) {
      let name = category.name;
      if (parentName) name = parentName + " - " + name;
      choices.push({ id: category.id, name });
      if (category.subCategories && maxDeepLevel - 1 > level) {
        const subChoices = getChoices(category.subCategories, name, level + 1);
        choices.push(...subChoices);
      }
    }
    return choices;
  }

  const choices = getChoices(categories, "", 0);

  if (useMutate) {
    return {
      data: choices,
      mutate,
    };
  } else {
    return choices;
  }
};

export const useCategoriesTree = () => {
  const { data: categories } = useSWR("category-tree", () =>
    getSeeriApi()
      .get<CategoryTree[]>("/api/categories/v2", {
        params: { fullTree: "true" },
      })
      .then(res => res.data)
  );

  if (!categories) return [];

  return categories;
};

import { getIntegrations } from "@/http";
import { SWR_INTEGRATIONS } from "@/pages/Integrations/constants";
import { useAuth } from "@/store/auth";
import useSWR from "swr";

export const useIntegrations = () => {
  const { user } = useAuth();
  return useSWR([user.id, SWR_INTEGRATIONS], getIntegrations);
};

export const useHasInvoiceIntegration = () => {
  const { data } = useIntegrations();
  if (!data) return false;

  return data?.content.some(
    integration =>
      ["INVOICING", "ACCOUNTING"].includes(integration.type) &&
      integration.credentialId
  );
};

export const getAccountingIntegration = () => {
  const { data } = useIntegrations();
  if (!data) return;

  const activeAccountingIntegration = data?.content.find(
    integration =>
      ["ACCOUNTING"].includes(integration.type) && integration.credentialId
  );

  return activeAccountingIntegration;
};

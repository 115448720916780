import { getInstance } from "../../core/axios-gateway";

const BASE_PATH = "/api/orders";

export const getPurchasePayments = async (purchaseId: string) => {
  return getInstance()
    .get<any[]>(`${BASE_PATH}/${purchaseId}/payments`)
    .then(res => res.data);
};

export const deletePurchasePayment = async (
  purchaseId: string,
  paymentId: string
) => {
  return getInstance()
    .delete<any[]>(`${BASE_PATH}/${purchaseId}/payments/${paymentId}`)
    .then(res => res.data);
};

export const createPurchasePayment = async (purchaseId: string, data: any) => {
  return getInstance()
    .post<any>(`${BASE_PATH}/${purchaseId}/payments`, data)
    .then(res => res.data);
};

export const updatePurchasePayment = async (
  purchaseId: string,
  paymentId: string,
  data: any
) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${purchaseId}/payments/${paymentId}`, data)
    .then(res => res.data);
};

export const getPurchasePaymentByID = async (
  purchaseId: string,
  paymentId: string
) => {
  return getInstance()
    .get<any[]>(`${BASE_PATH}/${purchaseId}/payments/${paymentId}`)
    .then(res => res.data);
};

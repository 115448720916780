import { CommercialDetail } from "@/components/commercials/visits/CommercialVisitsList";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import {
  addTicketComment,
  changeTicketResponsible,
  TTicketDetail,
} from "@/http";
import { CommercialSelection } from "@/pages/Clients/ClientList";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const TicketCommercialUpdate = ({
  ticket,
  onDone,
}: {
  ticket: TTicketDetail;
  onDone: (...args: any) => void;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [commercial, setCommercial] = useState<any>();
  const methods = useForm();

  const onSubmit = async (values: any) => {
    try {
      if (!commercial?.value) {
        showToastError(t("SELECT_RESPONSIBLE"));
        return;
      }
      const payload: any = {
        comment: values.comment,
        visibility: "INTERNAL",
        attachments: [],
      };
      await addTicketComment(ticket.id, payload);
      await changeTicketResponsible(ticket.id, commercial?.value ?? "");
      onDone();
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <div className="contentInline">
        <span className="bold">{t("RESPONSIBLE")}</span>
        {!["APPROVED", "REJECTED"].includes(ticket.status) && (
          <span
            className="purpleText mdFont pointer"
            onClick={() => setIsOpen(true)}
          >
            {t("CHANGE_RESPONSIBLE")}
          </span>
        )}
      </div>
      <div className={styles.responsible}>
        {ticket?.commercialId ? (
          <CommercialDetail id={ticket?.commercialId} />
        ) : (
          <p>{t("NO_RESPONSIBLE_RELATED")}</p>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("CHANGE_RESPONSIBLE")}
        size="sm"
        showOverflow
        footer={
          <>
            <button onClick={() => setIsOpen(false)}>{t("CANCEL")}</button>
            <button
              className="primary lg "
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <div className="showOverflow">
          <FormProvider {...methods}>
            <CommercialSelection
              values={null}
              onValueChange={(item: any) => setCommercial(item)}
              label={<span className="bold">{t("RESPONSIBLE")}</span>}
            />
            <div className="spacer" />
            <TextArea
              required
              rows={5}
              name="comment"
              label={<span className="bold">{t("COMMENT")}</span>}
            />
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};

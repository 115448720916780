import { EditVariantModal } from "@/components/products/ProductsTable/EditVariantModal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { getProductById } from "@/http";
import { useProducts } from "@/store/products";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

export const VariantDetailPage = () => {
  const { setTitle } = useHeaderTitle();
  const { id } = useParams();
  const { setSelectedVariant, selectedVariantEdit } = useProducts();
  const [isLoading, setLoading] = useState(false);

  const getProduct = async (id: string) => {
    setLoading(true);
    setTitle("-", "/products");
    const product = await getProductById(id);
    setSelectedVariant(product);
    setTitle(
      `${product?.category?.name ?? ""} » ${product?.name}`,
      "/products",
      undefined,
      undefined
    );
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getProduct(id);
    }
  }, [id]);

  if (isLoading || Object.keys(selectedVariantEdit ?? {}).length <= 0) {
    return <Spinner />;
  }

  return (
    <EditVariantModal
      onDone={(variant: any) => {
        setTitle(
          `${variant?.productUpdated?.category?.name ?? ""} » ${
            variant?.productUpdated?.name
          }`,
          "/products"
        );
        setSelectedVariant({});
        const getVariant = debounce(() => {
          getProduct(id ?? "");
        }, 300);
        getVariant();
      }}
    />
  );
};

import { useSupplierAddressById } from "@/hooks/useSupplier";
import { useTranslation } from "react-i18next";

type TWarehouseName = {
  id: string;
  useOnlyName?: boolean;
};
export const WarehouseName = ({ id, useOnlyName }: TWarehouseName) => {
  const address = useSupplierAddressById(id);
  const [t] = useTranslation("global");

  if (!address) {
    return <span className="redText">{t("WAREHOUSE_DELETED")}</span>;
  }

  if (useOnlyName && address?.name) {
    return <>{address.name}</>;
  }

  return (
    <>{`${address?.addressLine1 ?? "-"}, ${address.addressLine2 ?? "-"} ;
${address.city ?? "-"}, ${address.country ?? "-"}`}</>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import {
  assignProductToSegment,
  getProductById,
  getSegments,
  TProductSupplier,
  TProductSupplierSegment,
  TSegment,
  unassignProductFromSegment,
} from "@/http";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { FormProvider, useForm } from "react-hook-form";
import useSWR from "swr";
import { useProducts } from "@/store/products";
import { AiOutlineEdit } from "react-icons/ai";
import { Table, TableCell } from "../clients/ui/Table/Table";
import { formatCurrency } from "@/utils/currency";
import { InputV2 } from "../shared/Input/InputV2";
import { validateDecimalNumber } from "@/utils/keyboard";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { PremiumFeatureIndicator } from "../subscription/PremiumFeatureIndicator";
import { FEAT_CLIENT_SEGMENTATION } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";

type ProductSegmentsProps = {
  productSupplier: TProductSupplier;
};

export const ProductSegments = ({ productSupplier }: ProductSegmentsProps) => {
  const [t] = useTranslation("global");
  const refresh = useRefreshPageVariant();
  const { data: segments } = useSWR("segments", getSegments);
  const { hasFeature } = useSubscription();

  if (!segments) {
    return null;
  }

  const records = segments.map(segment => {
    const psSegment = productSupplier.segments?.find(
      s => s.segmentId === segment.id
    );
    return {
      id: segment.id,
      name: segment.name,
      wholesalePrice:
        typeof psSegment?.wholesalePrice === "number"
          ? formatCurrency(psSegment.wholesalePrice)
          : "-",
      unitPrice:
        typeof psSegment?.unitPrice === "number"
          ? formatCurrency(psSegment.unitPrice)
          : "-",
      actions: (
        <>
          <ProductSegmentButton
            segment={segment}
            productSupplier={productSupplier}
            productSegment={psSegment}
            onDone={refresh}
          />
        </>
      ),
    };
  });

  return (
    <div style={{ overflowX: "scroll", width: "100%" }}>
      <h2 className="bold">
        {t("SEGMENTS.TITLE")}{" "}
        <PremiumFeatureIndicator feature={FEAT_CLIENT_SEGMENTATION} />
      </h2>
      <DisabledZone when={!hasFeature(FEAT_CLIENT_SEGMENTATION)}>
        <Table records={records}>
          <TableCell label={t("SEGMENTS.SEGMENT")} source="name" />
          <TableCell
            label={t("SEGMENTS.WHOLESALE_PRICE")}
            source="wholesalePrice"
          />
          <TableCell label={t("SEGMENTS.UNIT_PRICE")} source="unitPrice" />
          <TableCell label="" source="actions" />
        </Table>
      </DisabledZone>
    </div>
  );
};

const ProductSegmentButton = ({
  segment,
  productSupplier,
  productSegment,
  onDone,
}: {
  segment: TSegment;
  productSupplier: TProductSupplier;
  productSegment?: TProductSupplierSegment;
  onDone?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
        className={"outlined"}
        title={t("EDIT")}
      >
        <AiOutlineEdit />
      </button>

      {createPortal(
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <h6 className="bold">
            {t("SEGMENTS.SEGMENT")}:{" "}
            <span className="greenText">{segment.name}</span>
          </h6>
          <div className="divider" />
          <div className="spacer" />
          <ProductSegmentForm
            segment={segment}
            productSupplier={productSupplier}
            productSegment={productSegment}
            onDone={() => {
              setIsOpen(false);
              onDone?.();
            }}
          />
        </Modal>,
        document.body
      )}
    </>
  );
};

const ProductSegmentForm = ({
  segment,
  productSupplier,
  productSegment,
  onDone,
}: {
  segment: TSegment;
  productSupplier: TProductSupplier;
  productSegment?: TProductSupplierSegment;
  onDone: () => void;
}) => {
  const [t] = useTranslation("global");

  const defaultValues = {
    wholesalePrice: productSegment?.wholesalePrice,
    unitPrice: productSegment?.unitPrice,
  };

  const methods = useForm({ defaultValues });
  const { isSubmitting } = methods.formState;

  const handleSubmit = async (values: typeof defaultValues) => {
    try {
      if (productSegment) {
        await unassignProductFromSegment(
          productSupplier.id,
          productSegment.segmentId
        );
      }
      if (Object.values(values).some(Boolean)) {
        await assignProductToSegment(productSupplier.id, segment, values);
      }
      showToastSuccess(t("SAVED"));
      methods.reset();
      onDone();
    } catch (error) {
      showToastError(error);
    }
  };

  const handleUnassign = async () => {
    try {
      if (productSegment) {
        await unassignProductFromSegment(
          productSupplier.id,
          productSegment.segmentId
        );
      }
      showToastSuccess(t("SAVED"));
      onDone();
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={event => {
          event.stopPropagation(); // stops react synthetic event propagates outside portal
          methods.handleSubmit(handleSubmit)(event);
        }}
      >
        <InputV2
          name="wholesalePrice"
          label={t("SEGMENTS.WHOLESALE_PRICE")}
          onKeyPress={validateDecimalNumber}
        />

        <InputV2
          name="unitPrice"
          label={t("SEGMENTS.UNIT_PRICE")}
          onKeyPress={validateDecimalNumber}
        />

        <div className="divider" />
        <div className="spacer" />
        <div className="contentInline">
          {productSegment && (
            <button
              type="button"
              className="redText"
              onClick={methods.handleSubmit(handleUnassign)}
              disabled={isSubmitting}
            >
              {t("DELETE")}
            </button>
          )}
          <button
            type="submit"
            className="primary"
            disabled={isSubmitting}
            style={{ marginLeft: "auto" }}
          >
            {t("SAVE")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

const useRefreshPageVariant = () => {
  const { selectedVariantEdit, setSelectedVariant } = useProducts();
  const refresh = async () => {
    if (!selectedVariantEdit) return;
    const product = await getProductById(selectedVariantEdit.id);
    setSelectedVariant(product);
  };
  return refresh;
};

import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import format from "date-fns/format";
import { ReactNode } from "react";
import styles from "./VerticalTimeline.module.scss";

type TTimelineEvent = {
  date: string;
  title: string | ReactNode;
  description: string | ReactNode;
};

type TVerticalTimeline = {
  events: TTimelineEvent[];
};

const VerticalTimeline = ({ events }: TVerticalTimeline) => {
  return (
    <div className={styles.verticalTimeline}>
      {events.map((event, index) => (
        <div className={styles.timelineEvent} key={index}>
          <div className={`col-4 noPadding textLine ${styles.eventDate}`}>
            {format(new Date(event.date), isUSA ? USA_DATE_HOUR : DATE_HOUR)}
          </div>
          <div className={`col-8 noPadding ${styles.eventContent}`}>
            {event.title && (
              <h3 className={styles.eventTitle}>{event.title}</h3>
            )}
            {event.description && (
              <p className={styles.eventDescription}>{event.description}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VerticalTimeline;

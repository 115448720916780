import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import VerticalTimeline from "@/components/shared/VerticalTimeline/VerticalTimeline";
import { getSubpurchaseModifications } from "@/http";
import { getSupplierDeliveryProviderByCode } from "@/http/resources/suppliers/delivery-provider";
import { get } from "lodash-es";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoEyeOutline } from "react-icons/io5";
import useSWR from "swr";

export const SubPurchaseModifications = ({ id }: { id: string }) => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const { data } = useSWR(
    id
      ? [
          id,
          { size: 100, page: 0, sort: "createdAt,desc" },
          "sub-purchase-events",
        ]
      : null,
    getSubpurchaseModifications
  );

  if (!data?.content?.length) {
    return null;
  }

  return (
    <>
      <ToolTip title={t("SHOW_TIMELINE")}>
        <span onClick={() => setOpen(true)} className="circleButton pointer">
          <IoEyeOutline />
        </span>
      </ToolTip>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)} size="sm">
        <div className="mdFont">
          <VerticalTimeline
            events={data?.content?.map((modification: any) => ({
              date: modification?.createdAt,
              title: t(modification?.event ?? ""),
              description: (
                <ProviderStatusName
                  status={get(
                    modification,
                    "data.newTracking.providerDeliveryStatus"
                  )}
                  provider={get(
                    modification,
                    "data.newTracking.deliveryProvider"
                  )}
                />
              ),
            }))}
          />
        </div>
      </Modal>
    </>
  );
};

const ProviderStatusName = ({
  provider,
  status,
}: {
  provider: string;
  status: string;
}) => {
  const { data: deliveryProviderStatus } = useSWR(
    provider ? [provider, "supplier-delivery-providers"] : null,
    getSupplierDeliveryProviderByCode
  );

  if (!deliveryProviderStatus?.length) {
    return null;
  }

  return (
    <>
      {
        deliveryProviderStatus.find(dp => dp?.providerDeliveryStatus === status)
          ?.providerDeliveryStatusName
      }
    </>
  );
};

import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { QUOTED } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const PurchasesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const [search, setSearch] = useSearchParams();
  const isProcurementFilter = search.get("isProcurement") === "true";
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const isQuotes = statusFilters["status"] === QUOTED;

  const debouncedEffectFunction = debounce(() => {
    const payload = {
      ...statusFilters,
      status: "",
    };

    const newSearch = new URLSearchParams(search);
    newSearch.set("status", JSON.stringify(payload));
    newSearch.set("isProcurement", "false");
    setSearch(newSearch);
  }, 500);

  useEffect(() => {
    setTitle(t("PURCHASES.LIST.TITLE"));
  }, [t]);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  return (
    <>
      <PurchaseListActions />
      {!isQuotes && !isProcurementFilter && <PurchaseList />}
    </>
  );
};

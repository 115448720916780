import { Checkbox } from "@/components/shared/Input/Checkbox";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { isUSA } from "@/constants/core";
import { customToast, showToastError } from "@/hooks/useToast";
import { createPendingInvoice, getInvoiceTypes } from "@/http";
import { useAuth } from "@/store/auth";
import { useOrderFilter, useOrders } from "@/store/orders";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export const ModalPendingInvoice = () => {
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuth();
  const { data: types } = useSWR([user.id, "invoice-types"], getInvoiceTypes);
  const methods = useForm();
  const {
    filter: activeFilter,
    idFilter,
    startDateFilter,
    endDateFilter,
    sortBy,
    sortDirection,
  } = useOrderFilter();

  const { getOrders, ordersIdToInvoice, currentPage } = useOrders();

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      let payload;
      if (isUSA) {
        payload = {
          subPurchaseId: ordersIdToInvoice ?? [],
          invoiceTypeId: "",
          sendToTaxCollector: false,
          notifyClientByEmail: false,
        };
      } else {
        payload = {
          subPurchaseId: ordersIdToInvoice ?? [],
          invoiceTypeId: values?.invoiceTypeId ?? null,
          sendToTaxCollector: values?.sendToTaxCollector,
          notifyClientByEmail: values?.notifyClientByEmail,
        };
      }
      const response = await createPendingInvoice(payload);
      customToast({ title: JSON.stringify(response.data), status: "warn" });
      setOpen(false);
      getOrders(
        user,
        activeFilter,
        idFilter,
        startDateFilter,
        endDateFilter,
        currentPage,
        sortBy,
        sortDirection
      );
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="primary" onClick={() => setOpen(true)}>
        {t("CREATE_INVOICE_SELECTED")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t("CREATE_INVOICE_SELECTED")}
        size="sm"
        footer={
          <>
            <button onClick={() => setOpen(false)} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("CREATE_INVOICES")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          {!isUSA ? (
            <div>
              {types && types?.length > 0 && (
                <SelectV2
                  required
                  name="invoiceTypeId"
                  label={t("INVOICE_TYPE")}
                  choices={types?.map(option => ({
                    value: option.value,
                    label: option.text,
                  }))}
                />
              )}
              <div className="spacer" />

              <Checkbox name="sendToTaxCollector" label={t("SEND_TAXES")} />
              <Checkbox
                name="notifyClientByEmail"
                label={t("NOTIFY_CLIENT_INVOICE")}
              />
            </div>
          ) : (
            <p>{t("CREATE_INVOICE_SELECTED_DESC")}</p>
          )}
        </FormProvider>
      </Modal>
    </>
  );
};

import { useAuth } from "@/store/auth";
import { hasPermission } from "@/utils/validations/permissions";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import styles from "./styles.module.scss";
import { onboardingSelectors } from "@/components/onboarding/selectors";

export const PurchaseListActions = () => {
  const [t] = useTranslation("purchases");
  const { isCommercial, role } = useAuth();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const isProcurementFilter = search.get("isProcurement") === "true";

  if (!hasPermission(isCommercial, role, ["action.purchase.create"])) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <div className="rigthAlign">
          {isProcurementFilter ? (
            <button
              className="outlined"
              onClick={() => navigate("/acquisitions/create")}
            >
              {t("CREATE_OWN_PURCHASE")}
            </button>
          ) : (
            <button
              data-onboarding={onboardingSelectors.createPurchase}
              className="primary"
              onClick={() => navigate("/purchases/create")}
            >
              {t("ACTIONS.CREATE")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import { BrandFilterInput } from "./ProductsGroupFilters";
import { BulkActivation } from "./BulkImport/BulkActivation";
import { onboardingSelectors } from "@/components/onboarding/selectors";

export const ProductsGroupActions = ({
  mutate,
}: {
  mutate: (...args: any) => void;
}) => {
  const [t] = useTranslation("products-page");
  const [search, setSearch] = useSearchParams();

  const tabCurrentFilter = search.get("tab");
  const isStockFilter = search.get("isStock") === "true";
  const isNotStockFilter = search.get("isNotStock") === "true";

  const handleStock = () => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("isStock", (!isStockFilter).toString());
    newSearch.set("isNotStock", "false");
    setSearch(newSearch);
  };
  const handleNoStock = () => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("isNotStock", (!isNotStockFilter).toString());
    newSearch.set("isStock", "false");
    setSearch(newSearch);
  };

  return (
    <>
      <div className={`${styles.container} ${styles.actions}`}>
        {!tabCurrentFilter && (
          <div className={styles.actionsContent}>
            <CheckboxButton
              checked={isStockFilter}
              onChange={handleStock}
              label={
                <span className={`${styles.checkboxLabel} textLine`}>
                  {t("WITH_STOCK")}{" "}
                </span>
              }
            />
            <CheckboxButton
              checked={isNotStockFilter}
              onChange={handleNoStock}
              label={
                <span className={`${styles.checkboxLabel} textLine`}>
                  {t("WITHOUT_STOCK")}{" "}
                </span>
              }
            />
          </div>
        )}
        <div className={styles.actionsContent}>
          <BrandFilterInput />
          <div className={styles.buttons}>
            {!tabCurrentFilter && <BulkActivation mutate={mutate} />}
            <button
              data-onboarding={onboardingSelectors.addProduct}
              className="primary md"
            >
              <Link to="/products/create/new">{t("ACTIONS.ADD_GROUP")}</Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

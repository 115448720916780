import { getPaymentProviders, TPaymentProvider } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { PaymentProviderForm } from "./PaymentProviderForm";
import clsx from "clsx";
import { PaymentProviderDelete } from "./PaymentProviderDelete";
import { Card } from "@/components/shared/Card/Card";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

export const PaymentProviders = () => {
  const [t] = useTranslation("global");

  const { data: supplierPaymentProviders = [], mutate } = useSWR(
    "supplierPaymentProviders",
    getPaymentProviders
  );

  return (
    <Card>
      <div className="contentInline">
        <span className="bolder">{t("PAYMENT_PROVIDERS.TITLE")}</span>
        <div className="rigthAlign">
          <PaymentProviderForm onDone={() => mutate()} />
        </div>
      </div>
      <div className="spacer" />
      <div>
        <div>
          {supplierPaymentProviders.map((paymentProvider: TPaymentProvider) => (
            <div
              key={paymentProvider.id}
              className={clsx(styles.providersContainer, styles.providersCard)}
            >
              <div>
                <p className="bold">{paymentProvider.code}</p>
                <p>{paymentProvider.description}</p>
                <p className="flexGap center">
                  <span
                    className={clsx({
                      ["greenText"]: paymentProvider?.allowInSellIn,
                      ["redText"]: !paymentProvider?.allowInSellIn,
                    })}
                  >
                    {paymentProvider?.allowInSellIn ? (
                      <BsFillCheckCircleFill />
                    ) : (
                      <BsFillXCircleFill />
                    )}
                  </span>
                  {t("ALLOW_IN_SELL_IN")}
                </p>
                <p className="flexGap center">
                  <span
                    className={clsx({
                      ["greenText"]: paymentProvider?.allowInSellOut,
                      ["redText"]: !paymentProvider?.allowInSellOut,
                    })}
                  >
                    {paymentProvider?.allowInSellOut ? (
                      <BsFillCheckCircleFill />
                    ) : (
                      <BsFillXCircleFill />
                    )}
                  </span>
                  {t("ALLOW_IN_SELL_OUT")}
                </p>
              </div>
              <div className={styles.actions}>
                <PaymentProviderForm
                  iconButton
                  paymentProvider={paymentProvider}
                  onDone={() => mutate()}
                />
                <PaymentProviderDelete
                  iconButton
                  paymentProvider={paymentProvider}
                  onDone={() => mutate()}
                />
              </div>

              <div className="rigthAlign"></div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const PurchaseOrderUnitRates = ({ products }: { products: any[] }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  if (!products?.length) {
    return null;
  }

  return (
    <>
      <button className="purple" onClick={() => setIsOpen(true)}>
        {t("SEE_PRODUCT_CONVERSION")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("PRODUCT_CONVERSION")}
        footer={
          <button className="primary lg" onClick={() => setIsOpen(false)}>
            {t("CLOSE")}
          </button>
        }
      >
        <table className={clsx(styles.table, "mdFont")}>
          <thead>
            <th>{t("PRODUCT")}</th>
            <th>{t("QUANTITY")}</th>
            <th>{t("STOCK_ADDED")}</th>
          </thead>
          <tbody>
            {products.map(p => (
              <tr key={p.id} className={styles.row}>
                <td className="bold">{p.name}</td>
                <td>{p.quantity || 0}</td>
                <td>{(p.quantity || 0) * (p.unitRate || 1)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

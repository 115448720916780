import { TPrePurchase } from "@/http";
import { PriceLabel } from "./PriceLabel";
import styles from "./Prepurchase.module.scss";
import { useTranslation } from "react-i18next";
import { ShipLabel } from "./ShipLabel";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { isUSA } from "@/constants/core";

type TPricing = {
  prepurchase: TPrePurchase;
  ligthStyle?: boolean;
  ligthStyle2?: boolean;
  editShipping?: boolean;
  editCoupon?: boolean;
  showTotalUnits?: boolean;
};
export const Pricing = ({
  prepurchase,
  ligthStyle = false,
  ligthStyle2 = false,
  editShipping = false,
  editCoupon = false,
  showTotalUnits = false,
}: TPricing) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const subTotal = prepurchase?.subtotal ?? 0;

  const paymentFee = prepurchase?.paymentFee ?? 0;

  const subTotalWithDiscounts = prepurchase?.subTotalWithDiscounts;
  const shipping = prepurchase?.shipping;
  const pendingToPay = prepurchase?.pendingToPay ?? prepurchase?.total;

  const partialMethodAmount = prepurchase?.partialMethodAmount ?? 0;

  const discountByCoupon = prepurchase?.discountByCoupon ?? 0;
  const discountByOffer = prepurchase?.discountByOffer ?? 0;
  const discountByProducts = prepurchase?.discountByProducts ?? 0;
  const discountByGlobalOffer = prepurchase?.discountByGlobalOffer ?? 0;
  const discountByProfit = prepurchase?.discountByProfit ?? 0;
  const totalTaxes = prepurchase?.totalTaxes ?? 0;

  const totalUnits = (prepurchase?.products || []).reduce(
    (sum, item) => sum + (item?.quantity || 0),
    0
  );

  return (
    <div className={`${styles.pricing} ${ligthStyle ? styles.ligthStyle : ""}`}>
      {editCoupon && <InputV2 name="coupon" label={t("COUPON")} />}
      {showTotalUnits && (
        <PriceLabel
          name={tGlobal("TOTAL_UNITS")}
          price={totalUnits}
          noCurrency
          bold
        />
      )}
      {Boolean(subTotal) && (
        <PriceLabel
          name={t("SUBTOTAL")}
          price={subTotal}
          hideDivider={ligthStyle2}
        />
      )}
      {Boolean(discountByProducts) && (
        <PriceLabel
          name={t("PRODUCTS_DISCOUNT")}
          price={discountByProducts}
          negative
          hideDivider={ligthStyle2}
        />
      )}
      <PriceLabel
        name={t("OFFER_DISCOUNT")}
        price={discountByOffer}
        negative
        hideDivider={ligthStyle || ligthStyle2}
        green={ligthStyle2}
        bold={ligthStyle2}
      />
      <PriceLabel
        name={
          <span className="centerVAlign ">{t("GLOBAL_OFFER_DISCOUNT")}</span>
        }
        price={discountByGlobalOffer}
        negative
        hideDivider={ligthStyle || ligthStyle2}
      />
      <PriceLabel
        name={t("EARNING_DISCOUNT")}
        price={discountByProfit}
        negative
        hideDivider={ligthStyle || ligthStyle2}
      />

      <PriceLabel
        name={t("PARTIAL_METHOD_DISCOUNT")}
        price={partialMethodAmount}
        negative
        hideDivider={ligthStyle || ligthStyle2}
      />
      <PriceLabel
        name={`${t("COUPON_DISCOUNT")} ${prepurchase?.couponInfo?.code}`}
        price={discountByCoupon}
        negative
        hideDivider={ligthStyle || ligthStyle2}
      />
      {subTotal != subTotalWithDiscounts && (ligthStyle || !ligthStyle2) && (
        <div className="divider" />
      )}
      {subTotal != subTotalWithDiscounts && (
        <PriceLabel
          name={t("SUBTOTAL_DISCOUNT")}
          price={subTotalWithDiscounts}
          hideDivider
        />
      )}
      <PriceLabel
        name={t("CHARGE_PAYMENT")}
        price={paymentFee}
        hideDivider={ligthStyle || ligthStyle2}
      />

      <PriceLabel
        name={isUSA ? tGlobal("TAX") : "IVA"}
        price={totalTaxes}
        bold
        hideDivider={ligthStyle || ligthStyle2}
        green
      />
      {editShipping ? (
        <ShipLabel
          editable={editShipping}
          name={`${t("SHIP")} ${
            prepurchase?.couponInfo?.type === "FREE_SHIPPING"
              ? `(${t("COUPON")}: ${prepurchase.couponInfo.code})`
              : ""
          }`}
          price={shipping}
          showZero
          hideDivider={ligthStyle || ligthStyle2}
        />
      ) : (
        <PriceLabel
          name={`${t("SHIP")} ${
            prepurchase?.couponInfo?.type === "FREE_SHIPPING"
              ? `(${t("COUPON")}: ${prepurchase.couponInfo.code})`
              : ""
          }`}
          price={shipping}
          showZero
          hideDivider={ligthStyle || ligthStyle2}
        />
      )}

      {ligthStyle2 && (
        <>
          <div className="spacer" />
        </>
      )}
      <PriceLabel
        name="Total"
        price={pendingToPay}
        bold
        hideDivider={ligthStyle || ligthStyle2}
        purpleResult={ligthStyle2}
        showZero
      />
    </div>
  );
};

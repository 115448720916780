import { PurchaseShow } from "@/components/purchases/PurchaseShow/PurchaseShow";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { QUOTED } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const PurchasesShowPage = () => {
  const { setTitle } = useHeaderTitle();
  const { id = "" } = useParams();
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { purchase, getPurchase } = usePurchase();

  useEffect(() => {
    const getData = () => {
      if (id) {
        getPurchase(id);
      }
    };

    getData();

    const timerId = setInterval(getData, 60000);

    return () => clearInterval(timerId);
  }, [id]);

  useEffect(() => {
    setTitle(
      `${t(
        purchase?.status === QUOTED
          ? "QUOTES.DETAIL.TITLE"
          : purchase?.supplierId === user.id
          ? "PURCHASES.DETAIL.TITLE"
          : "PURCHASES.ORDERS.DETAIL_TITLE"
      )} » ${purchase?.purchaseNumber ?? "-"}`,
      purchase?.status === QUOTED
        ? "/quotes"
        : purchase?.supplierId === user.id
        ? "/purchases"
        : "/acquisitions",
      "",
      null,
      false
    );
  }, [t, purchase]);

  if (!Object.keys(purchase).length) return <Spinner />;

  return (
    <>
      <PurchaseShow purchase={purchase} />
    </>
  );
};

import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { TextArea } from "@/components/shared/Input/TextArea";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { showToastError } from "@/hooks/useToast";
import { createTicket, TPurchase } from "@/http";
import { CommercialSelection } from "@/pages/Clients/ClientList";
import { uploadImageTicketObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TicketCreatedModal } from "./TicketCreatedModal";
import { TicketProducts } from "./TicketProducts";

type TTicketCreationForm = {
  purchase: TPurchase;
};
export const TicketCreationForm = ({ purchase }: TTicketCreationForm) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState<any>(null);
  const methods = useForm();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const files = methods.watch("files") || [];
  const { type, purchaseId } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { products = [] } = values;
      const filteredProducts = products
        .filter((p: any) => p?.selected)
        .map((p: any) => ({ id: p.id, quantity: p.quantity }));

      if (!Object.keys(filteredProducts).length) {
        showToastError({ message: t("CHOOSE_ONE_PRODUCT") });
        return;
      }

      if (!files?.length) {
        showToastError({ message: t("ADD_EVIDENCE") });
        return;
      }

      const payload: any = {
        purchaseId,
        products: filteredProducts,
        sellerId: purchase.client.id,
        attachments: [],
        type: "PURCHASE",
        requirement: values.type,
        description: values.reason,
      };

      if (files.length) {
        await Promise.all(files?.map(uploadImageTicketObject)).then(images => {
          payload.attachments = images;
        });
      }

      const data = await createTicket(payload);
      methods.reset();
      setIsOpen(true);
      setTicket(data);
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="flexGap flexSmColumn">
          <div className="col-6 col-sm-12">
            {type !== "PURCHASE_ORDERS" && (
              <>
                <CommercialSelection
                  values={null}
                  onValueChange={(item: any) =>
                    methods.setValue("commercialId", item?.value ?? "")
                  }
                  label={
                    <span className="bold">{t("COMMERCIAL_RESPONSIBLE")}</span>
                  }
                />
                <div className="spacer" />
              </>
            )}
            <TicketProducts purchase={purchase} />
            <div className="spacer" />
            <TextArea
              name="reason"
              label={<span className="bold">{t("TELL_US_WHAT_HAPPEN")}</span>}
              rows={6}
              required
            />
          </div>
          <div className="dividerVertical" />
          <div className="col-6 col-sm-12">
            <RadioGroupV2
              color="purple"
              required
              name={"type"}
              label={<span className="bold">{t("TYPE_LABEL")}</span>}
              inline
              options={[
                { label: t("RETURN"), value: "RETURN" },
                { label: t("REFUND"), value: "REFUND" },
              ]}
            />
            <div className="spacer" />
            <p className="bold">{tGlobal("UPLOAD_FILE")}</p>
            <DragAndDropFile
              minifiedStyle
              accept="image/png,image/jpeg"
              files={files}
              setFiles={files => methods.setValue("files", files)}
            />
            {files.length > 0 && (
              <>
                <div className="spacer" />
                <p className="bold">{t("EVIDENCE")}</p>
              </>
            )}
            <ImagesList
              images={files}
              setImages={files => methods.setValue("files", files)}
              showName
            />
          </div>
        </div>
      </FormProvider>
      <div className="spacer" />
      <div className="spacer" />
      <div className="rigthAlign">
        <button
          disabled={isLoading}
          onClick={() => {
            navigate("/tickets");
          }}
          className="lg outlined"
        >
          {t("CANCEL")}
        </button>
        <button
          className="primary lg"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {t("CREATE_TICKET")}
        </button>
      </div>
      {isOpen && ticket && (
        <TicketCreatedModal isOpen={isOpen} ticket={ticket} />
      )}
    </>
  );
};

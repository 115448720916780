import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deletePurchase, TPurchase } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

type Props = {
  purchase: TPurchase;
};
export const DeletePurchase = ({ purchase }: Props) => {
  const [t] = useTranslation("global");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deletePurchase(purchase.id);
      setModalOpen(false);
      navigate("/acquisitions");
      showToastSuccess(t("ORDER_DELETED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <button className="outlined" onClick={() => setModalOpen(true)}>
          {t("DELETE_ORDER")}
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        title={t("DELETE_ORDER")}
        footer={
          <>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </>
        }
      >
        <div>
          <p>{`${t("SURE_DELETE_ORDER")} ${purchase.purchaseNumber}?`}</p>
          <div className={"spacer"} />
          <div className={"spacer"} />
        </div>
      </Modal>
    </>
  );
};

import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { TPaymentProvider } from "@/http";
import { formatCurrency } from "@/utils/currency";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { exists } from "i18next";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { debounce, get } from "lodash-es";
import { showToastError } from "@/hooks/useToast";
import { uploadEventObject } from "@/utils/image-upload";
import { MdOutlineEdit } from "react-icons/md";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import clsx from "clsx";
import { InputV2 } from "@/components/shared/Input/InputV2";

export const SalePaymentsForm = ({
  payments,
  paymentMethods: paymentMethodList,
  total = 0,
  onDone,
  payment,
  coins = 0,
}: {
  payments: any[];
  paymentMethods: any[];
  total: number;
  onDone: (payment: any) => void | Promise<void>;
  payment?: any;
  coins: number;
}) => {
  const isEdit = !!get(payment, "amount", false);
  const methods = useForm();
  const [t] = useTranslation("global");
  const [tPurchases] = useTranslation("purchases");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const paymentMethodWatch = methods.watch("paymentMethod");
  const paymentProviderWatch = methods.watch("paymentProvider");
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [paymentProviders, setPaymentProviders] = useState<any[]>([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const { isCommercial, role } = useAuth();
  const [images, setImages] = useState<any[]>(
    isEdit && payment?.voucher ? [{ src: payment?.voucher }] : []
  );

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (images?.length > 0) {
        const image = await uploadEventObject(images[0]);
        values.voucher = image?.src;
      } else {
        values.voucher = null;
      }

      values.status =
        hasPermission(isCommercial, role, ["action.purchase.payment-edit"]) &&
        paymentMethodWatch === "SUPPLIER_PAY" &&
        paymentProviderWatch &&
        !["SUPPLIER_CREDIT", "COINS"].includes(
          (paymentProviderWatch || "").toUpperCase()
        )
          ? values.status
            ? "PAID"
            : "PENDING"
          : "PENDING";

      await onDone?.(values);
      methods.reset();
      setImages([]);
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    methods.reset();
    setIsOpen(false);
  };

  const buildPaymentProviders = () => {
    const providers =
      paymentMethods?.find(p => p.value === paymentMethodWatch)?.providers ||
      [];
    const choisesProviders = providers.map((pp: TPaymentProvider) => ({
      label: (
        <>
          {exists(`global:PAYMENT_METHOD.PROVIDERS.${pp?.code}`)
            ? t(`PAYMENT_METHOD.PROVIDERS.${pp?.code}`)
            : pp?.code}{" "}
          {pp.availableAmount || 0 > 0 ? (
            <>{formatCurrency(Number(pp?.availableAmount))} </>
          ) : (
            <></>
          )}
          {pp.isValid ? null : <>({tPurchases(pp.errors?.[0])})</>}
        </>
      ),
      value: pp?.code,
      disabled: !pp?.isValid,
      isValid: pp?.isValid,
      errors: pp?.errors,
      selected: providers.length === 1,
    }));
    choisesProviders.sort((x: any, y: any) =>
      x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1
    );
    setPaymentProviders(choisesProviders);
    if (choisesProviders.length === 1) {
      methods.setValue("paymentProvider", choisesProviders[0].value);
    } else if (
      !providers
        .map((p: TPaymentProvider) => p.code)
        .includes(paymentProviderWatch)
    ) {
      methods.setValue("paymentProvider", null);
    }
  };

  useEffect(() => {
    if (paymentMethods?.length === 1 && !paymentMethods?.[0]?.disabled) {
      methods.setValue("paymentMethod", paymentMethods[0]?.value);
    }
    buildPaymentProviders();
  }, [paymentMethods]);

  useEffect(() => {
    buildPaymentProviders();
  }, [paymentMethodWatch]);

  useEffect(() => {
    const amount = !!get(payment, "amount", false);
    if (amount) {
      methods.reset({ ...payment, status: payment.status === "PAID" });
      const debounceUpdate = debounce(() => {
        methods.setValue("paymentProvider", payment.paymentProvider);
        methods.setValue("amount", payment.amount);
      }, 1000);
      debounceUpdate();
    } else {
      methods.reset({});
    }
  }, [payment]);

  useEffect(() => {
    const choices =
      paymentMethodList
        ?.filter(p => p.code !== "PARTIAL_PAYMENT")
        ?.map(paymentMethod => ({
          label: (
            <>
              {`${t(`PAYMENT_METHOD.${paymentMethod.code}`)}${
                Number(paymentMethod?.availableAmount) > 0 &&
                paymentMethod.providers.length == 1
                  ? ` (${t("AVAILABLE_AMOUNT")}: ${formatCurrency(
                      Number(paymentMethod?.availableAmount)
                    )})`
                  : ""
              }`}
              {paymentMethod.isValid
                ? null
                : " (" + tPurchases(paymentMethod?.errors?.[0]) + ")"}
            </>
          ),
          value: paymentMethod.code,
          disabled:
            !paymentMethod.isValid ||
            (["ONLINE", "CREDIT", "UPON_DELIVERY", "PLATAM"].includes(
              paymentMethod.code
            ) &&
              payments.length > 0),
          providers: paymentMethod?.providers || [],
        })) || [];
    choices.sort((x, y) =>
      x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1
    );
    setPaymentMethods(choices);
  }, [paymentMethodList]);

  useEffect(() => {
    let totalAmount = 0;
    for (let i = 0; i < (payments ?? []).length; i++) {
      totalAmount += Number(get(payments[i], "amount", 0));
    }

    let maxSum = total - totalAmount - coins;
    if (isEdit) {
      maxSum = maxSum + Number(payment?.amount || 0);
    }
    const finalAmount = Number(maxSum.toFixed(2));
    if (maxAmount !== Number(maxSum.toFixed(2))) {
      setMaxAmount(finalAmount);
    }

    if (!payment?.amount) {
      methods.setValue("amount", finalAmount);
    } else {
      methods.setValue("amount", payment.amount);
    }
  }, [payments, total, payment, coins, isOpen, paymentMethodWatch]);

  return (
    <>
      {isEdit ? (
        <div className="pointer circleButton" onClick={() => setIsOpen(true)}>
          <MdOutlineEdit />
        </div>
      ) : (
        <>
          {maxAmount > 0 && (
            <div className="rigthAlign">
              <span
                className="purpleText pointer"
                onClick={() => setIsOpen(true)}
              >
                + {t("ADD_PAYMENT")}
              </span>
            </div>
          )}
        </>
      )}

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        size="sm"
        title={t(isEdit ? "EDIT_PAYMENT" : "ADD_PAYMENT")}
        footer={
          <>
            <button disabled={isLoading} onClick={onClose}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
            >
              {t(isEdit ? "SAVE" : "ADD")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <div
            className={clsx({
              ["dNone"]:
                paymentMethods?.length === 1 && !paymentMethods?.[0]?.disabled,
            })}
          >
            <SelectV2
              name="paymentMethod"
              required
              label={t("PURCHASES.ORDERS.SELECT_PAYMENT_METHOD")}
              choices={paymentMethods}
            />
          </div>
          {paymentMethodWatch && (
            <>
              <SelectV2
                name="paymentProvider"
                choices={paymentProviders}
                required={paymentProviders?.length > 0}
                label={t("PAYMENT_PROVIDER")}
                defaultValue={
                  paymentProviders.length === 1 ? paymentProviders[0].value : ""
                }
              />
            </>
          )}

          <NumericInput
            name="amount"
            label={t("AMOUNT")}
            required
            hideControls
            max={maxAmount}
            min={0.1}
            decimals
            disabled={
              !paymentMethodWatch ||
              !paymentProviderWatch ||
              ["ONLINE", "CREDIT", "UPON_DELIVERY", "PLATAM"].includes(
                paymentMethodWatch
              )
            }
          />

          <InputV2 name="reference1" label={t("REFERENCE_1")} />
          <InputV2 name="reference2" label={t("REFERENCE_2")} />

          {hasPermission(isCommercial, role, [
            "action.purchase.payment-edit",
          ]) &&
            paymentMethodWatch === "SUPPLIER_PAY" &&
            paymentProviderWatch &&
            !["SUPPLIER_CREDIT", "COINS"].includes(
              (paymentProviderWatch || "").toUpperCase()
            ) && <Checkbox name="status" label={tPurchases("PAID")} />}

          <p>{t("VOUCHER")}</p>
          {images?.length < 1 ? (
            <DragAndDropFile
              minifiedStyle
              accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
              files={images}
              setFiles={setImages}
            />
          ) : (
            <ImagesList images={images} setImages={setImages} showName />
          )}
        </FormProvider>
      </Modal>
    </>
  );
};

import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { retryPurchaseShipping, TPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { isFinishedPurchase } from "@/utils/actions-disabled";
import { getProductToDispatch } from "@/utils/products";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type TPurchaseRetryButton = {
  purchase: TPurchase;
};
export const PurchaseRetryButton = ({ purchase }: TPurchaseRetryButton) => {
  const [retrying, setRetrying] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [t] = useTranslation("global");
  const { getPurchase } = usePurchase();
  const { user } = useAuth();

  const productToDispatch = getProductToDispatch(purchase);

  if (user.id !== purchase?.supplierId) {
    return null;
  }

  return (
    <div>
      <button
        className="primary"
        disabled={
          isFinishedPurchase(purchase) ||
          purchase?.priority === 0 ||
          retrying ||
          disabledButton ||
          productToDispatch.length == 0
        }
        onClick={async () => {
          setRetrying(true);
          try {
            await retryPurchaseShipping(purchase?.id as string);
            showToastSuccess(t("SUCCESS_CREATE_DISPATCH"));
            getPurchase(purchase?.id);
            setDisabledButton(true);
            setTimeout(() => {
              setDisabledButton(false);
              getPurchase(purchase?.id);
            }, 7000);
          } catch (error) {
            showToastError(error);
          } finally {
            setRetrying(false);
          }
        }}
      >
        {t("CREATE_DISPATCH")}
      </button>
    </div>
  );
};

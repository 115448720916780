import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import styles from "./styles.module.scss";
import { updateSupplier } from "@/http";
import { showToastError } from "@/hooks/useToast";

export const CommunicationChannel = () => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, refreshUserData } = useAuth();
  const defaultValues = { communicationChannel: user.communicationChannel };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await updateSupplier(user.id, {
        communicationChannel: values.communicationChannel,
      } as any);
      await refreshUserData();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <p className="bold">{t("ACCOUNT.PERSONAL_DATA.COMMUNICATION_CHANNEL")}</p>
      {isOpen ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                className={`${styles.input} ${
                  errors.communicationChannel ? styles.required : ""
                }`}
                disabled={isLoading}
                defaultValue={user.communicationChannel}
                placeholder={t("ACCOUNT.PERSONAL_DATA.COMMUNICATION_CHANNEL")}
                {...register("communicationChannel", {
                  required: t("LOGIN.FIELD_REQUIRED"),
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t("LOGIN.INVALID_EMAIL"),
                  },
                })}
              />
              <br />
              <span className={styles.errorsLabel}>
                {errors.communicationChannel &&
                  errors.communicationChannel.message}
              </span>
            </div>
            <div className={styles.actions}>
              <button
                disabled={isLoading}
                className="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {t("ACCOUNT.ADDRESSES.SAVE")}
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
              >
                {t("ACCOUNT.ADDRESSES.CANCEL")}
              </button>
            </div>
          </form>
        </>
      ) : (
        <p className={styles.inlineDescription}>
          {user?.communicationChannel || "-"}
          <AiOutlineEdit className="pointer" onClick={() => setOpen(true)} />
        </p>
      )}
      <div className={styles.spacer} />
    </>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import {
  TCredit,
  TNewCredit,
  TSeller,
  createCredit,
  updateCredit,
} from "@/http";
import styles from "./styles.module.scss";
import { FormProvider, useForm } from "react-hook-form";
import { InputV2 } from "../shared/Input/InputV2";
import { SellerInputSearch } from "../shared/Sellers/SellerInputSearch/SellerInputSearch";
import { useAuth } from "@/store/auth";
import { showToastSuccess, showToastError } from "@/hooks/useToast";
import { Checkbox } from "../shared/Input/Checkbox";
import { FaEdit } from "react-icons/fa";

const getMinCreditAmount = (credit: TCredit | undefined) => {
  let min = 0;

  if (credit?.balance) {
    min = credit.balance;
  }

  return min;
};

type Props = {
  credit?: TCredit;
  seller?: TSeller;
  onDone?: (segment: TCredit) => void;
  buttonOutlined?: boolean;
  hideEditSeller?: boolean;
  editButton?: boolean;
  activeCredit?: boolean;
};
export const CreditModalForm = ({
  credit,
  seller,
  onDone,
  buttonOutlined,
  hideEditSeller = false,
  editButton = false,
  activeCredit = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");

  const { user } = useAuth();
  const supplierId = user.id;
  const defaultValues: TCredit | TNewCredit = credit || {
    supplierId,
    amount: 0,
    sellerId: seller?.id ?? "",
    dueDays: 30,
    interest: 0,
    isActive: true,
  };
  const methods = useForm<TNewCredit>({ defaultValues });

  const buildAmountLabel = (credit: TCredit | undefined) => {
    let label = t("CREDITS.FORM.AMOUNT");

    if (credit && credit.balance) {
      label = `${label} - ${t("CREDITS.FORM.BALANCE")}: ${credit.balance}`;
    }

    return label;
  };

  const handleSubmit = async (values: typeof defaultValues) => {
    try {
      let newCredit;

      const payload = {
        ...values,
        interest: 0,
      };
      if (activeCredit) {
        payload.isActive = true;
      }

      if (credit && credit.id) {
        newCredit = await updateCredit(credit.id, payload);
      } else {
        newCredit = await createCredit(payload);
      }
      showToastSuccess(t("SAVED"));
      methods.reset(defaultValues);
      onDone?.(newCredit);
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    }
  };

  useEffect(() => {
    if (seller?.id) {
      methods.setValue("sellerId", seller?.id);
    } else {
      methods.setValue("sellerId", "");
    }
  }, [seller]);

  return (
    <>
      {credit ? (
        <>
          {editButton ? (
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className={buttonOutlined ? "purpleOutlined" : "primary"}
            >
              <span className={styles.responsive}>{t("EDIT_CREDIT")}</span>
            </button>
          ) : (
            <ToolTip title={t("EDIT")} position="Left">
              <FaEdit
                className="pointer"
                onClick={() => {
                  methods.reset(defaultValues);
                  setIsOpen(true);
                }}
              />
            </ToolTip>
          )}
        </>
      ) : (
        <div className="rigthAlign">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className={buttonOutlined ? "purpleOutlined" : "primary"}
          >
            <span>{t("CREATE_CREDIT")}</span>
          </button>
        </div>
      )}

      {createPortal(
        <FormProvider {...methods}>
          <form>
            <Modal
              title={
                credit ? `${t("EDIT_CREDIT")} ${credit.id}` : t("CREATE_CREDIT")
              }
              isOpen={isOpen}
              onClose={() => {
                methods.reset(defaultValues);
                setIsOpen(false);
              }}
              size="sm"
              footer={
                <button
                  onClick={methods.handleSubmit(handleSubmit)}
                  className="primary lg"
                  disabled={methods.formState.isSubmitting}
                >
                  {t(credit ? "SAVE" : "CREATE")}
                </button>
              }
            >
              <SellerInputSearch
                sellerInput={seller}
                hideEditSeller={hideEditSeller}
              />
              <div className="spacer" />
              <InputV2
                type="number"
                name="amount"
                min={getMinCreditAmount(credit)}
                label={buildAmountLabel(credit)}
                required
              />
              <InputV2
                type="number"
                name="dueDays"
                label={t("CREDITS.FORM.DUEDAYS")}
                required
              />
              {!activeCredit && (
                <Checkbox name="isActive" label={t("ENABLED")} />
              )}
            </Modal>
          </form>
        </FormProvider>,
        document.body
      )}
    </>
  );
};

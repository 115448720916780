import { isUSA } from "@/constants/core";
import { TClient, TSeller } from "@/http";
import styles from "./styles.module.scss";
import { ClientModal } from "@/components/clients/ClientModal";
import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type TSellerResume = {
  seller?: TSeller | TClient;
  isSlim?: boolean;
  children?: ReactNode;
  hideEdit?: boolean;
  className?: any;
};

export const SellerResume = ({
  seller,
  isSlim,
  children,
  hideEdit = false,
  className,
}: TSellerResume) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  return seller && seller.id ? (
    <div className={clsx(styles.resumeContainer, className ?? "")}>
      <div
        className={styles.infoContainer}
        onClick={() => navigate(`/clients/${seller.id}/show`)}
      >
        <p>
          {isUSA && seller?.legalName
            ? seller.legalName
            : `${seller?.firstName ?? ""} ${seller?.lastName ?? ""}`}
        </p>
        {!isSlim && !seller?.email?.endsWith("@noemail.com") && (
          <div className={styles.description}>{seller?.email}</div>
        )}

        {!isSlim && (
          <div className="flexGap">
            {seller?.segments && seller?.segments?.length > 0 && (
              <span className={styles.description}>
                {t("SEGMENTS.SEGMENT") + ": " + seller?.segments?.[0]?.name}
              </span>
            )}
            {!isUSA && (
              <span className={styles.description}>
                {`${seller?.documentType ? seller?.documentType : "?"}: ${
                  seller?.documentId
                }`}{" "}
                {children}
              </span>
            )}
          </div>
        )}
      </div>
      {!isSlim && !hideEdit && (
        <div className={styles.actions}>
          <ClientModal smallIconButton client={seller} />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

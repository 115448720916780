import { TOTAL_ORDERS_PER_PAGE } from "@/constants/export-orders";
import {
  getSupplierProductsGroups,
  deleteProductSupplier,
  searchProducts,
  getProductsV2,
} from "@/http";
import { create } from "zustand";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { CURRENT_LANG, ES } from "@/constants/core";

type TUseProducts = {
  getProductsGroups: (supplierId: string) => void;
  isLoadingProductsGroups: boolean;
  productsGroup: any[];
  currentPageGroup: number;
  setCurrentPageGroup: (currentPageGroup: number) => void;
  totalPagesGroup: number;
  getProduct?: () => void;
  isLoadingProduct?: boolean;
  setCurrentFilter: (value: string) => void;
  currentFilter: string;
  setTabCurrentFilter: (value: string) => void;
  tabCurrentFilter: string;
  setNameFilter: (value: string) => void;
  nameFilter: string;
  setBrandFilter: (value: string) => void;
  brandFilter: string;
  getVariants: (supplierId: string) => void;
  isLoadingVariants: boolean;
  selectedVariantEdit: any;
  setSelectedVariant: (value: any) => void;
  isOpenModal: boolean;
  setOpenModal: (value: boolean) => void;
  variants: any[];
  totalPagesVariants: number;
  selectedProducts: Record<string, any>;
  setSelectedProducts: (product: any) => void;
  resetSelectedProducts: () => void;
  clearSelectedProducts: () => void;
  deleteProducts: (supplierId: string, mutate: (...args: any) => void) => void;
  isStockFilter: boolean;
  isNotStockFilter: boolean;
  setIsStockFilter: (value: boolean) => void;
  setIsNotStockFilter: (value: boolean) => void;
};
export const useProducts = create<TUseProducts>((set, get) => ({
  setCurrentFilter: (currentFilter: string) => set(() => ({ currentFilter })),
  currentFilter: "",
  setTabCurrentFilter: (tabCurrentFilter: string) =>
    set(() => ({ tabCurrentFilter })),
  tabCurrentFilter: "",
  errorProductsGroups: "",
  getProductsGroups: async (supplierId: string) => {
    try {
      set({ isLoadingProductsGroups: true });
      const { currentPageGroup, currentFilter, nameFilter, brandFilter } =
        get();
      const data = await getSupplierProductsGroups(supplierId, {
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPageGroup,
        sort: "name,asc",
        search: `status:${currentFilter}${
          nameFilter ? `,name:${nameFilter}` : ""
        }${brandFilter ? `,brandId:${brandFilter}` : ""}`,
      });
      set({
        productsGroup: data.content,
        totalPagesGroup: data.totalPages,
        isLoadingProductsGroups: false,
      });
    } catch (e) {
      set({
        productsGroup: [],
        totalPagesGroup: 0,
        isLoadingProductsGroups: false,
      });
    }
  },
  setNameFilter: nameFilter => set(() => ({ nameFilter })),
  nameFilter: "",
  setBrandFilter: brandFilter => set(() => ({ brandFilter })),
  brandFilter: "",
  isLoadingProductsGroups: false,
  productsGroup: [],
  currentPageGroup: 0,
  totalPagesGroup: 0,
  setCurrentPageGroup: currentPageGroup => set(() => ({ currentPageGroup })),
  getVariants: async (supplierId: string) => {
    try {
      set({ isLoadingVariants: true });
      const {
        currentPageGroup,
        nameFilter,
        brandFilter,
        tabCurrentFilter,
        isStockFilter,
        isNotStockFilter,
      } = get();
      let data;
      if ((isStockFilter || isNotStockFilter) && !tabCurrentFilter) {
        data = await getProductsV2({
          size: TOTAL_ORDERS_PER_PAGE,
          page: currentPageGroup,
          sort: "name,asc",
          search: `supplierId:${supplierId}${
            nameFilter ? `,name:${nameFilter}` : ""
          }${brandFilter ? `,brandId:${brandFilter}` : ""}${
            isStockFilter ? ",availableStock>0" : ",availableStock<1"
          }`,
        });
      } else {
        const payload: any = { supplierId };
        if (brandFilter) {
          payload.brandId = brandFilter;
        }
        if (nameFilter) {
          payload.text = nameFilter;
        }
        data = await searchProducts({
          data: payload,
          params: {
            sort: "name,asc",
            page: currentPageGroup,
            size: TOTAL_ORDERS_PER_PAGE,
          },
        });
      }

      set({
        variants: data?.content ?? [],
        totalPagesVariants: data?.totalPages ?? 0,
        isLoadingVariants: false,
      });
    } catch (e) {
      set({
        variants: [],
        totalPagesVariants: 0,
        isLoadingVariants: false,
      });
    }
  },
  selectedVariantEdit: null,
  setSelectedVariant: (value: any) => set({ selectedVariantEdit: value }),
  isOpenModal: false,
  setOpenModal: (value: boolean) => set({ isOpenModal: value }),
  selectedProducts: [],
  clearSelectedProducts: () => {
    set({
      selectedProducts: {},
    });
  },
  setSelectedProducts: (value: any) => {
    const currentProducts = { ...get().selectedProducts };
    if (currentProducts[value.id]) {
      delete currentProducts[value.id];
      set({
        selectedProducts: currentProducts,
      });
    } else {
      set({
        selectedProducts: { ...currentProducts, [value.id]: value },
      });
    }
  },
  resetSelectedProducts: () => {
    set({ selectedProducts: [] });
  },
  isLoadingVariants: false,
  variants: [],
  totalPagesVariants: 0,
  deleteProducts: async (
    supplierId: string,
    mutate: (...args: any) => void
  ) => {
    const { selectedProducts, clearSelectedProducts } = get();

    let successDeleteQuantity = 0;
    for await (const product of Object.values(selectedProducts)) {
      await deleteProductSupplier(product.id, supplierId)
        .then(() => {
          successDeleteQuantity += 1;
          return;
        })
        .catch(() => {
          showToastError(
            CURRENT_LANG() === ES
              ? `El producto ${product.name} no se pudo eliminar`
              : `Product ${product.name} could not be deleted`
          );
          return;
        });
    }

    if (successDeleteQuantity) {
      showToastSuccess(
        CURRENT_LANG() === ES
          ? `${successDeleteQuantity} productos eliminados con éxito`
          : `${successDeleteQuantity} products successfully deleted`
      );
      mutate();
      clearSelectedProducts();
    }
  },
  isStockFilter: false,
  isNotStockFilter: false,
  setIsStockFilter: isStockFilter => set(() => ({ isStockFilter })),
  setIsNotStockFilter: isNotStockFilter => set(() => ({ isNotStockFilter })),
}));

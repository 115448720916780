import { PreviewFile } from "./ProductImage";
import styles from "./Images.module.scss";
import { useTranslation } from "react-i18next";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { useFormContext } from "react-hook-form";

type TImagesList = {
  images: any[];
  setImages?: (images: any[]) => void;
  minImages?: number;
  showName?: boolean;
};
export const ImagesFormList = ({
  images,
  setImages,
  minImages,
  showName = false,
}: TImagesList) => {
  const [t] = useTranslation("global");
  const methods = useFormContext();
  const imagesPriority = methods.watch("imagesPriority");

  const removeImage = (e: any, value: string) => {
    e.preventDefault();

    if (setImages) {
      let removedIndex = -1;
      const updatedImages = images.filter((image, index) => {
        const shouldRemove = image.name
          ? image.name === value
          : image.src === value;
        if (shouldRemove) removedIndex = index;
        return !shouldRemove;
      });

      if (removedIndex >= 0) {
        const newPriority = (imagesPriority || []).filter(
          (_: any, idx: any) => idx !== removedIndex
        );
        methods.setValue("imagesPriority", newPriority);
      }
      setImages(updatedImages);
    }
  };

  const additionalEmptyImagesCount = minImages
    ? Math.max(0, minImages - (images?.length || 0))
    : 0;
  const additionalEmptyImages = Array.from(
    { length: additionalEmptyImagesCount },
    () => ({})
  );

  return (
    <div className={styles.imagesListMinified}>
      {images?.map((image, idx) => (
        <div className="flexColumn" key={`${image.name}-${idx}-image`}>
          <PreviewFile
            file={image}
            removeFile={setImages ? removeImage : undefined}
            showName={showName}
          />
          <div className={styles.priorityInput}>
            <NumericInput
              hideControls
              name={`imagesPriority[${idx}]`}
              label={t("OFFERS.PRIORITY")}
            />
          </div>
        </div>
      ))}
      {additionalEmptyImages?.map((_, idx) => (
        <div key={idx} className={styles.emptyImage} />
      ))}
    </div>
  );
};

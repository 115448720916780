import { ToastProps } from "@/models/toast";
import { AxiosError } from "@/http";
import { getFirebaseErrorText } from "@/utils/validations/firebase";
import { FirebaseError } from "firebase/app";
import { toast } from "react-toastify";
import { sentryReport } from "@/utils/sentry";
import { logError } from "@/utils/errors";
import { isES } from "@/constants/core";

export const customToast = ({ title, status }: ToastProps) => {
  toast[status](title);
};

export const showToastError = (error: any) => {
  logError(error);
  sentryReport(error);

  if (error instanceof AxiosError) {
    const status = error.response?.status;
    const customMessage = !(status && status >= 400 && status < 500)
      ? isES
        ? "Hubo un error, intenta mas tarde."
        : "An error occurred, please try again later."
      : null;

    toast.error(
      customMessage ?? error.response?.data?.message ?? error.message
    );
  } else if (error instanceof FirebaseError) {
    toast.error(getFirebaseErrorText(error.code, error.message));
  } else {
    toast.error(
      error?.response?.data?.message ?? error?.message ?? JSON.stringify(error)
    );
  }
};

export const showToastSuccess = (title: string) => {
  toast.success(title);
};

import { TGlobalOffer } from "@/http/types/global-offers";
import format from "date-fns/format";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import styles from "./styles.module.scss";
import { useState } from "react";
import { DeleteOffer } from "../delete/DeleteOffer";
import { isUSA } from "@/constants/core";
import { validateTimezoneDate } from "@/utils/date";
import { useTranslation } from "react-i18next";
import { showToastError } from "@/hooks/useToast";
import { toggleStatusOffer } from "@/http";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { Link } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";

type TOfferRow = {
  rowOffer: TGlobalOffer;
};
export const OfferRow = ({ rowOffer }: TOfferRow) => {
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState(rowOffer);

  const handleChange = async () => {
    try {
      setIsLoading(true);
      const offerNew = await toggleStatusOffer(offer.id);
      setOffer(offerNew);
      setIsLoading(false);
    } catch (error) {
      showToastError(error);
      setIsLoading(false);
    }
  };

  return (
    <tr className={styles.row}>
      <td>
        {format(new Date(offer.createdAt), isUSA ? USA_DATE_HOUR : DATE_HOUR)}
      </td>
      <td>{offer.name}</td>
      <td>{t("OFFERS." + offer.type)}</td>
      <td>{t(offer.condition)}</td>
      <td>
        {format(
          new Date(validateTimezoneDate(offer?.endsAt)),
          isUSA ? USA_DATE_HOUR : DATE_HOUR
        )}
      </td>
      <td>
        <div className="flexGap centerAlign centerVAlign">
          <label className="switchBtn">
            <input
              type="checkbox"
              checked={offer?.enabled ?? false}
              onChange={handleChange}
              className={isLoading ? "loading" : ""}
            />
            <div className={`slide`} />
          </label>
          <div>{t(offer?.enabled ? "ENABLED" : "DISABLED")}</div>
        </div>
      </td>
      <td className="purpleText">
        <ToolTip title={t("SHOW")} position="Left">
          <Link to={`/offers/${offer?.id}/show`}>
            <IoEyeOutline className="bold" />
          </Link>
        </ToolTip>
        <DeleteOffer offer={offer} />
      </td>
    </tr>
  );
};

import Input from "@/components/shared/Input/Input";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSellerBillingAddress, TSellerBillingAddress } from "@/http";
import { validateOnlyNumber } from "@/utils/keyboard";
import { FC, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { CityInput } from "./CityInput";
import { DocumentTypeInput } from "./DocumentTypeInput";
import { StateInput } from "./StateInput";
import Select from "react-select";
import { useCountries } from "@/hooks/useCountry";
import { singleSelectStyles } from "@/constants/input";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { isUSA } from "@/constants/core";

type TCreateBillingAddressModal = {
  onDone?: (address: TSellerBillingAddress) => void;
  sellerId: string;
  iconButton?: boolean;
};
export const CreateBillingAddressModal: FC<TCreateBillingAddressModal> = ({
  onDone,
  sellerId,
  iconButton,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const countries = useCountries();
  const countryOptions = countries?.map(c => ({
    label: `${c.emoji} ${c.name}`,
    value: c,
  }));

  const defaultValues = {
    state: "",
    city: "",
    documentType: "",
    firstName: "",
    lastName: "",
    documentId: "",
    verifierDigit: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    phonePrefix: "",
    countryId: "",
  };
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const documentType = watch("documentType");

  const onSubmit = async (values: Partial<TSellerBillingAddress>) => {
    setIsLoading(true);
    try {
      values.sellerId = sellerId;
      const address = await createSellerBillingAddress(values);
      onDone?.(address);
      setIsLoading(false);
      showToastSuccess(t("ADDRESS_BILLING_CREATED"));
      reset(defaultValues);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  return (
    <>
      {iconButton ? (
        <span className="pointer circleButton" onClick={() => setOpen(true)}>
          <MdOutlineAddLocationAlt />
        </span>
      ) : (
        <button type="button" onClick={() => setOpen(true)} className="primary">
          {t("CREATE_BILLING_ADDRESS")}
        </button>
      )}
      {createPortal(
        <Modal
          isOpen={open}
          onClose={() => setOpen(false)}
          title={t("CREATE_BILLING_ADDRESS")}
          footer={
            <>
              <button
                className="primary xl"
                disabled={isLoading}
                onClick={e => {
                  e.stopPropagation();
                  handleSubmit(onSubmit)(e);
                }}
              >
                {t("CREATE_ADDRESS")}
              </button>
            </>
          }
        >
          <FormProvider {...methods}>
            <form autoComplete="off">
              <div className="row">
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="firstName"
                    label={t("SELLER_BILLING_NAME")}
                    type="text"
                    placeholder={t("SELLER_BILLING_NAME_PLACEHOLDER")}
                    register={register}
                    required={true}
                    error={errors.firstName}
                  />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="lastName"
                    label={t("SELLER_BILLING_LAST_NAME")}
                    type="text"
                    placeholder={t("SELLER_BILLING_LAST_NAME_PLACEHOLDER")}
                    register={register}
                    required={true}
                    error={errors.lastName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 noPaddingH">
                  <DocumentTypeInput required={!isUSA} />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="documentId"
                    label={t("SELLER_BILLING_DOCUMENT_ID")}
                    type="text"
                    placeholder={t("SELLER_BILLING_DOCUMENT_ID_PLACEHOLDER")}
                    register={register}
                    required={!isUSA}
                    error={errors.documentId}
                  />
                </div>
              </div>
              <div className="row">
                {documentType.toUpperCase() === "NIT" && (
                  <div className="col-6 col-sm-12 noPaddingH">
                    <Input
                      name="verifierDigit"
                      label={t("SELLER_BILLING_VERIFIER_DIGIT")}
                      type="text"
                      placeholder={t(
                        "SELLER_BILLING_VERIFIER_DIGIT_PLACEHOLDER"
                      )}
                      register={register}
                      required={!isUSA}
                      error={errors.verifierDigit}
                      onKeyPress={validateOnlyNumber}
                      minLength={1}
                      maxLength={1}
                    />
                  </div>
                )}
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="email"
                    label={t("SELLER_BILLING_EMAIL")}
                    type="email"
                    placeholder={t("SELLER_BILLING_EMAIL_PLACEHOLDER")}
                    register={register}
                    required={true}
                    error={errors.email}
                  />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="phone"
                    label={t("SELLER_BILLING_PHONE")}
                    type="text"
                    placeholder={t("SELLER_BILLING_PHONE_PLACEHOLDER")}
                    register={register}
                    required={!isUSA}
                    error={errors.phone}
                    onKeyPress={validateOnlyNumber}
                    minLength={10}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 noPaddingH">
                  <p>{t("COUNTRY")}</p>
                  <Select
                    isSearchable
                    name="phonePrefix"
                    placeholder={t("SELECT_OPTION")}
                    required
                    options={countryOptions}
                    styles={singleSelectStyles}
                    isDisabled={!countries?.length}
                    onChange={({ value = {} }: any) => {
                      methods.setValue(
                        "phonePrefix",
                        value?.phoneCode ? "+" + value?.phoneCode : ""
                      );
                      methods.setValue("country", value?.name ?? "");
                      methods.setValue("countryId", value?.id ?? "");
                      methods.setValue("state", "");
                      methods.setValue("city", "");
                    }}
                  />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  <StateInput />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 noPaddingH">
                  <CityInput />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  {isUSA && (
                    <InputV2
                      name="postalCode"
                      label={tGlobal("CLIENTS.POSTAL_CODE")}
                      maxLength={6}
                      onKeyPress={validateOnlyNumber}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="addressLine1"
                    label={t("SELLER_BILLING_ADDRESS_LINE_1")}
                    type="text"
                    placeholder={
                      isUSA
                        ? "1105 NE 3rd Ave Unit 23"
                        : t("SELLER_BILLING_ADDRESS_LINE_1_PLACEHOLDER")
                    }
                    register={register}
                    required={true}
                    error={errors.addressLine1}
                  />
                </div>
                <div className="col-6 col-sm-12 noPaddingH">
                  <Input
                    name="addressLine2"
                    label={t("SELLER_BILLING_ADDRESS_LINE_2")}
                    type="text"
                    placeholder={t("SELLER_BILLING_ADDRESS_LINE_2_PLACEHOLDER")}
                    register={register}
                    required={false}
                    error={errors.addressLine2}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal>,
        document.body
      )}
    </>
  );
};

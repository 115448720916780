import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import { confirmSupplierDelivery, TPurchase } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  purchase: TPurchase;
};
export const ConfirmPurchaseDelivery = ({ purchase }: Props) => {
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { getPurchase } = usePurchase();

  const confirmDelivery = async () => {
    try {
      setIsLoading(true);
      await confirmSupplierDelivery(purchase.id!, {});
      await getPurchase(purchase.id!);
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (
    ["DELIVERED", "CANCELLED", "DONE"].includes(purchase?.status) ||
    purchase?.subPurchases?.length === 0 ||
    !["PENDING", "SUPPLIER_PENDING"].includes(purchase?.deliveryStatus)
  ) {
    return null;
  }

  return (
    <div>
      <button
        className="primary sm"
        disabled={isLoading}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("PACKAGE_RECEIVED_BUTTON")}
      </button>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={t("PACKAGE_CONFIRMATION_TITLE")}
        footer={
          <>
            <button disabled={isLoading} onClick={() => setIsOpen(false)}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={confirmDelivery}
            >
              {t("CONFIRM")}
            </button>
          </>
        }
      >
        <h6>{t("PACKAGE_RECEIVED_CONFIRMATION_MESSAGE")}</h6>
      </Modal>
    </div>
  );
};

import { formatCurrency } from "@/utils/currency";
import { ReactNode } from "react";
import { FaCartArrowDown } from "react-icons/fa";

import styles from "./Prepurchase.module.scss";

type TPriceLabel = {
  name: string | ReactNode;
  price: number;
  bold?: boolean;
  showZero?: boolean;
  negative?: boolean;
  secondary?: boolean;
  helper?: string;
  hideDivider?: boolean;
  green?: boolean;
  purpleResult?: boolean;
  noCurrency?: boolean;
};
export const PriceLabel = ({
  name,
  bold,
  price,
  negative,
  showZero,
  secondary,
  helper,
  hideDivider = false,
  green = false,
  purpleResult = false,
  noCurrency = false,
}: TPriceLabel) => {
  if (price === 0 && !showZero) return null;

  return (
    <>
      <div className={styles.priceLabel}>
        <div
          className={green ? "greenText" : secondary ? styles.secondary : ""}
        >
          {bold ? <h6 className="bold">{name}</h6> : <p>{name}</p>}
          {helper && <span className={styles.helper}>{helper}</span>}
        </div>
        <div
          className={`${bold ? "bold" : ""} ${
            purpleResult
              ? "statusColorHelper purpleText pw1 bolder phmd"
              : green
              ? "greenText"
              : secondary
              ? styles.secondary
              : negative
              ? styles.danger
              : ""
          }`}
        >
          {negative && !green && <FaCartArrowDown />}
          {noCurrency ? price : formatCurrency(price)}
        </div>
      </div>
      {!hideDivider && <div className="divider" />}
    </>
  );
};

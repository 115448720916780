import { useCities, useCountries, useStates } from "@/hooks/useCountry";
import {
  createAddress,
  TSellerAddress,
  TSupplierAddress,
  updateAddress,
} from "@/http";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { customToast, showToastError } from "@/hooks/useToast";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Autocomplete } from "../shared/Autocomplete/Autocomplete";
import { SelectV2 } from "../shared/Input/SelectV2";
import { Checkbox } from "../shared/Input/Checkbox";
import { NumericInput } from "../shared/Input/NumericInput";
import { InputV2 } from "../shared/Input/InputV2";
import { isUSA } from "@/constants/core";

type AddressFormProps = {
  clientId: string;
  address?: TSellerAddress;
  onDone?: (address: TSellerAddress) => void;
};

export const AddressForm: FC<AddressFormProps> = ({
  clientId,
  address,
  onDone,
}) => {
  const defaultValues = address
    ? { ...address, countryName: address.country }
    : {
        name: "",
        addressLine1: "",
        addressLine2: "",
        isMain: false,
        country: "",
        state: "",
        city: "",
        latitude: "",
        longitude: "",
        postalCode: "",
        phone: "",
        contactName: "",
        countryName: "",
      };
  const methods = useForm<Partial<TSupplierAddress & { countryName: string }>>({
    defaultValues,
  });

  const countries = useCountries();
  const stateName = methods.watch("state");
  const [country, setCountry] = useState<any>(null);
  const { states, statesIsLoading } = useStates(country?.id);
  const { cities, citiesIsLoading } = useCities(states, stateName ?? "");
  const [t] = useTranslation("global");
  const isRequired = ["mx", "us"].includes(country?.prefix?.toLowerCase());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const payload = {
        ...values,
        countryCode: values.country.prefix,
        country: values.countryName,
      };

      let savedAddress;
      if (address) {
        savedAddress = await updateAddress(clientId, address.id!, payload);
      } else {
        savedAddress = await createAddress(clientId, payload as any);
      }
      customToast({
        title: t("CLIENTS.ADDRESS_SAVED"),
        status: "success",
      });
      onDone?.(savedAddress);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (address) {
      const country = countries?.find(c => c.name === address.country);
      if (country) {
        setCountry(country);
      }
    }
  }, [countries]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [address]);

  useEffect(() => {
    methods.setValue("state", "");
    methods.setValue("city", "");
  }, [methods.watch("countryName")]);

  useEffect(() => {
    methods.setValue("city", "");
  }, [methods.watch("state")]);

  return (
    <FormProvider {...methods}>
      <div className={styles.formGrid}>
        <Autocomplete
          inputName="countryName"
          inputLabel={t("COUNTRY")}
          inputPlaceholder=""
          required
          choices={
            countries?.map(country => ({
              ...country,
              id: "" + country.id,
            })) ?? []
          }
          action={country => {
            methods.setValue("countryName", country?.name ?? "");
            setCountry(country);
          }}
        />
        <SelectV2
          name="state"
          label={t("ACCOUNT.ADDRESSES.STATE")}
          required
          disabled={statesIsLoading}
          choices={states?.map(state => ({
            value: state.name,
            label: state.name,
          }))}
        />
        <SelectV2
          name="city"
          label={t("ACCOUNT.ADDRESSES.CITY")}
          required
          disabled={citiesIsLoading}
          choices={cities?.map(city => ({
            value: city.name,
            label: city.name,
          }))}
        />
        <NumericInput
          name="postalCode"
          label={t("CLIENTS.POSTAL_CODE") + (isRequired ? " *" : "")}
          required={isRequired}
          max={isRequired ? 99999 : 999999}
          hideControls
        />
        <InputV2
          name="addressLine1"
          type="text"
          label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1")}
          placeholder={
            isUSA
              ? "1105 NE 3rd Ave Unit 23"
              : t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1_PLACEHOLDER")
          }
          required
          maxLength={170}
        />
        <InputV2
          name="addressLine2"
          type="text"
          label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2")}
          placeholder={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2_PLACEHOLDER")}
          maxLength={170}
        />
        <Checkbox name="isMain" label={t("MAIN_ADDRESS")} />
      </div>

      <div className="row rigthAlign">
        <button
          className="primary lg"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {t("ACCOUNT.ADDRESSES.SAVE")}
        </button>
      </div>
    </FormProvider>
  );
};

import ProductImage from "@/components/layout/ProductImage";
import { customToast, showToastError } from "@/hooks/useToast";
import { TOrderDetailsPanel } from "@/models/order-details";
import { splitSupplierOrder } from "@/http";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Checkbox } from "../shared/Input/Checkbox";
import { Modal } from "../shared/Modal/Modal";
import styles from "./styles.module.scss";
import { ProductResume } from "./ProductsDetails/ProductResume";
import { NumericInput } from "../shared/Input/NumericInput";

const ConfirmPartialOrderModal = ({ orderDetail }: TOrderDetailsPanel) => {
  const methods = useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("orders-page");
  const navigate = useNavigate();

  const confirmOrder = async (data: any) => {
    try {
      setIsLoading(true);
      const { products } = data;

      const productsToSplit = products.filter(
        (p: any) => p.split && p.quantity > 0
      );

      if (!productsToSplit.length) {
        customToast({
          status: "warn",
          title: t("SPLIT.EMPTY_PRODUCTS"),
        });
        setIsLoading(false);
        return;
      }

      const splittedOrder = await splitSupplierOrder(
        `${orderDetail?.id}`,
        productsToSplit
      );

      customToast({
        title: t("SPLIT.ORDER_SPLITTED"),
        status: "success",
      });
      setIsLoading(false);
      setOpen(false);
      navigate(`/orders/${splittedOrder.id}`);
      methods.reset();
    } catch (error) {
      showToastError(error);
      setIsLoading(false);
      methods.reset();
    }
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="outlined">
        {t("SPLIT.TITLE")}
      </button>

      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          methods.reset();
        }}
        size="sm"
        title={t("SPLIT.TITLE")}
        footer={
          <div className="row rigthAlign">
            <button onClick={() => setOpen(false)} disabled={isLoading}>
              {t("CLOSE")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(confirmOrder)}
              disabled={isLoading}
            >
              {t("SPLIT.SPLIT")}
            </button>
          </div>
        }
      >
        <p>{t("SPLIT.SELECT_PRODUCTS")}</p>
        <div className="spacer" />
        <FormProvider {...methods}>
          {orderDetail?.products.map((product: any, index) => {
            return (
              <div key={`partial_${product.id}_${product?.type}_${index}`}>
                <input
                  type="hidden"
                  {...methods.register(`products.${index}.productId`)}
                  value={product.id}
                />
                <input
                  type="hidden"
                  {...methods.register(`products.${index}.type`)}
                  value={product.type}
                />
                <Checkbox
                  defaultChecked={false}
                  name={`products.${index}.split`}
                  label={
                    <div className="flexGap center pointer">
                      <ProductImage images={product.images} />
                      <ProductResume product={product} />
                    </div>
                  }
                />
                {!!methods.watch(`products.${index}.split`) && (
                  <div className={styles.quantityContainer}>
                    <NumericInput
                      name={`products.${index}.quantity`}
                      label={`${t("QUANTITY_MAX")} ${product?.quantity})`}
                      required
                      max={product?.quantity}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </FormProvider>
      </Modal>
    </>
  );
};

export default ConfirmPartialOrderModal;

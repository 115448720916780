import { showToastError } from "@/hooks/useToast";
import { TSupplierVariantPP } from "@/models/order-details";
import { useProducts } from "@/store/products";
import { updateSupplierVariantV2 } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";

type TVariantSwitchStatus = {
  variant: TSupplierVariantPP;
  onDone?: (variant: TSupplierVariantPP) => void;
  variantStatus: string;
  property: string;
  disabled?: boolean;
};
export const VariantSwitchStatus = ({
  variant,
  onDone,
  variantStatus,
  property,
  disabled,
}: TVariantSwitchStatus) => {
  const [product, setProduct] = useState(variant);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("products-page");
  const { setOpenModal } = useProducts();

  const handleChange = async () => {
    try {
      setOpenModal(false);
      setIsLoading(true);
      const productNew = await updateSupplierVariantV2(variant.id ?? "", {
        [property]: !get(variant, property, false),
      });
      onDone?.(productNew);
      setProduct(productNew);
      setIsLoading(false);
    } catch (error) {
      showToastError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {product?.id ? (
        <div className="flexGap centerAlign centerVAlign">
          <label className="switchBtn">
            <input
              type="checkbox"
              checked={get(variant, property, false) ?? false}
              disabled={
                ["IN_REVIEW", "PENDING"].includes(variantStatus) || disabled
              }
              onChange={handleChange}
              className={isLoading ? "loading" : ""}
            />
            <div className="slide" />
          </label>
          <span>
            {get(variant, property, false)
              ? t("PRODUCT.ENABLED")
              : t("PRODUCT.DISABLED")}
          </span>
        </div>
      ) : (
        <>{"-"}</>
      )}
    </>
  );
};

import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import {
  TSupplierOrder,
  TUpdateSupplierOrderAction,
} from "../../types/supliers-orders";

const BASE_PATH = "/api/sub-purchases";
const PURCHASES_PATH = "/api/orders/sub-purchases";

export const generateRemission = async (id: string) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${id}/remission`, {})
    .then(res => res.data);
};

export const getAllSupplierOrders = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierOrder>>(`${BASE_PATH}`, {
      params: {
        ...params,
        search: `isFromSupplier:true,${params.search}`,
      },
    })
    .then(res => res.data);
};

export const getSupplierOrders = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TSupplierOrder>>(`${BASE_PATH}`, {
      params: {
        ...params,
        search: `supplierId:${supplierId},${params.search}`,
      },
    })
    .then(res => res.data);
};

export const updateSupplierOrder = async (
  orderId: string,
  action: TUpdateSupplierOrderAction,
  data: Partial<TSupplierOrder> = {}
) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/${action}`, data)
    .then(res => res.data);
};

export const getSupplierOrderById = async (orderId: string) => {
  return getInstance()
    .get<TSupplierOrder>(`${BASE_PATH}/id/${orderId}`)
    .then(res => res.data);
};

export const deleteSupplierOrderById = async (orderId: string) => {
  return getInstance()
    .delete<TSupplierOrder>(`${PURCHASES_PATH}/${orderId}`)
    .then(res => res.data);
};

export const updateTrackingCodeSupplierOrder = async (
  orderId: string,
  trackingCode: string,
  trackingFile?: string,
  estimatedDeliveryDate?: string,
  observations?: string
) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/manual`, {
      trackingCode,
      trackingFile,
      estimatedDeliveryDate,
      observations,
    })
    .then(res => res.data);
};

export const updateTrackingCodeDeliveryProvider = async (
  orderId: string,
  trackingCode: string,
  trackingFile?: string,
  deliveryProvider?: string,
  providerDeliveryStatus?: string
) => {
  return getInstance()
    .post<TSupplierOrder>(`${BASE_PATH}/${orderId}/tracking-code`, {
      trackingCode,
      trackingFile,
      deliveryProvider,
      providerDeliveryStatus,
    })
    .then(res => res.data);
};

export const updateDeliveryStatusDeliveryProvider = async (
  orderId: string,
  trackingCode: string,
  data: any
) => {
  return getInstance()
    .put<TSupplierOrder>(
      `${BASE_PATH}/${orderId}/tracking-code/${trackingCode}/delivery-status`,
      data
    )
    .then(res => res.data);
};

export const updateDeliveryStatusSupplierOrder = async (
  orderId: string,
  deliveryStatus: string,
  evidenceLink?: string
) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/delivery-status/${orderId}`, {
      deliveryStatus,
      evidenceLink,
    })
    .then(res => res.data);
};

export const splitSupplierOrder = async (orderId: string, data: any[]) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/split`, data)
    .then(res => res.data);
};

export const createProcurementSupplierOrder = async (
  supplierId: string,
  products: { id: string; quantity: number }[]
) => {
  return getInstance()
    .post<TSupplierOrder>(`${BASE_PATH}/procurement`, { supplierId, products })
    .then(res => res.data);
};

export const quoteDeliverySupplierOrder = async (data: any) => {
  return getInstance()
    .post<any>(`${BASE_PATH}/quote-delivery`, data)
    .then(res => res.data);
};

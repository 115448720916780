import ProductImage from "@/components/layout/ProductImage";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SmallImageInput } from "@/components/shared/Input/SmallImageInput";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { isUSA } from "@/constants/core";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { useIsProductBrandOwner } from "@/hooks/useProducts";
import { useSupplierAddressMain } from "@/hooks/useSupplier";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierLimit,
  createSupplierProcurementV2,
  createSupplierVariantV2,
  getProductById,
  relateSupplierVariantV2,
  TSupplierProduct,
  updateProductV2,
} from "@/http";
import { useAuth } from "@/store/auth";
import { useProductsCreation } from "@/store/products-creation";
import { uploadImageProductObject } from "@/utils/image-upload";
import { validateOnlyNumber } from "@/utils/keyboard";
import { generateRandomSKU } from "@/utils/sku";
import { generateSlug } from "@/utils/slug";
import {
  getCurrentOptionString,
  getVariantOptionsString,
  optionsToString,
} from "@/utils/variants";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillWarning } from "react-icons/ai";
import { useNavigate, useParams } from "react-router";
import styles from "./styles.module.scss";

export const TableRelate = () => {
  const [t] = useTranslation("products-page");
  const [isLoading, setIsLoading] = useState(false);
  const { productRelate } = useProductsCreation();
  const productOptions = Object.keys(productRelate?.group?.options ?? {});
  const { id } = useParams();
  const { user } = useAuth();
  const mainAddress = useSupplierAddressMain(user.id);
  const navigate = useNavigate();
  const { id: supplierId, marginSeeri = 0, negotiationType } = user;
  const { FIXED_MARGIN, VARIABLE_MARGIN, PRICE_LIST } = NEGOTIATION_TYPE;
  const isSupplierBrand = Boolean(useIsProductBrandOwner(id!, user.id));
  const methods = useForm({
    defaultValues: {
      generateSku: true,
      variants:
        productRelate?.group?.products?.map((product: any) => {
          const isRelated = !!product?.suppliers?.find(
            (productSupplier: TSupplierProduct) =>
              productSupplier?.supplierId === user.id
          );

          return {
            price: product?.retailPrice ?? null,
            required: product.id === id && !isRelated,
            related: isRelated,
            sku: "",
            realSku: product.sku,
            stock: null,
            discount: null,
            id: product.id,
            units: null,
          };
        }) || [],
      newVariants: [] as any,
    } as any,
  });

  const addVariant = () => {
    const options: any = {};
    productOptions.forEach((key: string) => {
      options[key] = "";
    });
    methods.setValue("newVariants", [
      ...methods.watch("newVariants"),
      {
        required: true,
        sku: "",
        price: null,
        stock: null,
        discount: null,
        image: "",
        units: null,
        variant: options,
      },
    ]);
  };

  const generatePricesPayload = async (
    payload: any,
    variant: any,
    values: any
  ) => {
    payload.productId = variant?.id;
    payload.supplierId = supplierId;
    payload.marginSeeri = marginSeeri;
    payload.disableInStockOut = true;
    payload.securityStock = 0;
    payload.status = true;

    if ([FIXED_MARGIN, VARIABLE_MARGIN].includes(negotiationType)) {
      payload.wholesalePrice = variant?.price;
      payload.supplierPrice = 0;
    }

    if ([PRICE_LIST].includes(negotiationType)) {
      payload.supplierPrice = variant?.price;
      payload.wholesalePrice =
        variant?.price / (1 - (user.priceListIncreasePercentage ?? 0) / 100);
    }

    payload.retailPrice = isSupplierBrand ? variant?.price : 0;

    payload.supplierSku = values.generateSku
      ? variant.realSku ??
        (await generateRandomSKU(
          productRelate.group.categoryId,
          productRelate.group.brandId
        ))
      : variant.sku;

    return payload;
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const { variants, newVariants } = values;
      const variantsMapped = variants.filter(({ required }: any) => !!required);
      const newVariantsMapped = newVariants.filter(
        ({ required }: any) => !!required
      );
      if (variantsMapped?.length || newVariantsMapped?.length) {
        for (let i = 0; i < variantsMapped.length; i++) {
          try {
            const payload = {
              ...(await generatePricesPayload({}, variantsMapped[i], values)),
            } as any;
            if (payload?.retailPrice) {
              await updateProductV2(variantsMapped[i]?.id, {
                retailPrice: payload.retailPrice,
              } as any);
            }
            const productSupplier = await relateSupplierVariantV2(payload);
            await createSupplierProcurementV2(productSupplier?.id, {
              type: "SUPPLY",
              createdBy: user.id,
              warehouseId: mainAddress?.id,
              amount: variantsMapped[i]?.stock,
              destiny: "AVAILABLE",
            });
            if (variantsMapped[i].discount > 0) {
              await createSupplierLimit({
                applier: "PRODUCT",
                referenceId: variantsMapped[i]?.id,
                supplierId: user.id,
                type: "DISCOUNT",
                valueType: "AMOUNT",
                value: variantsMapped[i]?.units,
                percentage: variantsMapped[i]?.discount,
              });
            }
          } catch (e) {
            showToastError(e);
          }
        }

        for (let i = 0; i < newVariantsMapped.length; i++) {
          try {
            const payload = {} as any;
            const variant = newVariantsMapped[i];
            const temporalPayload = await generatePricesPayload(
              {},
              variant,
              values
            );
            payload.supplierSku = variant.sku;
            if (values.generateSku) {
              payload.supplierSku = await generateRandomSKU(
                productRelate.group.categoryId,
                productRelate.group.brandId
              );
            }

            payload.suppliers = [
              {
                supplierId: user.id,
                supplierSku: payload.supplierSku,
                supplierPrice: temporalPayload?.supplierPrice,
                wholesalePrice: temporalPayload?.wholesalePrice,
                marginSeeri,
                disableInStockOut: true,
                securityStock: 0,
                status: true,
              },
            ];
            payload.categoryId = productRelate.group.categoryId;
            payload.brandId = productRelate.group.brandId;

            payload.name = `${productRelate.group.name} ${optionsToString(
              variant.variant
            )}`;
            payload.description = payload.name;
            payload.longDescription = payload.name;
            payload.slug = generateSlug(payload.name);
            payload.productOptions = variant.variant;
            payload.retailPrice = temporalPayload.retailPrice;
            payload.images = variant?.image
              ? [await uploadImageProductObject(variant?.image)]
              : [];

            payload.groupProductId = productRelate.group.id;
            payload.disableInStockOut = true;
            payload.marginSeeri = marginSeeri;
            payload.status = true;
            payload.featured = true;
            payload.statusRetail = true;
            payload.locationType = "SUPPLIER_STORAGE";
            payload.approvedStatus = "APPROVED";
            payload.height = 20;
            payload.width = 20;
            payload.length = 20;
            payload.weight = 1;

            const temporalProduct = await createSupplierVariantV2(payload);
            const product = await getProductById(temporalProduct.id);

            await createSupplierProcurementV2(product?.suppliers[0]?.id, {
              type: "SUPPLY",
              createdBy: user.id,
              warehouseId: mainAddress?.id,
              amount: variant?.stock,
              destiny: "AVAILABLE",
            });

            if (variant.discount > 0) {
              await createSupplierLimit({
                applier: "PRODUCT",
                referenceId: product?.id,
                supplierId: user.id,
                type: "DISCOUNT",
                valueType: "AMOUNT",
                value: variant?.units,
                percentage: variant?.discount,
              });
            }
          } catch (e) {
            showToastError(e);
          }
        }

        showToastSuccess(t("PRODUCTS_ADDED"));
        navigate(`/products/${id}`);
      } else {
        showToastError(t("ADD_ONE_VARIANT"));
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateVariants = (e: any) => {
    const value = e.target.checked;
    const variants = methods
      .watch("variants")
      .filter(({ related }: any) => !related);
    for (let i = 0; i < variants.length; i++) {
      const variant = methods.watch("variants")[i];
      if (variant.id !== id && !variant.related) {
        methods.setValue(`variants[${i}].required`, value);
      }
    }
    for (let i = 0; i < methods.watch("newVariants").length; i++) {
      methods.setValue(`newVariants[${i}].required`, value);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Checkbox name="generateSku" label={t("GENERATE_SKU")} />

        <div className="spacer" />

        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.row}>
                <th>
                  <Checkbox
                    name="allVariants"
                    label=""
                    onChange={validateVariants}
                  />
                </th>
                <th>{t("IMAGE")}</th>
                {productOptions.map((option: string, idx: number) => (
                  <th key={idx}>{option.toUpperCase()}</th>
                ))}
                <th>{t("PRICE")}</th>
                <th>{t("SKU")}</th>
                <th>{t("STOCK")}</th>
                <th>{t("DISCOUNT")} (%)</th>
                <th className="textLine">{t("DISCOUNT_FROM")} ($)</th>
              </tr>
            </thead>
            <tbody>
              {(productRelate?.group?.products || []).map(
                (product: any, index: number) => (
                  <ProductTableRow
                    key={index}
                    product={product}
                    index={index}
                  />
                )
              )}
              {methods.watch("newVariants").map((_: any, index: number) => (
                <NewVariantTableRow key={index} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </FormProvider>
      <div className="spacer" />
      <div className="contentInline">
        {Object.keys(productRelate?.group?.options ?? {}).length > 0 ? (
          <button className="textGreen bold" onClick={addVariant}>
            + {t("ADD_MORE_VALUES")}
          </button>
        ) : (
          <span />
        )}
        <button
          className="primary lg"
          disabled={isLoading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {t("SAVE")}
        </button>
      </div>
      <div className="spacer" />
    </>
  );
};

const ProductTableRow = ({
  product,
  index,
}: {
  product: any;
  index: number;
}) => {
  const methods = useFormContext();
  const { productRelate } = useProductsCreation();
  const isRequired = methods.watch(`variants[${index}].required`);
  const isRelated = methods.watch(`variants[${index}].related`);
  const productOptions = Object.keys(productRelate?.group?.options ?? {});
  const [t] = useTranslation("products-page");

  useEffect(() => {
    const variantsFiltered = methods
      .watch("variants")
      .filter(({ related }: any) => !related);
    const newVariants = methods.watch("newVariants") as Array<any>;

    const allRequired = [...variantsFiltered, ...newVariants].every(
      ({ required }: any) => !!required
    );
    methods.setValue("allVariants", allRequired);
  }, [isRequired]);

  return (
    <tr
      className={`${styles.row} ${
        isRequired && !isRelated ? "" : styles.disabledRow
      }`}
      key={index}
    >
      <td>
        {!isRelated ? (
          <Checkbox name={`variants[${index}].required`} label="" />
        ) : (
          <ToolTip title={`*${t("ALREADY_SUPPLIER")}`} position="Right">
            <AiFillWarning className="warningIcon" />
          </ToolTip>
        )}
      </td>
      <td>
        <ProductImage images={product.images} />
      </td>
      {productOptions.map((option: string, idx: number) => (
        <td key={idx}>{get(product.productOptions, option)}</td>
      ))}
      <td>
        <NumericInput
          name={`variants[${index}].price`}
          required={isRequired && !isRelated}
          label=""
          decimals
          hideControls
          min={1}
        />
      </td>
      <td>
        {methods.watch("generateSku") === true || !isRequired ? (
          product.sku
        ) : (
          <InputV2
            required={isRequired && !isRelated}
            name={`variants[${index}].sku`}
            label=""
            type="text"
          />
        )}
      </td>
      <td>
        <NumericInput
          name={`variants[${index}].stock`}
          required={isRequired && !isRelated}
          label=""
          decimals
          min={1}
        />
      </td>
      <td>
        <NumericInput
          name={`variants[${index}].discount`}
          required={isRequired && !isRelated}
          label=""
          min={0}
        />
      </td>
      <td>
        <InputV2
          type="text"
          name={`variants[${index}].units`}
          disabled={methods.watch(`variants[${index}].discount`) === 0}
          required={
            isRequired &&
            !isRelated &&
            Number(methods.watch(`variants[${index}].discount`)) > 0
          }
          label=""
          min={0}
          onKeyPress={validateOnlyNumber}
        />
      </td>
    </tr>
  );
};

const NewVariantTableRow = ({ index }: { index: number }) => {
  const [t] = useTranslation("products-page");
  const methods = useFormContext();
  const { productRelate } = useProductsCreation();
  const [optionsString, setOptionsString] = useState<any[]>(
    getVariantOptionsString(index, productRelate?.group?.products ?? [], [])
  );
  const productGroupOptions = Object.keys(productRelate?.group?.options ?? {});
  const isRequired = methods.watch(`newVariants[${index}].required`);

  const validateCombinations = () => {
    if (!isRequired) {
      return;
    }
    return !optionsString.includes(
      getCurrentOptionString(methods.watch(`newVariants[${index}].variant`))
    );
  };

  const triggerVariants = () => {
    productGroupOptions.map((option: string) => {
      if (methods.watch(`newVariants[${index}].variant.${option}`)) {
        methods.trigger(`newVariants[${index}].variant.${option}`);
      }
    });
  };

  useEffect(() => {
    const newOptionsString = getVariantOptionsString(
      index,
      productRelate?.group?.products ?? [],
      methods.watch("newVariants")
    );
    setOptionsString(newOptionsString);
  }, [methods.watch(`newVariants`).length]);

  useEffect(() => {
    const variantsFiltered = methods
      .watch("variants")
      .filter(({ related }: any) => !related);
    const newVariants = methods.watch("newVariants") as Array<any>;
    const allRequired = [...variantsFiltered, ...newVariants].every(
      ({ required }: any) => !!required
    );
    methods.setValue("allVariants", allRequired);
  }, [isRequired]);

  return (
    <tr
      className={`${styles.row} ${isRequired ? "" : styles.disabledRow}`}
      key={index}
    >
      <td>
        <Checkbox name={`newVariants[${index}].required`} label="" />
      </td>
      <td>
        <SmallImageInput
          required={isRequired && !isUSA}
          name={`newVariants[${index}].image`}
          label=""
        />
      </td>
      {productGroupOptions.map((option: string, idx: number) => (
        <td key={idx}>
          <InputV2
            required={isRequired}
            name={`newVariants[${index}].variant.${option}`}
            label=""
            type="text"
            validate={validateCombinations}
            validMessage={t("ALREADY_COMBINATION")}
            onChange={triggerVariants}
          />
        </td>
      ))}

      <td>
        <NumericInput
          name={`newVariants[${index}].price`}
          required={isRequired}
          label=""
          decimals
          hideControls
          min={1}
        />
      </td>
      <td>
        {methods.watch("generateSku") === true ? (
          t("AUTOMATIC_GENERATION")
        ) : (
          <InputV2
            required={isRequired}
            name={`newVariants[${index}].sku`}
            label=""
            type="text"
          />
        )}
      </td>
      <td>
        <NumericInput
          name={`newVariants[${index}].stock`}
          required={isRequired}
          label=""
          decimals
          min={1}
        />
      </td>
      <td>
        <NumericInput
          name={`newVariants[${index}].discount`}
          required={isRequired}
          label=""
          min={0}
        />
      </td>
      <td>
        <InputV2
          type="text"
          name={`newVariants[${index}].units`}
          disabled={methods.watch(`newVariants[${index}].discount`) < 1}
          required={
            isRequired &&
            Number(methods.watch(`newVariants[${index}].discount`)) > 0
          }
          label=""
          min={0}
          onKeyPress={validateOnlyNumber}
        />
      </td>
    </tr>
  );
};

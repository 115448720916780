import { deleteSupplierAddressAndMoveStock, TSupplierAddress } from "@/http";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { FaTrashAlt } from "react-icons/fa";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import Tooltip from "@/components/shared/Tooltip/Tooltip";
import styles from "./styles.module.scss";
import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { useAddresses } from "@/store/profile";
import { addressToString } from "@/utils/address";
import { FormProvider, useForm } from "react-hook-form";
import { SelectV2 } from "@/components/shared/Input/SelectV2";

type TAddressDelete = {
  address: TSupplierAddress;
};
const AddressDelete = ({ address }: TAddressDelete) => {
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const { user } = useAuth();
  const [t] = useTranslation("global");
  const { getAddresses, currentPage, addresses } = useAddresses();
  const availableAddress = (addresses?.content ?? []).filter(
    a => a.id !== address.id
  );

  const onDelete = async (values: any) => {
    try {
      if (!address.id) {
        return;
      }
      const { warehouseToId } = values;
      const addressTo = warehouseToId === "NO_ID" ? null : warehouseToId;

      await deleteSupplierAddressAndMoveStock(user.id, address.id, addressTo);
      getAddresses(user.id, currentPage);
      showToastSuccess(t("ACCOUNT.ADDRESSES.SUCCESSFUL_DELETE"));
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <Tooltip title={t("ACCOUNT.ADDRESSES.DELETE")}>
        <span className={styles.iconButton} onClick={() => setIsOpen(true)}>
          <FaTrashAlt size={15} />
        </span>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("ACCOUNT.ADDRESSES.DELETE")}
        footer={
          <div className={styles.actions}>
            <button onClick={() => setIsOpen(false)}>
              {t("ACCOUNT.ADDRESSES.CANCEL")}
            </button>
            <button
              className="primary"
              onClick={methods.handleSubmit(onDelete)}
            >
              {t("ACCOUNT.ADDRESSES.DELETE")}
            </button>
          </div>
        }
      >
        <div>
          <p>{t("ACCOUNT.ADDRESSES.CONFIRM_DELETE")}</p>
          <div className="spacer" />
          <p className="bold">{address?.name}</p>
          <p className="mdFont">{addressToString(address)}</p>
          <div className="spacer" />
          <FormProvider {...methods}>
            <SelectV2
              name="warehouseToId"
              label={t("SELECT_TO_WAREHOUSE")}
              required
              choices={[
                ...availableAddress.map(a => ({
                  label: addressToString(a),
                  value: a.id,
                })),
                { label: t("DELETE_STOCK"), value: "NO_ID" },
              ]}
            />
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};

export default AddressDelete;

import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSerial, TProductSerial, updateSerial } from "@/http";
import { uploadImageProductObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import { ImagesList } from "../../ProductsForm/ProductImageList";
import styles from "./styles.module.scss";

type TSerialModal = {
  productId: string;
  serial?: TProductSerial;
  slim?: boolean;
  onDone?: (...args: any) => void;
};
export const SerialModal = ({
  productId,
  serial,
  onDone,
  slim,
}: TSerialModal) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = serial?.id
    ? { ...serial, attachments: serial?.attachments || [] }
    : {
        attachments: [],
      };
  const methods = useForm({
    defaultValues,
  });

  const onClose = () => {
    methods.reset(defaultValues);
    setIsOpen(false);
  };

  const onSubmit = async (values: Partial<TProductSerial>) => {
    try {
      setIsLoading(true);

      const data: any = {
        serialNumber: values.serialNumber,
        attachments: [],
      };
      const files = values?.attachments || [];

      if (files.length > 0) {
        await Promise.all(files.map(uploadImageProductObject)).then(images => {
          data.attachments = images;
          return data;
        });
      }

      let newSerial;
      if (serial?.id) {
        newSerial = await updateSerial(productId, serial.id, data);
      } else {
        newSerial = await createSerial(productId, data);
      }
      methods.reset(defaultValues);
      onDone?.(newSerial);

      showToastSuccess(t(serial?.id ? "SERIAL_UPDATED" : "SERIAL_CREATED"));
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {serial?.id ? (
        <ToolTip title={t("EDIT")} position="Left">
          <span className="pointer circleButton">
            <BiEdit
              onClick={() => {
                methods.reset(defaultValues);
                setIsOpen(true);
              }}
            />
          </span>
        </ToolTip>
      ) : (
        <>
          {slim ? (
            <span
              className="purpleText pointer"
              onClick={() => setIsOpen(true)}
            >
              {t("CREATE_SERIAL")}
            </span>
          ) : (
            <button
              type="button"
              className="outlined"
              onClick={() => setIsOpen(true)}
            >
              {t("CREATE_SERIAL")}
            </button>
          )}
        </>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        title={t(serial?.id ? "EDIT_SERIAL" : "CREATE_SERIAL")}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t(serial?.id ? "SAVE" : "CREATE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2 name="serialNumber" label={t("SERIAL")} required />
          <p>{t("EVIDENCE")}</p>
          <DragAndDropFile
            minifiedStyle
            accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
            files={methods.watch("attachments")}
            setFiles={(images: any[]) =>
              methods.setValue("attachments", images)
            }
          />
          <div className="spacer" />
          <div className={styles.imagesList}>
            <ImagesList
              minified
              images={methods.watch("attachments")}
              setImages={(images: any[]) => {
                methods.setValue("attachments", images);
              }}
            />
          </div>
        </FormProvider>
      </Modal>
    </>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { deleteSupplierEvent } from "@/http/resources/suppliers/events";
import { TSupplierEvent } from "@/http";

type TClientDeleteEvent = {
  event: TSupplierEvent;
  onDone: () => void;
};
export const ClientDeleteEvent = ({ event, onDone }: TClientDeleteEvent) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierEvent(event?.id ?? "");
      setIsLoading(false);
      setModalOpen(false);
      onDone?.();
      showToastSuccess(t("EVENT_DELETED"));
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <span className="circleButton pointer" onClick={() => setModalOpen(true)}>
        <AiOutlineDelete />
      </span>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        title={t("DELETE_EVENT")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{`${t("SURE_DELETE_EVENT")} "${event.title}"?`}</p>
      </Modal>
    </>
  );
};

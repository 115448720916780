import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { PurchasesListSort } from "../PurchaseListActions/PurchasesListSort";
import { PurchaseTableFilters } from "../PurchaseListActions/PurchaseTableFilters";
import { PurchaseSupplierFilter } from "./PurchaseSupplierFilter";
import styles from "./styles.module.scss";

export const PurchaseListFilters = () => {
  const debounceRef = useRef<any>();
  const [t] = useTranslation("global");
  const [search, setSearch] = useSearchParams();
  const purchaseNumberFilter = search.get("purchaseNumber");

  const onPurchaseNumberQueryChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === purchaseNumberFilter) {
      return;
    }
    const newSearch = new URLSearchParams(search);
    newSearch.set("purchaseNumber", event.target.value);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      newSearch.set("page", "0");
      setSearch(newSearch);
    }, 350);
  };

  return (
    <div className={styles.containerFilters}>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          autoComplete={"off"}
          type="text"
          placeholder={t("SEARCH")}
          onChange={onPurchaseNumberQueryChanged}
          defaultValue={purchaseNumberFilter ?? ""}
        />
      </div>
      <PurchaseSupplierFilter />
      <PurchasesListSort />
      <PurchaseTableFilters />
    </div>
  );
};

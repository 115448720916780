import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import { reopenTicket, TTicketDetail } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const TicketReopen = ({
  ticket,
  onDone,
}: {
  ticket: TTicketDetail;
  onDone: (...args: any) => void;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await reopenTicket(ticket.id);
      onDone();
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onClose = () => {
    setIsOpen(false);
  };

  if (!["APPROVED", "REJECTED"].includes(ticket.status)) {
    return null;
  }

  return (
    <>
      <button className="primary lg" onClick={() => setIsOpen(true)}>
        {t("REOPEN_TICKET")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("REOPEN_TICKET")}
        size="sm"
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t("IM_SURE")}
            </button>
          </>
        }
      >
        <p>{t("ARE_YOU_SURE_REOPEN_TICKET")}</p>
      </Modal>
    </>
  );
};

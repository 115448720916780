import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { isUSA } from "@/constants/core";
import {
  DATE_HOUR,
  DATE_HOUR_NO_SPACES,
  USA_DATE_HOUR,
  USA_DATE_HOUR_NO_SPACES,
} from "@/constants/date";
import { MAX_ORDERS_PER_PAGE } from "@/constants/export-orders";
import {
  deliveryStatusRendering,
  paymentStatus,
  supplierStatus,
} from "@/constants/purchases";
import { showToastError } from "@/hooks/useToast";
import {
  getPurchasesByIds,
  getSupplierOrders,
  getSupplierPendingProducts,
  TPurchase,
} from "@/http";
import { OrderFilters } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { useOrderFilter } from "@/store/orders";
import { getCommonSearchFilter } from "@/utils/orders";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

export const Export = () => {
  const { filter, startDateFilter, endDateFilter } = useOrderFilter();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const [globalT] = useTranslation("global");

  const exportAction = (action: string) => {
    switch (action) {
      case "orders":
        exportOrders();
        break;
      case "pendingProducts":
        exportProducts();
        break;
    }
  };

  const exportProducts = async () => {
    try {
      setIsLoading(true);
      const pendingProducts = await getSupplierPendingProducts(user.id);
      const dataArray = pendingProducts.map(item => [
        item?.productName ?? "",
        item?.brandName ?? "",
        item?.debt ?? 0,
        item?.ordersAmount ?? 0,
        item?.orders ?? "",
      ]);
      const ws = XLSX.utils.aoa_to_sheet([
        [
          t("PRODUCT_NAME"),
          t("BRAND"),
          t("PENDING_PRODUCTS_QUANTITY"),
          t("ORDERS_PENDING_QUANTITY"),
          t("ORDERS_PENDING"),
        ],
        ...dataArray,
      ]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, t("PENDING_PRODUCTS"));
      const csvData = XLSX.write(wb, { bookType: "csv", type: "array" });
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("PENDING_PRODUCTS_SNAKE")}_${format(
        new Date(),
        isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
      )}.csv`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportOrders = async () => {
    try {
      setIsLoading(true);
      const search = getCommonSearchFilter(
        startDateFilter,
        endDateFilter,
        filter
      );
      const { content: orders = [] } = await getSupplierOrders(user.id, {
        size: MAX_ORDERS_PER_PAGE,
        page: 0,
        search,
      });

      const purchasesIds = orders.map(o => o.purchaseId);

      const purchases = await getPurchasesByIds(purchasesIds);
      const purchasesMap: Record<string, TPurchase> = {};

      for (let i = 0; i < purchases.length; i++) {
        const obj = purchases[i];
        purchasesMap[obj.id] = obj;
      }

      const dataArray = orders.map(order => [
        order?.subPurchaseNumber ?? "-",
        format(new Date(order.createdAt), isUSA ? USA_DATE_HOUR : DATE_HOUR),
        supplierStatus.find(({ code }) => order.supplierStatus === code)
          ?.name ?? "-",
        order.trackingCode ?? t("NO_GUIDE"),
        order.total || 0,
        globalT(`PAYMENT_METHOD.${order.paymentMethod}`),
        order.clientName,
        order?.createdByName,
        paymentStatus.find(
          s => s.code === purchasesMap[order.purchaseId]?.paymentStatus
        )?.name,
        deliveryStatusRendering.find(
          s => s.code === purchasesMap[order.purchaseId]?.deliveryStatus
        )?.name,
      ]);
      const ws = XLSX.utils.aoa_to_sheet([
        [
          t("ORDER_NUMBER"),
          t("CREATED"),
          t("STATUS_NAME"),
          t("GUIDE_NUMBER"),
          t("TOTAL"),
          t("PAYMENT_METHOD"),
          t("CLIENT"),
          t("CREATED_BY"),
          t("PAYMENT_STATUS"),
          t("DELIVERY_STATUS"),
        ],
        ...dataArray,
      ]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, t("ORDERS_NAME"));
      const csvData = XLSX.write(wb, { bookType: "csv", type: "array" });
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("ORDERS_NAME")}_${format(
        new Date(),
        isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
      )}.csv`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SingleMenu
        onSelect={exportAction}
        hideOnSelect
        align="rigth"
        width={87}
        label={
          <button
            className="primary textLine xl col-sm-12"
            disabled={isLoading}
          >
            {t("EXPORT")}
          </button>
        }
        options={[
          {
            value: "pendingProducts",
            label: t("PENDING_PRODUCTS"),
          },
          {
            value: "orders",
            label: `${t("ORDERS_NAME")} ${
              (OrderFilters.find(({ value }) => value === filter) ?? {})
                ?.show ?? ""
            }`,
          },
        ]}
      />
    </>
  );
};

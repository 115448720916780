import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createProductUnitType,
  getProductById,
  TUnitType,
  updateProductUnitType,
} from "@/http";
import { useProducts } from "@/store/products";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";

type TUnitTypeModal = {
  unitType?: TUnitType;
};
export const UnitTypeModal = ({ unitType }: TUnitTypeModal) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = unitType?.id ? { ...unitType } : {};
  const { selectedVariantEdit, setSelectedVariant } = useProducts();
  const methods = useForm({
    defaultValues,
  });

  const onClose = () => {
    methods.reset(defaultValues);
    setIsOpen(false);
  };

  const onSubmit = async (values: Partial<TUnitType>) => {
    try {
      setIsLoading(true);
      const data = {
        isDefault:
          !selectedVariantEdit?.unitTypes?.length || !!unitType?.isDefault,
        name: values.name,
        rate: values.rate,
      };

      if (unitType?.id) {
        const unit = await updateProductUnitType(
          selectedVariantEdit?.id,
          unitType?.id,
          data
        );
        methods.reset(unit);
      } else {
        await createProductUnitType(selectedVariantEdit?.id, data);
        methods.reset({});
      }
      const product = await getProductById(selectedVariantEdit.id);
      setSelectedVariant(product);
      showToastSuccess(
        t(unitType?.id ? "UNIT_TYPE_UPDATED" : "UNIT_TYPE_CREATED")
      );
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {unitType?.id ? (
        <ToolTip title={t("EDIT")} position="Left">
          <span className="pointer circleButton">
            <BiEdit
              onClick={() => {
                methods.reset(defaultValues);
                setIsOpen(true);
              }}
            />
          </span>
        </ToolTip>
      ) : (
        <button
          type="button"
          className="outlined"
          onClick={() => setIsOpen(true)}
        >
          {t("CREATE_UNIT_TYPE")}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        title={t(unitType?.id ? "EDIT_UNIT_TYPE" : "CREATE_UNIT_TYPE")}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t(unitType?.id ? "SAVE" : "CREATE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2 name="name" label={t("NAME")} required />
          <NumericInput
            name="rate"
            label={t("RATE")}
            required
            hideControls
            decimals
          />
        </FormProvider>
      </Modal>
    </>
  );
};

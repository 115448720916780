import { useAuth } from "@/store/auth";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import styles from "./styles.module.scss";
import { updateSupplier } from "@/http";
import { showToastError } from "@/hooks/useToast";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

export const PaymentLink = () => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, refreshUserData } = useAuth();
  const defaultValues = { paymentLink: user.paymentLink };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await updateSupplier(user.id, {
        paymentLink: values.paymentLink,
      } as any);
      await refreshUserData();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <p className="bold">{t("PAYMENT_LINK")}</p>
      {isOpen ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                className={`${styles.input} ${
                  errors.paymentLink ? styles.required : ""
                }`}
                disabled={isLoading}
                defaultValue={user.paymentLink}
                placeholder={t("PAYMENT_LINK")}
                {...register("paymentLink", {
                  required: t("FIELD_REQUIRED"),
                  pattern: {
                    value: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
                    message: t("URL_FORMAT_NOT_VALID"),
                  },
                })}
              />
              <br />
              <span className={styles.errorsLabel}>
                {errors.paymentLink && errors.paymentLink.message}
              </span>
            </div>
            <div className={styles.actions}>
              <button
                disabled={isLoading}
                className="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {t("SAVE")}
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
              >
                {t("CANCEL")}
              </button>
            </div>
          </form>
        </>
      ) : (
        <div className={styles.inlineDescription}>
          {user?.paymentLink || t("NO_PAYMENT_LINK")}
          <ToolTip title={t("EDIT")}>
            <AiOutlineEdit className="pointer" onClick={() => setOpen(true)} />
          </ToolTip>
        </div>
      )}
      <div className={styles.spacer} />
    </>
  );
};

import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { SmallImageInput } from "@/components/shared/Input/SmallImageInput";
import { isUSA } from "@/constants/core";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { useSupplierAddressMain } from "@/hooks/useSupplier";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierLimit,
  createSupplierProcurementV2,
  createSupplierProductGroupV2,
  createSupplierVariantV2,
  getBrandById,
  getProductById,
} from "@/http";
import { useAuth } from "@/store/auth";
import { uploadImageProductObject } from "@/utils/image-upload";
import { validateOnlyNumber } from "@/utils/keyboard";
import { generateRandomSKU } from "@/utils/sku";
import { generateSlug } from "@/utils/slug";
import {
  getCurrentOptionString,
  getVariantOptionsString,
} from "@/utils/variants";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";

export const GroupVariantsTable = () => {
  const methods = useFormContext();
  const variantsOptions = methods.watch("variantFormValues");
  const optionsKeys = Object.keys(variantsOptions ?? {});
  const hideForm = !!methods.watch("hideVariantForm");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const mainAddress = useSupplierAddressMain(user.id);
  const navigate = useNavigate();
  const { id: supplierId, marginSeeri = 0, negotiationType } = user;
  const { FIXED_MARGIN, VARIABLE_MARGIN, PRICE_LIST } = NEGOTIATION_TYPE;
  const [options, setOptions] = useState<string[]>([]);
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");

  const addVariant = () => {
    methods.setValue("newVariants", [
      ...methods.watch("newVariants"),
      {
        required: true,
        sku: "",
        price: 0,
        stock: 0,
        discount: 0,
        image: "",
        units: 0,
      },
    ]);
  };

  const generatePricesPayload = async (
    payload: any,
    variant: any,
    values: any,
    isSupplierBrand: boolean,
    group: any
  ) => {
    payload.productId = variant?.id;
    payload.supplierId = supplierId;
    payload.marginSeeri = marginSeeri;
    payload.status = true;
    payload.disableInStockOut = true;

    if ([FIXED_MARGIN, VARIABLE_MARGIN].includes(negotiationType)) {
      payload.wholesalePrice = variant?.price;
      payload.supplierPrice = 0;
    }

    if ([PRICE_LIST].includes(negotiationType)) {
      payload.supplierPrice = variant?.price;
      payload.wholesalePrice =
        variant?.price / (1 - (user.priceListIncreasePercentage ?? 0) / 100);
    }

    payload.retailPrice = isSupplierBrand ? variant?.price : 0;

    payload.supplierSku = values.generateSku
      ? variant.realSku ??
        (await generateRandomSKU(group.categoryId, group.brandId))
      : variant.sku;

    return payload;
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const { variants, newVariants } = values;
      const variantsMapped = variants.filter(({ required }: any) => !!required);
      const newVariantsMapped = newVariants.filter(
        ({ required }: any) => !!required
      );

      if (variantsMapped?.length || newVariantsMapped?.length) {
        const payload = {
          brandId: values.brandId,
          categoryId: values.categoryId,
          name: values.name,
          description: values.description,
          type: values.type,
          taxesType: values.type === "GIFT" ? [] : ["IVA"],
          status: true,
          featured: false,
          supplierId: user.id,
          options: values.variantFormValues,
          products: [] as any[],
          images: values?.images ?? [],
        };

        if (payload?.images?.length) {
          await Promise.all(
            payload?.images?.map(uploadImageProductObject)
          ).then(images => {
            payload.images = images;
          });
        }

        const productGroup = await createSupplierProductGroupV2(payload as any);

        const allMappedVariants = [...variantsMapped, ...newVariantsMapped];
        const brandSelected = await getBrandById(values.brandId);
        const isSupplierBrand = brandSelected?.supplierId === user.id;

        for (let i = 0; i < allMappedVariants.length; i++) {
          try {
            const payload = {} as any;
            const variant = allMappedVariants[i];
            const temporalPayload = await generatePricesPayload(
              {},
              variant,
              values,
              isSupplierBrand,
              productGroup
            );
            payload.supplierSku = variant.sku;
            if (values.generateSku) {
              payload.supplierSku = await generateRandomSKU(
                productGroup.categoryId,
                productGroup.brandId
              );
            }

            payload.suppliers = [
              {
                supplierId: user.id,
                supplierSku: payload.supplierSku,
                supplierPrice: temporalPayload?.supplierPrice,
                wholesalePrice: temporalPayload?.wholesalePrice,
                marginSeeri,
                disableInStockOut: true,
                securityStock: 0,
                status: true,
              },
            ];
            payload.categoryId = productGroup.categoryId;
            payload.brandId = productGroup.brandId;
            payload.name = `${productGroup.name} ${optionsKeys
              .map(option => variant?.variant?.[option])
              .toString()}`;
            payload.description = payload.name;
            payload.longDescription = payload.name;
            payload.slug = generateSlug(payload.name);
            payload.productOptions = variant?.variant;
            payload.retailPrice = temporalPayload.retailPrice;
            payload.images = variant?.image
              ? [await uploadImageProductObject(variant?.image)]
              : [];
            payload.groupProductId = productGroup.id;
            payload.disableInStockOut = true;
            payload.marginSeeri = marginSeeri;
            payload.status = true;
            payload.featured = false;
            payload.statusRetail = true;
            payload.locationType = "SUPPLIER_STORAGE";
            payload.approvedStatus = "APPROVED";
            payload.height = 20;
            payload.width = 20;
            payload.length = 20;
            payload.weight = 1;

            const temporalProduct = await createSupplierVariantV2(payload);
            const product = await getProductById(temporalProduct.id);

            await createSupplierProcurementV2(product?.suppliers[0]?.id, {
              type: "SUPPLY",
              createdBy: user.id,
              warehouseId: mainAddress?.id,
              amount: variant?.stock,
              destiny: "AVAILABLE",
            });

            if (variant.discount > 0) {
              await createSupplierLimit({
                applier: "PRODUCT",
                referenceId: product?.id,
                supplierId: user.id,
                type: "DISCOUNT",
                valueType: "AMOUNT",
                value: variant?.units,
                percentage: variant?.discount,
              });
            }
          } catch (e) {
            showToastError(e);
          }
        }

        showToastSuccess(t("PRODUCT_CREATED"));
        navigate(`/products`);
      } else {
        showToastError({ message: tGlobal("ADD_ONE_VARIANT") });
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateVariants = (e: any) => {
    const value = e.target.checked;
    const variants = methods.watch("variants");
    for (let i = 0; i < variants.length; i++) {
      methods.setValue(`variants[${i}].required`, value);
    }
    for (let i = 0; i < methods.watch("newVariants").length; i++) {
      methods.setValue(`newVariants[${i}].required`, value);
    }
  };

  useEffect(() => {
    setOptions(Object.keys(variantsOptions ?? {}));
    methods.setValue("variants", []);
  }, [variantsOptions]);

  if (!hideForm) {
    return null;
  }

  return (
    <>
      <Checkbox name="generateSku" label={t("GENERATE_SKU")} />

      <div className="spacer" />

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.row}>
              <th>
                <Checkbox
                  name="allVariants"
                  label=""
                  onChange={validateVariants}
                />
              </th>
              <th>{t("IMAGE")}</th>
              {options.map((option: string, idx: number) => (
                <th key={idx}>{option.toUpperCase()}</th>
              ))}
              <th>{t("PRICE")}</th>
              <th>{t("SKU")}</th>
              <th>{t("STOCK")}</th>
              <th>{t("TABLE.DISCOUNTS")} (%)</th>
              <th>{t("TABLE.DISCOUNTS_FROM")} ($)</th>
            </tr>
          </thead>
          <tbody>
            {(methods.watch("variants") || []).map(
              (product: any, index: number) => (
                <ProductTableRow
                  key={index}
                  product={product}
                  index={index}
                  source="variants"
                />
              )
            )}
            {methods
              .watch("newVariants")
              ?.map((product: any, index: number) => (
                <ProductTableRow
                  key={index}
                  index={index}
                  product={product}
                  source="newVariants"
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="spacer" />
      <div className="contentInline">
        <button className="textGreen bold" onClick={addVariant}>
          + {t("ADD_OPTION")}
        </button>
        <button
          className="primary lg"
          disabled={isLoading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {t("SAVE")}
        </button>
      </div>
      <div className="spacer" />
    </>
  );
};

const ProductTableRow = ({
  product,
  index,
  source,
}: {
  product: any;
  index: number;
  source: string;
}) => {
  const methods = useFormContext();
  const [t] = useTranslation("products-page");
  const isRequired = methods.watch(`${source}[${index}].required`);
  const variantsOptions = methods.watch("variantFormValues");
  const optionsKeys = Object.keys(variantsOptions);
  const [optionsString, setOptionsString] = useState<any[]>(
    getVariantOptionsString(index, [], [])
  );

  const validateCombinations = () => {
    if (!isRequired) {
      return;
    }

    return !optionsString.includes(
      getCurrentOptionString(methods.watch(`newVariants[${index}].variant`))
    );
  };

  const triggerVariants = () => {
    optionsKeys.map((option: string) => {
      if (methods.watch(`newVariants[${index}].variant.${option}`)) {
        methods.trigger(`newVariants[${index}].variant.${option}`);
      }
    });
  };

  useEffect(() => {
    const variantsFiltered = methods.watch("variants");
    const newVariants = methods.watch("newVariants") as Array<any>;

    const allRequired = [...variantsFiltered, ...newVariants].every(
      ({ required }: any) => !!required
    );
    methods.setValue("allVariants", allRequired);
  }, [isRequired]);

  useEffect(() => {
    const newOptionsString = getVariantOptionsString(
      index,
      [],
      methods.watch("newVariants")
    );
    setOptionsString(newOptionsString);
  }, [methods.watch(`newVariants`).length]);

  return (
    <tr
      className={`${styles.row} ${isRequired ? "" : styles.disabledRow}`}
      key={index}
    >
      <td>
        <Checkbox name={`${source}[${index}].required`} label="" />
      </td>
      <td>
        <SmallImageInput
          required={isRequired && !isUSA}
          name={`${source}[${index}].image`}
          label=""
        />
      </td>
      {optionsKeys.map((option: string, idx: number) => (
        <td key={idx}>
          {product.option ?? (
            <SelectV2
              name={`${source}[${index}].variant.${option}`}
              choices={variantsOptions[option].map((choice: string) => ({
                value: choice,
                label: choice,
              }))}
              label=""
              required={isRequired}
              validate={validateCombinations}
              validMessage={t("ALREADY_COMBINATION")}
              onChange={triggerVariants}
            />
          )}
        </td>
      ))}
      <td>
        <NumericInput
          name={`${source}[${index}].price`}
          required={isRequired}
          label=""
          decimals
          hideControls
          min={1}
        />
      </td>
      <td>
        {methods.watch("generateSku") === true ? (
          t("AUTOMATIC_GENERATION")
        ) : (
          <InputV2
            required={isRequired}
            name={`${source}[${index}].sku`}
            label=""
            type="text"
          />
        )}
      </td>
      <td>
        <NumericInput
          name={`${source}[${index}].stock`}
          required={isRequired}
          label=""
          decimals
          min={1}
        />
      </td>
      <td>
        <NumericInput
          name={`${source}[${index}].discount`}
          required={isRequired}
          label=""
          min={0}
        />
      </td>
      <td>
        <InputV2
          type="text"
          name={`${source}[${index}].units`}
          disabled={methods.watch(`${source}[${index}].discount`) === 0}
          required={isRequired}
          label=""
          min={0}
          onKeyPress={validateOnlyNumber}
        />
      </td>
    </tr>
  );
};

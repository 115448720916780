import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSegment, TSegment } from "@/http";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type TDeleteSegment = {
  segment: TSegment;
  onDone?: () => void;
};
export const DeleteSegment = ({ segment, onDone }: TDeleteSegment) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSegment(segment.id);
      setIsLoading(false);
      setModalOpen(false);
      showToastSuccess(t("DELETED"));
      onDone?.();
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <ToolTip title={t("DELETE")} position="Left">
        <AiOutlineDelete cursor="pointer" onClick={() => setModalOpen(true)} />
      </ToolTip>
      <Modal isOpen={isModalOpen} size="sm" onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <h6 className="bold">
            {t("DELETE")} {t("SEGMENTS.SEGMENT")}
          </h6>
          <div className={"divider"} />
          <div className={styles.content}>
            <p>{`${t("DELETE")} ${segment.name}?`}</p>
            <div className={"spacer"} />
            <div className={"spacer"} />
          </div>
          <div className={"divider"} />
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import { createBrand, TBrand } from "@/http";
import { useAuth } from "@/store/auth";
import { generateSimpleSlug } from "@/utils/slug";
import { useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export const BrandCreation = ({
  setValue,
}: {
  setValue: (key: string, value: any) => void;
}) => {
  const methods = useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [t] = useTranslation("products-page");

  const handleCreateBrand = async (values: any) => {
    try {
      setIsLoading(true);
      const { name, description } = values;
      const slug = generateSimpleSlug(name);
      const payload = {
        status: true,
        featured: false,
        supplierId: user.id,
        name,
        description,
        slug,
      } as TBrand;
      const brand = await createBrand(payload);
      setValue("brandId", brand.id);
      setValue("brandName", brand.name);
      methods.reset();
      setOpen(false);
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <div className={`row flexGap flexSmColumn ${styles.inputsContainer}`}>
        <button
          className={styles.actionButton}
          onClick={e => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          + {t("CREATE_BRAND")}
        </button>
      </div>
      {createPortal(
        <Modal isOpen={open} onClose={() => setOpen(false)} size="sm">
          <div>
            <h6 className="bold">{t("CREATE_BRAND")}</h6>
            <div className="divider" />
            <FormProvider {...methods}>
              <InputV2 label={t("PRODUCT.NAME")} name="name" required />
              <TextArea
                label={t("PRODUCT.DESCRIPTION")}
                name="description"
                rows={6}
                required
              />
            </FormProvider>

            <div className="divider" />
            <div className="rigthAlign">
              <button
                disabled={isLoading}
                onClick={e => {
                  e.preventDefault();
                  setOpen(false);
                }}
              >
                {t("CANCEL")}
              </button>
              <button
                disabled={isLoading}
                className="primary lg"
                onClick={methods.handleSubmit(handleCreateBrand)}
              >
                {t("ADD")}
              </button>
            </div>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};

import { TProduct, searchProducts, TUnitType } from "@/http";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import ProductImage from "@/components/layout/ProductImage";
import { formatCurrency } from "@/utils/currency";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./Products.module.scss";
import { BrandName } from "@/components/shared/Details";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { getProductSupplierFromProduct } from "@/utils/products";
import { createPortal } from "react-dom";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { get } from "lodash-es";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { hasPermission } from "@/utils/validations/permissions";

type TProductInput = {
  index: number;
  onDelete: (...params: any[]) => void;
  supplierId?: string;
  isQuote?: boolean;
  reservedQuantity?: number;
};
export const ProductInput = ({
  index,
  onDelete,
  supplierId,
  isQuote,
  reservedQuantity = 0,
}: TProductInput) => {
  const { setValue, watch, register } = useFormContext();
  const [options, setOptions] = useState<TProduct[]>([]);
  const productAllowInputs = watch(`products[${index}].allowInputs`) ?? false;
  const locked = watch(`products[${index}].locked`) ?? null;
  const unitRate = watch(`products[${index}].unitRate`) ?? 1;
  const unitTypes = watch(`products[${index}].unitTypes`) ?? [];
  const warehouses = watch(`products[${index}].availableWarehouses`) ?? [];
  const warehouse = watch(`products[${index}].warehouse`) ?? {};
  const warehouseId = watch(`products[${index}].warehouseId`);
  const partialUnitsAllowed = watch(`products[${index}].partialUnitsAllowed`);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { user, isCommercial, role } = useAuth();
  const debounceRefQuantity = useRef<any>();
  const fixedDivRef = useRef<HTMLDivElement>(null);

  const getWarehouseName = (id: string) => {
    return warehouses?.find((a: any) => a?.warehouseId === id)?.warehouseName;
  };

  const isProductLoaded = (id: string) => {
    const product = watch("products")?.find(
      (product: any, idx: number) => product?.id === id && index !== idx
    );
    return !!product;
  };

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(event.target.value);
    }, 250);
  };

  const getFilterProducts = async (name: string) => {
    const { content } = (await searchProducts({
      data: {
        text: name,
        supplierId: supplierId ?? user.id,
      },
      params: {
        sort: "name,asc",
        page: 0,
        size: 10,
      },
    })) as any;
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const setProduct = (product: TProduct, index: number) => {
    setValue(`products[${index}]`, {
      availableStock: product?.availableStock ?? 0,
      name: product.name,
      id: product.id,
      supplierId: product.supplierId,
      reservedQuantity: 0,
      type: product.type,
      unitType: "",
      unitRate: 1,
      wholesalePrice: product?.wholesalePrice,
      unitTypes: get(product, "unitTypes", []),
      availableWarehouses: get(product, "availableWarehouses", []),
      warehouseId: "",
      partialUnitsAllowed: get(product, "partialUnitsAllowed", false),
      allowInputs: true,
    });

    setShowOptions(false);
  };

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantityValue = event.target.value;

    if (debounceRefQuantity.current) {
      clearTimeout(debounceRefQuantity.current);
    }

    debounceRefQuantity.current = setTimeout(() => {
      setValue(`products[${index}].quantity`, quantityValue);
    }, 600);
  };

  useEffect(() => {
    if (unitRate) {
      const unit = (unitTypes ?? []).find(
        (u: TUnitType) => Number(u?.rate) === Number(unitRate)
      );
      setValue(`products[${index}].unitType`, unit?.name);
    }
  }, [unitRate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue(`products.${index}`, {
          id: crypto.randomUUID(),
          name: "",
        });

        setShowOptions(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue(`products.${index}`, { id: crypto.randomUUID(), name: "" });
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, []);

  useEffect(() => {
    if (warehouseId) {
      const warehouse =
        warehouses?.find((a: any) => a.warehouseId === warehouseId) ?? {};
      setValue(`products[${index}].warehouse`, warehouse);
    }
  }, [warehouseId]);

  return (
    <>
      <div className={styles.container}>
        <div className={`flexGap ${styles.containerInputs}`}>
          <div className={`col-12  noPadding`} ref={fixedDivRef}>
            <InputV2
              name={`products[${index}].name`}
              label={
                <p className={styles.productInputLabel}>
                  {`${t("PRODUCT")} ${index + 1}`}
                  <AiOutlineDelete
                    className="pointer"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(index);
                      }
                    }}
                  />
                </p>
              }
              type="text"
              placeholder={t("SELECT_PRODUCTS")}
              required
              onChange={onProductNameChange}
              disabled={locked}
            />
          </div>
        </div>
        {productAllowInputs && (
          <>
            <div className="col-12 noPadding flexGap flexSmColumn">
              <NumericInput
                name={`products[${index}].wholesalePrice`}
                label={
                  <span className="textLine">
                    {tGlobal("SEGMENTS.WHOLESALE_PRICE")}
                  </span>
                }
                hideControls
                decimals
                min={0}
                disabled={
                  !hasPermission(isCommercial, role, [
                    "action.purchase.product-price",
                  ])
                }
              />

              {unitTypes?.length > 0 ? (
                <SelectV2
                  name={`products[${index}].unitRate`}
                  label={t("UNIT_TYPE")}
                  required
                  disabled={locked}
                  choices={(unitTypes ?? [])
                    .sort((a: any, b: any) => {
                      if (a?.isDefault === b?.isDefault) {
                        return 0;
                      }
                      return a?.isDefault ? -1 : 1;
                    })
                    .map((u: TUnitType) => ({
                      value: u?.rate,
                      label: u?.name,
                    }))}
                />
              ) : (
                <>
                  <input
                    type="hidden"
                    value={1}
                    {...register(`products[${index}].unitRate`)}
                  />
                </>
              )}

              {!isQuote && (
                <SelectV2
                  name={`products[${index}].warehouseId`}
                  label={t("WAREHOUSE")}
                  required
                  choices={(warehouses ?? []).map((w: any) => ({
                    value: w.warehouseId,
                    label: getWarehouseName(w.warehouseId),
                  }))}
                  infoLabel={
                    warehouse?.id && !isQuote
                      ? `${tGlobal("PURCHASES.ORDERS.STOCK_AVAILABLE")}: ${
                          partialUnitsAllowed
                            ? (warehouse?.availableStock / unitRate).toFixed(2)
                            : Math.floor(warehouse?.availableStock / unitRate)
                        }`
                      : ""
                  }
                />
              )}

              <NumericInput
                name={`products[${index}].quantity`}
                label={t("PRODUCT_QUANTITY")}
                hideControls
                required
                decimals={partialUnitsAllowed}
                max={
                  isQuote
                    ? null
                    : (unitRate !== 1
                        ? Math.floor(
                            Number(warehouse?.availableStock ?? 0) / unitRate +
                              (warehouse?.overStock
                                ? Number(
                                    warehouse?.overStockAmount ?? 1000000
                                  ) / unitRate
                                : 0)
                          )
                        : Number(warehouse?.availableStock ?? 0) +
                          (warehouse?.overStock
                            ? Number(warehouse?.overStockAmount ?? 1000000)
                            : 0)) + reservedQuantity
                }
                min={0.01}
                onChange={onQuantityChange}
              />
            </div>
            {!isQuote && (
              <div className="col-12 noPadding">
                <Checkbox
                  name={`products[${index}].status`}
                  label={tGlobal("PRODUCT_DELIVERED")}
                />
              </div>
            )}
          </>
        )}

        {showOptions &&
          createPortal(
            <div
              ref={optionsRef}
              style={{
                top: fixedDivRef.current
                  ? `${
                      fixedDivRef.current.getBoundingClientRect().top +
                      80 +
                      (window.pageYOffset || document.documentElement.scrollTop)
                    }px`
                  : "0",
                width: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                  : "100%",
                left: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                  : "100%",
              }}
              className={styles.list}
            >
              <ul>
                {options.map((option: any) => {
                  const productSupplier = getProductSupplierFromProduct(
                    option,
                    supplierId ?? user.id
                  );

                  return (
                    <li
                      key={option?.id}
                      onClick={() => {
                        if (
                          isQuote ||
                          (productSupplier?.status &&
                            !isProductLoaded(option?.id) &&
                            productSupplier?.availableStock > 0)
                        ) {
                          setProduct(option, index);
                        }
                      }}
                      className={
                        isQuote
                          ? ""
                          : !productSupplier?.status ||
                            isProductLoaded(option?.id) ||
                            productSupplier?.availableStock <= 0
                          ? styles.disabledProduct
                          : ""
                      }
                    >
                      <div>
                        <ProductImage images={option.images} />
                      </div>
                      <div className={styles.description}>
                        <div>
                          {option?.name}{" "}
                          {!productSupplier?.status && !isQuote && (
                            <span className={styles.sku}>
                              * {t("PRODUCT_DISABLED")}
                              <br />
                            </span>
                          )}
                          {productSupplier?.availableStock <= 0 && !isQuote && (
                            <span className={styles.sku}>
                              * {t("PRODUCT_NOT_AVAILABLE_SALE")}
                              <br />
                            </span>
                          )}
                        </div>

                        {option?.suppliers?.[0]?.supplierSku && (
                          <span className={styles.sku}>
                            <span className="bold">{"SKU: "}</span>
                            {option?.suppliers?.[0]?.supplierSku}
                            <br />
                          </span>
                        )}
                        {option?.brand?.name ? (
                          <span className={`${styles.sku} bold`}>{`${t(
                            "BRAND"
                          )}: ${option.brand.name} | `}</span>
                        ) : (
                          option?.brandId && (
                            <span className={styles.sku}>
                              <span className="bold">{`${t("BRAND")}: `}</span>
                              <BrandName id={option.brandId} />
                              {" | "}
                            </span>
                          )
                        )}
                      </div>
                      <div className="rigthAlign">
                        <div className="bold">
                          {formatCurrency(
                            option?.suppliers?.[0]?.wholesalePrice ?? 0
                          )}
                        </div>
                        {!isQuote && (
                          <div className={`${styles.mediumSize} textLine`}>
                            <span className="bold">{`${t(
                              "AVAILABLE"
                            )}: `}</span>
                            <span className="greenText">
                              {productSupplier?.availableStock ?? 0}
                            </span>
                            <br />
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
                {!options?.length && <li>{t("NO_OPTIONS")}</li>}
              </ul>
            </div>,
            document.body
          )}
      </div>
    </>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError } from "@/hooks/useToast";
import { deleteOffer } from "@/http";
import { TGlobalOffer } from "@/http/types/global-offers";
import { useAuth } from "@/store/auth";
import { useOffers } from "@/store/offers";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";

type TDeleteOffer = {
  offer: TGlobalOffer;
};
export const DeleteOffer = ({ offer }: TDeleteOffer) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { getOffers } = useOffers();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const onDeleteOffer = async () => {
    try {
      setLoading(true);
      await deleteOffer(offer.id!);
      getOffers(user.id);
      setOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToolTip title={t("OFFERS.DELETE_OFFER")} position="Left">
        <AiOutlineDelete
          cursor="pointer"
          size={17}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
      </ToolTip>
      {createPortal(
        <Modal
          isOpen={isOpen}
          size="sm"
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="spacer" />
          <h1 className="bolder">{`${t("OFFERS.WANT_DELETE")} "${
            offer.name
          }"?`}</h1>
          <div className="spacer" />
          <div className="spacer" />
          <div className="rigthAlign">
            <button
              className="primary lg"
              onClick={onDeleteOffer}
              disabled={isLoading}
            >
              {t("OFFERS.DELETE_OFFER")}
            </button>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};

import { Card } from "@/components/shared/Card/Card";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BrandInput } from "../ProductsForm/BrandInput";
import { CategoryTree } from "../ProductsForm/CategoryTree";
import { BrandCreation } from "./BrandCreation";

export const NameInputs = () => {
  const [t] = useTranslation("products-page");
  const { setValue } = useFormContext();

  return (
    <Card borderTop>
      <p className="bold">{t("BASIC_INFO")}</p>
      <p>{t("BASIC_INFO_DESCRIPTION")}</p>
      <div className="spacer" />
      <div className="col-12 noPadding">
        <div className="col-6 col-sm-12 noPadding">
          <InputV2
            name="name"
            label={<span className="bold">{t("PRODUTC_NAME_LABEL")}</span>}
            required
            min={10}
          />
        </div>
      </div>
      <div className="col-12 noPadding">
        <div className="col-6 col-sm-12 noPadding">
          <BrandInput
            label={
              <div className="contentInline">
                <span className="bold">{t("PRODUCT_BRAND")}</span>
                <BrandCreation setValue={setValue} />
              </div>
            }
            placeholder={""}
          />
        </div>
      </div>

      <div className="col-12 noPadding">
        <div className="col-6 col-sm-12 noPadding">
          <CategoryTree />
        </div>
      </div>

      <div className="col-12 noPadding">
        <div className="col-6 col-sm-12 noPadding">
          <TextArea
            name="description"
            label={
              <span className="bold">{t("PRODUTC_DESCRIPTION_LABEL")}</span>
            }
            rows={6}
          />
        </div>
      </div>
      <div className="col-12 noPadding">
        <div className="col-12 noPadding">
          <Checkbox name="isMarketplace" label={t("PRODUCT.IS_MARKETPLACE")} />
        </div>
        <div className="col-12 noPadding">
          <Checkbox name="featured" label={t("PRODUCT.FEATURED")} />
        </div>
      </div>
    </Card>
  );
};

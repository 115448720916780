import { isUSA } from "@/constants/core";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_LOGISTICS } from "@/constants/features";
import { useAuth } from "@/store/auth";

import { useSubscription } from "@/hooks/useSubscription";
import { useTranslation } from "react-i18next";
import { TDeliveryProvider } from "@/http";

export const LogisticOptionsForm = ({
  deliveryProviders,
}: {
  deliveryProviders: TDeliveryProvider[];
}) => {
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const showSeeriLogistic = user.canUseLogisticIntegration;
  const { hasFeature } = useSubscription();

  return (
    <>
      <RadioGroupV2
        label={""}
        name="dispatchValue"
        options={[
          ...(showSeeriLogistic && !isUSA
            ? [
                {
                  label: (
                    <>
                      {t("SEERI_LOGISTIC")}
                      <PremiumFeatureIndicator feature={FEAT_LOGISTICS} />
                    </>
                  ),
                  value: "SEERI",
                  disabled: !hasFeature(FEAT_LOGISTICS),
                },
              ]
            : []),
          ...(deliveryProviders ?? []).map(dp => ({
            label: dp.name,
            value: dp.code,
          })),
        ]}
        required
        inline
        color="purple"
      />
    </>
  );
};

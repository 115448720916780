import { QUOTED, TPurchase } from "@/http";
import { groupByPending } from "@/utils/products";
import { useTranslation } from "react-i18next";
import { Pricing } from "../PurchaseCreateForm/Prepurchase/Pricing";
import { PurchaseStatusInfo } from "./PurchaseGeneralInfo/PurchaseStatusInfo";
import styles from "./PurchaseShow.module.scss";
import { SubPurchases } from "./SubPurchases/SubPurchases";
import ProductsDetails from "@/components/order-details/ProductsDetails/ProductsDetails";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { PurchaseActions } from "./Actions/PurchaseActions";
import { LastPurchasesUser } from "@/components/order-details/LastPurchasesUser/LastPurchasesUser";
import { validateTimezoneDate } from "@/utils/date";
import {
  buildCountryLine,
  buildLineAddress,
} from "@/components/invoices/utils";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { ShowTimeline } from "./Timeline/ShowTimeline";
import { PurchaseComments } from "./Comments/PurchaseComments";
import { useAuth } from "@/store/auth";
import { PurchaseObservations } from "./Comments/PurchaseObservations";
import { PaymentsList } from "./Payments/PaymentsList";
import { Card } from "@/components/shared/Card/Card";
import { usePurchase } from "@/store/purchase";

export type TPurchaseShow = {
  purchase: TPurchase;
};
export const PurchaseShow = ({ purchase }: TPurchaseShow) => {
  const { getPurchase } = usePurchase();
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const pendingProducts = groupByPending(purchase?.products)["PENDING"];

  const isSale =
    !(purchase?.sellerId === user.id) && purchase.status !== QUOTED;

  return (
    <>
      <Card className="noPadding">
        <div className={`${styles.titleWrapper} row`}>
          <div className="col-5 col-sm-12 noPadding">
            <h6 className="bold">{t("GENERAL_INFO_TITLE")}</h6>
          </div>
          <div
            className={`${styles.infoTitle} flexSmColumn col-7 col-sm-12 noPadding`}
          >
            <div>
              <div className="flexGap gapSm center">
                <ShowTimeline purchaseId={purchase.id} />
                <span className="bold textLine">{t("CREATED_AT")}:</span>
                <span className="textLine">
                  {purchase?.createdAt
                    ? format(
                        new Date(validateTimezoneDate(purchase?.createdAt)),
                        isUSA ? USA_DATE_HOUR : DATE_HOUR
                      )
                    : t("NO_AVAILABLE")}
                </span>
              </div>

              {purchase.status === QUOTED && (
                <div className="flexGap">
                  <span className="bold textLine">{t("EXPIRES_AT")}:</span>
                  {purchase?.expiredAt
                    ? format(
                        new Date(validateTimezoneDate(purchase?.expiredAt)),
                        isUSA ? USA_DATE_HOUR : DATE_HOUR
                      )
                    : t("NO_AVAILABLE")}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row pw1">
          <PurchaseActions purchase={purchase} />
        </div>

        <div className="spacer" />

        <div className="row">
          <div
            className={`${styles.generalContainer} col-4 col-sm-12 noPadding`}
          >
            <span className={styles.title}>{t("CLIENT")}</span>
            <div className="spacer" />

            <p className="bold">{t(isUSA ? "LEGAL_NAME" : "CLIENT_NAME")}</p>
            <div className="pointer">
              <SellerResume seller={purchase?.client} hideEdit />
              {purchase.client.phonePrefix + " " + purchase.client.phone}
            </div>
            <div className="spacer" />
            <p className="bold">{t("ADDRESS_DELIVERY")}</p>
            <p>{buildLineAddress(purchase?.client?.location)}</p>
            <p>{buildCountryLine(purchase?.client?.location)}</p>
            <div className="spacer" />
          </div>

          <div
            className={`${styles.generalContainer} col-4 col-sm-6 noPadding`}
          >
            <span className={styles.title}>{t("STATES")}</span>
            <div className="spacer" />
            <PurchaseStatusInfo purchase={purchase} />
            {purchase.commercialName && (
              <>
                <span className="bold">{t("SELLER")}: </span>
                <span>{purchase.commercialName}</span>
              </>
            )}
            <div className="spacer" />
          </div>
          <div
            className={`${styles.generalContainer} col-4 col-sm-12 noPadding`}
          >
            <span className={styles.title}>{t("PAYMENTS")}</span>
            {purchase && <PaymentsList purchase={purchase} />}
          </div>
        </div>

        <div className="row col-12 flexGap flexSmColumn">
          <div className="col-4 col-sm-12 noPadding">
            <LastPurchasesUser clientId={purchase?.client?.id} font="sm" />
          </div>
          {isSale && (
            <>
              <div className="col-4 col-sm-12 noPadding">
                <PurchaseComments />
              </div>
              <div className="col-4 col-sm-12 noPadding">
                <PurchaseObservations />
              </div>
            </>
          )}
        </div>

        <div className="row col-12">
          <div className={styles.section}>
            {pendingProducts.length > 0 && (
              <>
                <ProductsDetails
                  orderDetail={
                    { ...purchase, products: pendingProducts } as any
                  }
                  lightStyle
                  showSerials
                  onDoneSerials={() => getPurchase(purchase.id)}
                />
                {purchase?.subPurchases?.length > 0 && (
                  <>
                    <div className="spacer" />
                    <div className="spacer" />
                  </>
                )}
              </>
            )}
            <SubPurchases subpurchases={purchase.subPurchases} />
          </div>
        </div>
        <div className="row col-12 noPadding">
          <div className={styles.pricingContent}>
            <div className="col-7 col-sm-12 noPadding"></div>
            <div className="col-5 col-sm-12 noPadding">
              <div className={styles.generalContainer}>
                <span className={styles.title}>{t("PURCHASE_INFO_TITLE")}</span>
                <div className="spacer" />
                <Pricing prepurchase={purchase as any} ligthStyle />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

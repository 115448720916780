import { TSupplierCommercial, updateSupplierCommercial } from "@/http";
import { useAuth } from "@/store/auth";
import { useCommercials } from "@/store/commercials";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CommercialStatus = ({
  commercial,
}: {
  commercial: TSupplierCommercial;
}) => {
  const [isLoading, setLoading] = useState(false);
  const { getCommercials } = useCommercials();
  const [t] = useTranslation("global");
  const { user } = useAuth();

  const handleChange = async () => {
    try {
      setLoading(true);
      await updateSupplierCommercial(commercial.id, {
        status: !commercial.status,
      });
      getCommercials(user.id);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flexGap centerVAlign">
        <label className="switchBtn">
          <input
            type="checkbox"
            onChange={handleChange}
            checked={commercial?.status}
            className={isLoading ? "loading" : ""}
          />
          <div className={"slide"}></div>
        </label>
        <span>{t(commercial?.status ? "ENABLED" : "DISABLED")}</span>
      </div>
    </>
  );
};

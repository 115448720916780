import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { ChangeEvent, useRef } from "react";
import { ProductsGroupActions } from "./ProductsGroupActions";
import { useSupplierBrands } from "@/hooks/useSupplier";
import { useAuth } from "@/store/auth";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { BrandName } from "@/components/shared/Details";
import { FiChevronDown } from "react-icons/fi";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

export const ProductsGroupFilters = ({
  mutate,
}: {
  mutate: (...args: any) => void;
}) => {
  const [search, setSearch] = useSearchParams("page=0");
  const tabCurrentFilter = search.get("tab");
  const { user } = useAuth();
  const [t] = useTranslation("products-page");

  const setFilter = (value: any) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tab", value);
    newSearch.set("page", "0");
    setSearch(newSearch);
  };

  return (
    <>
      <div className={`flexGap ${styles.tabContainer}`}>
        <div className={clsx("noPadding col-sm-12")}>
          <div>
            <p className="bold">{t("WAREHOUSE")}</p>
            <select
              value={tabCurrentFilter ?? ""}
              onChange={e => setFilter(e.target.value)}
            >
              {[
                { text: "FILTERS.SEE_ALL", value: "" },
                ...(user?.addresses || []).map(a => ({
                  text: a.name,
                  value: a.id ?? "",
                })),
              ].map((option, index) => (
                <option
                  key={`${option.value}_${index}_select`}
                  value={option.value}
                >
                  {t(option.text)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={clsx("noPadding centerVAlign rigthAlignFlex col-sm-12")}
        >
          <ProductsGroupActions mutate={mutate} />
        </div>
      </div>
    </>
  );
};

export const SearchProductInput = () => {
  const debounceRef = useRef<any>();
  const [search, setSearch] = useSearchParams("page=0");
  const nameFilter = search.get("name");
  const [t] = useTranslation("products-page");

  const onQueryChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === nameFilter) {
      return;
    }
    const newSearch = new URLSearchParams(search);
    newSearch.set("name", event.target.value);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      newSearch.set("page", "0");
      setSearch(newSearch);
    }, 350);
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          type="text"
          placeholder={t("SEARCH_PRODUCT")}
          onChange={onQueryChanged}
          defaultValue={nameFilter || ""}
        />
      </div>
    </>
  );
};

export const BrandFilterInput = () => {
  const { user } = useAuth();
  const [t] = useTranslation("products-page");
  const [search, setSearch] = useSearchParams("page=0");
  const brandFilter = search.get("brand");

  const supplierBrands = useSupplierBrands(user.id);

  const setFilter = (value: any) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("brand", value);
    newSearch.set("page", "0");
    setSearch(newSearch);
  };

  return (
    <>
      <div className={styles.sort}>
        {supplierBrands?.length > 0 && (
          <>
            <SingleMenu
              onSelect={setFilter}
              hideOnSelect
              align="rigth"
              label={
                <div className="menuTooltip flexGap center textLine">
                  {brandFilter ? (
                    <BrandName id={brandFilter} />
                  ) : (
                    t("PRODUCT.DETAIL.BRAND")
                  )}
                  <FiChevronDown color="brand.primary.500" />
                </div>
              }
              options={[
                ...supplierBrands.map(brand => ({
                  value: brand.id,
                  label: brand.name,
                })),
                { value: "", label: t("ALL_BRANDS_DESC") },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};

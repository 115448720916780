import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { QUOTED } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export const QuotesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const [search, setSearch] = useSearchParams();
  const isProcurementFilter = search.get("isProcurement") === "true";
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const isQuotes = statusFilters["status"] === QUOTED;
  const navigate = useNavigate();

  const debouncedEffectFunction = debounce(() => {
    const payload = {
      ...statusFilters,
      status: QUOTED,
    };

    const newSearch = new URLSearchParams(search);
    newSearch.set("status", JSON.stringify(payload));
    newSearch.set("isProcurement", "false");
    setSearch(newSearch);
  }, 500);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  useEffect(() => {
    setTitle(t("QUOTES.LIST_TITLE"));
  }, [t]);

  return (
    <>
      <div className="rigthAlign">
        <button className="primary" onClick={() => navigate("/quotes/create")}>
          {t("QUOTES.CREATE_QUOTE")}
        </button>
        <div className="spacer" />
      </div>

      {isQuotes && !isProcurementFilter && <PurchaseList quotes />}
    </>
  );
};

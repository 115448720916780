export const selectMultipleStylesSlim = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
    border: "1px solid #0b263f",
    backgroundColor: state.isDisabled ? "rgb(247, 249, 252)" : "transparent",
    minHeight: "1.8rem", // Altura mínima inicial
    display: "flex",
    alignItems: "flex-start", // Alineación flexible para permitir ajustes
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: "0 8px", // Espaciado interno
    display: "flex",
    flexWrap: "wrap", // Permite que las opciones seleccionadas ajusten la altura
    alignItems: "center",
    gap: "4px", // Espaciado entre las opciones seleccionadas y el input
  }),
  placeholder: (styles: any) => ({
    ...styles,
    margin: 0,
    lineHeight: "1.8rem", // Alineación vertical
    position: "absolute", // Soluciona desplazamientos indeseados
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    height: "1.6rem", // Altura específica para las etiquetas seleccionadas
    margin: "2px", // Espaciado alrededor de las etiquetas
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    lineHeight: "1.6rem", // Alineación del texto
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "1.6rem", // Tamaño consistente con las etiquetas
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
    height: "auto", // Permite el ajuste dinámico
    lineHeight: "1.6rem", // Alineación del texto en el input
    flex: "1 1 auto", // Asegura que el input ocupe el espacio necesario
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: 0, // Elimina el padding del contenedor de indicadores
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0, // Elimina el padding del icono desplegable
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: 0, // Elimina el padding del botón de borrado
  }),
  menu: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 5,
  }),
};

export const selectMultipleStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
    border: "1px solid #0b263f",
    backgroundColor: state.isDisabled ? "rgb(247, 249, 252)" : "transparent",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    height: "20px",
    alignItems: "center",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  input: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  menu: (provided: any) => ({
    ...provided,
    lineHeight: "20px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 5 }),
};

export const singleSelectStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "0.5rem",
    height: "1.2rem",
    marginTop: "0.5rem",
    border: "1px solid #0b263f",
    backgroundColor: state.isDisabled ? "rgb(247, 249, 252)" : "transparent",
  }),
};

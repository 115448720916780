import ProductImage from "@/components/layout/ProductImage";
import {
  useCompletePurchase,
  usePurchaseProducts,
} from "@/hooks/usePurchaseProducts";
import { TOrderDetailsPanel } from "@/models/order-details";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TProductSerial,
  TPurchaseProduct,
  updatePurchaseProducts,
} from "@/http";
import { ProductResume } from "./ProductResume";
import clsx from "clsx";
import { get } from "lodash-es";
import { MultipleSelectionSerial } from "@/components/shared/Input/MultipleSelectionSerial";
import { FormProvider, useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { SerialModal } from "@/components/products/ProductDetail/Serials/SerialsModal";

const ProductsDetails = ({
  orderDetail,
  lightStyle = false,
  isProductsTicket,
  isSubpurchase,
  showSerials = false,
  onDoneSerials,
}: TOrderDetailsPanel) => {
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();
  const allowSerials = (showSerials || isSubpurchase) && user.allowSerials;
  const orderProducts = usePurchaseProducts(
    orderDetail?.products ?? [],
    orderDetail?.purchaseId ?? ""
  );

  if (!orderProducts.length) {
    return null;
  }

  const sum = orderProducts.reduce(
    (previousValue: number, currentValue: TPurchaseProduct) =>
      previousValue + (currentValue?.subTotalWithDiscount ?? 0),
    0
  );
  const totalReturnedAmount = isSubpurchase
    ? orderProducts.reduce((amount, p) => amount + (p.returnedAmount ?? 0), 0)
    : 0;

  const price = useCallback(
    (product: any) => {
      return (
        product.suppliers?.find(
          (supplier: any) => supplier?.supplierId === user.id
        )?.wholesalePrice ?? 0
      );
    },
    [user.id]
  );

  const getDiscount = useCallback(
    (product: any) => {
      return (
        (product?.wholesalePrice || 0) * (product?.quantity || 0) -
        (product?.couponType === "DISCOUNT_PERCENTAGE"
          ? (product.subTotalWithDiscount || 0) -
            (product.discountByCoupon || 0)
          : product.subTotalWithDiscount || 0)
      );
    },
    [user.id]
  );

  const getFinalPrice = useCallback(
    (product: any) => {
      return product?.couponType === "DISCOUNT_PERCENTAGE"
        ? (product?.subTotalWithDiscount || 0) -
            (product?.discountByCoupon || 0)
        : product?.subTotalWithDiscount || 0;
    },
    [user.id]
  );

  const getDiscountPercentage = useCallback(
    (product: any) => {
      const discountTotal = getDiscount(product);
      const discountPercentage =
        discountTotal /
        ((product?.wholesalePrice || 0) * (product?.quantity || 0));

      const finalDiscount = (discountPercentage || 0) * 100;

      return Math.floor(finalDiscount * 100) / 100;
    },
    [user.id]
  );

  const printUnitTypes = orderProducts.filter(p => !!p.unitType).length > 0;
  const printImages = orderProducts.flatMap(p => p.images).length > 0;
  const printDiscounts =
    orderProducts.filter(p => p.subtotal !== p.subTotalWithDiscount).length > 0;
  const orderTotal = orderProducts
    .map(({ subTotalWithDiscount }) => subTotalWithDiscount)
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const totalUnits = orderProducts
    .map(({ quantity }) => quantity)
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const totalProducts = orderProducts.length;

  return (
    <>
      <div className={styles.productsDetails}>
        {!isProductsTicket && (
          <p className={styles.productsTitle}>{t("PRODUCTS_DETAILS.TITLE")}</p>
        )}

        <div
          className={clsx(styles.productsTable, {
            [styles.lightStyle]: lightStyle,
          })}
        >
          <table id="products-details-table">
            <thead>
              <tr className={styles.productsTableHead}>
                {printImages && <th>{t("PRODUCTS_DETAILS.IMAGE")}</th>}
                <th>{t("PRODUCTS_DETAILS.PRODUCT")}</th>
                <th>{t("FILTER.DELIVERYSTATUS")}</th>
                <th>{t("PRODUCTS_DETAILS.QUANTITY")}</th>
                {printUnitTypes && <th>{t("PRODUCTS_DETAILS.UNIT_TYPE")}</th>}
                {allowSerials && (
                  <th className={styles.leftAlign}>{tGlobal("SERIALS")}</th>
                )}
                {user?.showPricingInfoOnInvoice && !isProductsTicket && (
                  <>
                    <th>{t("UNIT_PRICE")}</th>
                    {printDiscounts && <th>{t("DISCOUNT")} (%)</th>}
                    {printDiscounts && <th>{t("FINAL_DISCOUNT")}</th>}
                    <th>{t("PRODUCT_FINAL_PRICE")}</th>
                  </>
                )}
                {isProductsTicket && (
                  <>
                    <th>{t("UNIT_PRICE")}</th>
                    <th>{t("TOTAL")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {orderProducts?.map((product: any, index: number) => (
                <tr
                  key={`${product.sku}_${index}`}
                  className={styles.productsTableRow}
                >
                  {printImages && (
                    <td>
                      <Link to={`/products/${product.id}`}>
                        <ProductImage images={product.images} />
                      </Link>
                    </td>
                  )}
                  <td className="bold">
                    <ProductResume product={product} />
                  </td>
                  <td className="bold">
                    {t(`FILTER.${product.deliveryStatus}`)}
                  </td>
                  <td>{product.quantity}</td>
                  {printUnitTypes && <td>{product?.unitType ?? "-"}</td>}

                  {allowSerials && (
                    <td>
                      <ProductSerial
                        product={product}
                        purchaseId={
                          orderDetail?.purchaseId || orderDetail?.id || ""
                        }
                        onDone={onDoneSerials}
                      />
                    </td>
                  )}
                  {user?.showPricingInfoOnInvoice && !isProductsTicket && (
                    <>
                      <td>{formatCurrency(product?.wholesalePrice || 0)}</td>
                      {printDiscounts && (
                        <td>{getDiscountPercentage(product)} %</td>
                      )}
                      {printDiscounts && (
                        <td>{formatCurrency(getDiscount(product))}</td>
                      )}
                      <td>{formatCurrency(getFinalPrice(product))}</td>
                    </>
                  )}
                  {isProductsTicket && (
                    <>
                      <td>{formatCurrency(price(product))}</td>
                      <td>
                        {formatCurrency(price(product) * product?.quantity)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
            {totalProducts > 1 && (
              <tfoot>
                <tr key={`footer`} className={styles.productsTableRow}>
                  {printImages && <td></td>}
                  <td>{totalProducts}</td>
                  <td></td>
                  <td>{totalUnits}</td>
                  {printUnitTypes && <td></td>}

                  {allowSerials && <td></td>}
                  {user?.showPricingInfoOnInvoice && !isProductsTicket && (
                    <>
                      <td></td>
                      {printDiscounts && <td></td>}
                      {printDiscounts && <td></td>}
                      <td>{!isSubpurchase && formatCurrency(orderTotal)}</td>
                    </>
                  )}
                  {isProductsTicket && (
                    <>
                      <td></td>
                      <td></td>
                    </>
                  )}
                </tr>
              </tfoot>
            )}
          </table>
          {isSubpurchase && (
            <div className="mr1">
              <div className="col-12 noPadding rigthAlign">
                <p>
                  {orderDetail?.total && (
                    <>
                      <b>{t("TOTAL_SUBPURCHASE") + ": "}</b>
                      {formatCurrency(orderDetail?.total)}
                    </>
                  )}
                </p>
                {Boolean(orderDetail?.shipping) && (
                  <>
                    <p>
                      <b>{t("SHIPPING") + ": "}</b>
                      {formatCurrency(orderDetail?.shipping ?? 0)}
                    </p>
                    <p>
                      <b>{t("TOTAL_SUBPURCHASE_SHIPPING") + ": "}</b>
                      {formatCurrency(sum + (orderDetail?.shipping ?? 0))}
                    </p>
                  </>
                )}
                {Boolean(orderDetail?.partialMethodAmount) && (
                  <p>
                    <b>{t("PARTIAL_PAYMENT") + ": "}</b>
                    {formatCurrency(orderDetail?.partialMethodAmount ?? 0)}
                  </p>
                )}
                {Boolean(totalReturnedAmount) && (
                  <p>
                    <b>{t("RETURNED_AMOUNT") + ": "}</b>
                    {formatCurrency(totalReturnedAmount)}
                  </p>
                )}
                {Boolean(orderDetail?.partialMethodAmount) && (
                  <p>
                    <b>{t("TOTAL_PAY") + ": "}</b>
                    {formatCurrency(
                      sum -
                        (orderDetail?.partialMethodAmount ?? 0) +
                        (orderDetail?.shipping ?? 0)
                    )}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ProductSerial = ({
  product,
  purchaseId,
  onDone,
}: {
  onDone?: (...args: any) => void;
  product: any;
  purchaseId: string;
}) => {
  const fullPurchase = useCompletePurchase(purchaseId);
  const serials = get(product, "serials", []).map((s: any) => s.id);
  const [isEnabled, setEnabled] = useState(false);
  const [t] = useTranslation("global");

  const methods = useForm({ defaultValues: { serials } });

  const setSerial = (serial: TProductSerial) => {
    const currentSerial = methods.watch("serials");

    if (currentSerial?.length) {
      methods.setValue("serials", [...currentSerial, serial.id]);
    } else {
      methods.setValue("serials", [serial.id]);
    }
  };

  const onSubmit = async (values: any) => {
    const newSerials = values?.serials || [];

    await updatePurchaseProducts(purchaseId, {
      products: fullPurchase.products.map(p => {
        if (p.id === product.id) {
          return { ...p, serials: newSerials };
        } else {
          return p;
        }
      }),
      recalculate: false,
    });

    onDone?.();
    setEnabled(false);
  };

  useEffect(() => {
    methods.setValue(
      "serials",
      get(product, "serials", []).map((s: any) => s.id)
    );
  }, [fullPurchase]);

  return (
    <div className={styles.serialCell}>
      <FormProvider {...methods}>
        <div className="flexGap center">
          <div className="col-12 noPadding">
            {isEnabled ? (
              <MultipleSelectionSerial
                name={"serials"}
                productId={product.id}
                purchaseId={purchaseId}
                disabled={!isEnabled}
                maxOptions={product?.quantity || 0}
                infoLabel={
                  isEnabled ? (
                    <>
                      <div className="rigthAlign">
                        <SerialModal
                          productId={product.id}
                          onDone={setSerial}
                          slim
                        />
                      </div>
                    </>
                  ) : null
                }
              />
            ) : (
              <div className={clsx("flexColumn", styles.serialsInfo)}>
                {Array.from({ length: product?.quantity || 0 }, (_, index) => (
                  <div key={index} className="flexGap gapSm mdFont">
                    <span>#{index + 1}:</span>
                    <span>
                      {get(product, `serials[${index}].serialNumber`) || (
                        <span className="redText">{t("NO_SERIAL")}</span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!isEnabled && (
            <span
              className="circleButton pointer"
              onClick={() => setEnabled(true)}
            >
              <MdOutlineEdit />
            </span>
          )}
        </div>
        {isEnabled && (
          <div className="rigthAlign">
            <button
              onClick={() => {
                methods.reset({ serials });
                setEnabled(false);
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </div>
        )}
      </FormProvider>
    </div>
  );
};

export default ProductsDetails;

import { ReactNode, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  when?: boolean;
  className?: string;
  children: ReactNode;
};

export const DisabledZone = ({ children, when = true, className }: Props) => {
  const div = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (div.current) {
      div.current.inert = when;
    }
  }, [when]);

  return (
    <div ref={div} className={clsx({ [styles.disabledZone]: when }, className)}>
      {children}
    </div>
  );
};

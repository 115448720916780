import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { createSegment, TNewSegment, TSegment, updateSegment } from "@/http";
import { useState } from "react";
import { createPortal } from "react-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { FormProvider, useForm } from "react-hook-form";
import { InputV2 } from "../shared/Input/InputV2";

type Props = {
  segment?: TSegment;
  onDone?: (segment: TSegment) => void;
};
export const SegmentModalForm = ({ segment, onDone }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      {segment ? (
        <ToolTip title={t("EDIT")} position="Left">
          <AiOutlineEdit cursor="pointer" onClick={() => setIsOpen(true)} />
        </ToolTip>
      ) : (
        <div className="rigthAlign">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className={"primary"}
          >
            + {t("CREATE")}
          </button>
        </div>
      )}

      {createPortal(
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <h6 className="bold">
            {segment ? `${t("EDIT")} ${segment.name}` : t("CREATE")}
          </h6>
          <div className="divider" />
          <div className="spacer" />
          <SegmentForm
            segment={segment}
            onDone={segment => {
              setIsOpen(false);
              onDone?.(segment);
            }}
          />
        </Modal>,
        document.body
      )}
    </>
  );
};

export const SegmentForm = ({
  segment,
  onDone,
}: {
  segment?: TSegment;
  onDone?: (segment: TSegment) => void;
}) => {
  const [t] = useTranslation("global");
  const defaultValues = segment || { name: "" };

  const methods = useForm<TNewSegment>({ defaultValues });
  const { isSubmitting } = methods.formState;

  const handleSubmit = async (values: typeof defaultValues) => {
    try {
      let newSegment;

      if (segment) {
        newSegment = await updateSegment(segment.id, values);
      } else {
        newSegment = await createSegment(values);
      }
      showToastSuccess(t("SAVED"));
      methods.reset();
      onDone?.(newSegment);
    } catch (error) {
      showToastError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <InputV2 name="name" label={t("NAME")} required />

        <div className="divider" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button className="primary lg" disabled={isSubmitting}>
            {t(segment ? "SAVE" : "ADD")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

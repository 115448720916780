import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { useCategoriesChoices } from "@/hooks/useCategory";
import { TCategory } from "@/http";
import { get } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CategoryCreation } from "../CreationV2/CategoryCreation";

export const CategoryTree = ({
  disabled = false,
  required = false,
  hideCreation = false,
}: {
  disabled?: boolean;
  required?: boolean;
  hideCreation?: boolean;
}) => {
  const { setValue } = useFormContext();
  const result = useCategoriesChoices({ maxDeepLevel: Infinity }, true);
  const categories = get(result, "data", []);
  const mutate = get(result, "mutate", () => {
    return;
  });
  const [t] = useTranslation("products-page");
  const choices = categories.map((category: TCategory) => ({
    id: category.id,
    name: category.name,
  }));

  if (!choices?.length) {
    return null;
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        required={required}
        choices={choices}
        inputName="categoryName"
        inputLabel={
          <p className="contentInline">
            <span className="bold">{t("PRODUCT.CATEGORY")}</span>
            {!hideCreation && (
              <CategoryCreation
                onDone={(category: TCategory) => {
                  mutate();
                  setValue("categoryId", category.id);
                  setValue("categoryName", category.name);
                }}
              />
            )}
          </p>
        }
        inputPlaceholder=""
        action={option => {
          setValue("categoryName", option?.name);
          setValue("categoryId", option?.id);
        }}
      />
    </>
  );
};

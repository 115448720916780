import { useState } from "react";
import { Modal } from "@/components/shared/Modal/Modal";
import styles from "./styles.module.scss";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteBanner, TBanner } from "@/http";
import { useTranslation } from "react-i18next";

type TDeleteBanner = {
  banner: TBanner;
  onDone?: () => void;
};
export const DeleteBanner = ({ banner, onDone }: TDeleteBanner) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await deleteBanner(banner.id);
      onDone?.();
      showToastSuccess(t("BANNER_DELETED"));
      setModalOpen(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button className="outlined" onClick={() => setModalOpen(true)}>
        {t("DELETE")}
      </button>
      <Modal
        title={t("DELETE_BANNER")}
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        footer={
          <button
            className="primary md"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <div className={styles.content}>
          <p>{`${t("SURE_DELETE_BANNER")} "${banner.name}"?`}</p>
        </div>
      </Modal>
    </>
  );
};

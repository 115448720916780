import { create } from "zustand";
import {
  getSupplierCommercials,
  getSupplierPermissions,
  TSupplierCommercial,
} from "@/http";

type TUseCommercials = {
  getCommercials: (...args: any) => void;
  commercials: TSupplierCommercial[];
  isLoading: boolean;
  getPermissions: (...args: any) => void;
  permissions: any[];
  isLoadingPermissions: boolean;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (currentPage: number) => void;
  setPageSize: (pageSize: number) => void;
};
export const useCommercials = create<TUseCommercials>((set, get) => ({
  getCommercials: async (userId: string) => {
    set(() => ({ isLoading: true }));
    try {
      const { currentPage, pageSize } = get();
      if (userId) {
        const commercials = await getSupplierCommercials({
          page: currentPage,
          size: pageSize,
          sort: "createdAt,desc",
        });

        set(() => ({
          commercials: commercials.content,
          isLoading: false,
          totalPages: commercials.totalPages,
        }));
      }
    } catch (error) {
      set(() => ({ commercials: [], isLoading: false }));
    }
  },
  getPermissions: async () => {
    set(() => ({ isLoadingPermissions: true }));
    try {
      const permissions = await getSupplierPermissions({
        page: 0,
        size: 1000,
      });

      set(() => ({
        permissions: permissions.content,
        isLoadingPermissions: false,
      }));
    } catch (error) {
      set(() => ({ permissions: [], isLoadingPermissions: false }));
    }
  },
  commercials: [],
  permissions: [],
  isLoading: false,
  isLoadingPermissions: false,
  totalPages: 0,
  currentPage: 0,
  pageSize: 10,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
  setPageSize: pageSize => set(() => ({ pageSize })),
}));

import {
  deliveryStatusRendering,
  paymentStatus,
  purchaseStatus,
} from "@/constants/purchases";
import { QUOTED } from "@/http";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { TPurchaseShow } from "../PurchaseShow";
import { PurchaseUpdatePaymentStatus } from "./PurchaseUpdatePaymentStatus";
import styles from "./styles.module.scss";
import { hasPermission } from "@/utils/validations/permissions";

export const PurchaseStatusInfo = ({ purchase }: TPurchaseShow) => {
  const { isCommercial, role } = useAuth();
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const isStockIn = purchase?.sellerId === user.id;
  const canUpdatePaymentStatus =
    hasPermission(isCommercial, role, ["action.purchase.paid"]) &&
    ["SUPPLIER_PAY"].includes(purchase.paymentMethod) &&
    ["PENDING", "REVIEW"].includes(purchase.paymentStatus) &&
    "SUPPLIER_CREDIT" != purchase.paymentProvider &&
    !isStockIn;

  return (
    <div>
      <div className={styles.field}>
        <span className={styles.title}>{t("STATUS")}:</span>
        <span>
          {purchaseStatus.find(s => s.code === purchase.status)?.name}
        </span>
      </div>

      {purchase.status != QUOTED && (
        <>
          <div className={styles.field}>
            <span className={styles.title}>{t("SHIP")}:</span>
            <span>
              {
                deliveryStatusRendering.find(
                  s => s.code === purchase.deliveryStatus
                )?.name
              }
            </span>
          </div>

          <div className={`${styles.field} centerVAlign`}>
            <span className={styles.title}>{t("PAYMENT")}:</span>
            <span>
              {paymentStatus.find(s => s.code === purchase.paymentStatus)?.name}
            </span>
          </div>
        </>
      )}

      {!!purchase.shippingMethod && (
        <div className={styles.field}>
          <span className={styles.title}>{t("SHIPPING_METHOD")}:</span>
          <span className="textLine">{t(purchase?.shippingMethod)}</span>
        </div>
      )}
    </div>
  );
};

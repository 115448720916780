import { Card } from "@/components/shared/Card/Card";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ImagesFormList } from "../ProductsForm/ImagesFormList";

export const ImagesInputs = () => {
  const [t] = useTranslation("products-page");
  const methods = useFormContext();

  return (
    <Card>
      <div className="flexGap flexSmColumn">
        <div className="col-5 col-sm-12  noPadding">
          <p className="bold">{t("IMAGES_GALLERY")}</p>
          <p>{t("IMAGES_GALEERY_DESCRIPTION")}</p>
          <div className="divider" />
          <p className="bold mdFont">{t("IMAGES_REQUIREMENTS_LIST")}</p>
          <ul className="ml2 mdFont">
            <li>{t("IMAGES_REQUIREMENT_1")}</li>
            <li>{t("IMAGES_REQUIREMENT_2")}</li>
            <li>{t("IMAGES_REQUIREMENT_3")}</li>
            <li>{t("IMAGES_REQUIREMENT_4")}</li>
          </ul>
        </div>
        <div className="spacerVertical smHide" />
        <div className="col-7  col-sm-12 noPadding flexGap">
          <div className="col-5 noPadding">
            <DragAndDropFile
              height="100%"
              minifiedStyle
              accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
              files={methods.watch("images")}
              setFiles={(images: any[]) => methods.setValue("images", images)}
            />
          </div>
          <div className="col-7 noPadding">
            <ImagesFormList
              images={methods.watch("images")}
              setImages={(images: any[]) => methods.setValue("images", images)}
              minImages={6}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

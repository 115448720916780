import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCopy } from "react-icons/fa";
import ToolTip from "../Tooltip/Tooltip";

type TCopyCLipboard = {
  render?: ReactNode;
  toCopy: string;
  hideTooltip?: boolean;
};
export const CopyClipboard = ({
  toCopy,
  render,
  hideTooltip,
}: TCopyCLipboard) => {
  const [t] = useTranslation("global");
  const clipboard = async () => {
    try {
      await navigator.clipboard.writeText(toCopy);

      showToastSuccess(t("CLIPBOARD_SUCCESS"));
    } catch (err) {
      showToastError(err);
    }
  };
  return hideTooltip ? (
    <>
      <div className="textLine pointer flexGap center" onClick={clipboard}>
        {render} <FaRegCopy />
      </div>
    </>
  ) : (
    <ToolTip title={t("COPY_CLIPBOARD")} position="Right">
      <div className="textLine pointer" onClick={clipboard}>
        {render} <FaRegCopy />
      </div>
    </ToolTip>
  );
};

import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { isUSA } from "@/constants/core";
import { getPurchases } from "@/http";
import { useAuth } from "@/store/auth";
import { hasPermission, isAdmin } from "@/utils/validations/permissions";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { PurchaseListRow } from "./PurchaseListRow";
import styles from "./styles.module.scss";

export const PurchaseListTable = ({ quotes }: { quotes?: boolean }) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const [search] = useSearchParams(
    "page=0&sortBy=createdAt&sortDirection=DESC"
  );
  const currentPage = +search.get("page")!;
  const purchaseNumberFilter = search.get("purchaseNumber");
  const supplierIdFilter = search.get("supplierId");
  const sortBy = search.get("sortBy");
  const sortDirection = search.get("sortDirection");
  const isProcurementFilter = search.get("isProcurement") === "true";
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const supplierFilter = search.get("supplier");

  const { user, isCommercial, commercial, role } = useAuth();
  const isSale = !isProcurementFilter && !quotes;

  const hasMultiplePermissions = hasPermission(isCommercial, role, [
    "action.purchase.show-all-purchases",
  ]);

  const swrData = useSWR(
    {
      key: `clients${currentPage}${purchaseNumberFilter}${supplierIdFilter}${sortBy}${sortDirection}${isProcurementFilter}${statusFilters}${supplierFilter}`,
      currentPage,
      purchaseNumberFilter,
      supplierIdFilter,
      sortBy,
      sortDirection,
      isProcurementFilter,
      statusFilters,
      supplierFilter,
    },
    ({ currentPage: page }) => {
      const commercialId = commercial?.id;
      const showAllPurchases =
        hasMultiplePermissions || isAdmin(isCommercial, role);

      const filter = supplierIdFilter
        ? ""
        : Object.entries(statusFilters ?? "")
            .filter(([, value]) => value !== "")
            .map(([key, value]) => {
              if (key === "status" && value === "IS_INVOICED") {
                return "hasInvoice:false,status:PENDING;DELIVERED;DONE;IN_TRANSIT";
              }
              return `${key}:${value}`;
            })
            .join(",");

      const supplierSegment = !isProcurementFilter
        ? `supplierId:${user.id}`
        : supplierFilter
        ? `supplierId:${supplierFilter}`
        : "";
      const sellerSegment = supplierIdFilter
        ? `sellerId:${supplierIdFilter}`
        : "";
      const purchaseSegment = purchaseNumberFilter
        ? `text:${purchaseNumberFilter}`
        : "";
      const commercialSegment =
        !isAdmin(isCommercial, role) && !showAllPurchases
          ? `commercialId:${commercialId}`
          : "";
      const procurementSegment = isProcurementFilter
        ? `sellerId:${user.id}`
        : "";
      const filterSegment = filter.length ? filter : "";

      const search = [
        supplierSegment,
        sellerSegment,
        purchaseSegment,
        commercialSegment,
        procurementSegment,
        filterSegment,
      ]
        .filter(Boolean)
        .join(",");

      return getPurchases({
        size: 10,
        page,
        search,
        sort: `${sortBy},${sortDirection}`,
      });
    }
  );

  const { data: list, error } = swrData;

  return (
    <>
      {error ? (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <p className="bold textCenter">{t("ERROR")}</p>
        </>
      ) : !list ? (
        <Spinner />
      ) : (
        <div>
          <PaginatedTable swrData={swrData}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("CREATED_AT")}</th>
                  {quotes &&
                    hasPermission(isCommercial, role, [
                      "action.quotes.show-commercial-creation",
                    ]) && <th>{t("CREATED_BY")}</th>}
                  <th>{t(!quotes ? "PURCHASE_NUMBER" : "QUOTE_NUMBER")}</th>
                  <th className={isSale ? "leftAlign" : ""}>
                    {t("PURCHASE_PAYMENT_METHOD")}
                  </th>
                  <th>{t("TOTAL")}</th>
                  {!isProcurementFilter && (
                    <>
                      <th className="leftAlign">
                        {t(isUSA ? "LEGAL_NAME" : "CLIENT")}
                      </th>
                      <th>{t("COMMERCIAL")}</th>
                      <th>{tGlobal("ORIGIN")}</th>
                    </>
                  )}
                  {!quotes && (
                    <>
                      <th className="leftAlign">{t("PURCHASE_STATUS")}</th>
                    </>
                  )}
                  {isSale && <th>{t("INVOICED")}</th>}
                </tr>
              </thead>
              <tbody>
                {list.content.map(purchase => (
                  <PurchaseListRow key={purchase.id} purchase={purchase} />
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        </div>
      )}
    </>
  );
};

import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputV2 } from "../Input/InputV2";
import styles from "./Autocomplete.module.scss";

type TAutocomplete = {
  inputName: string;
  required?: boolean;
  inputLabel: string | ReactNode;
  customSearch?: boolean;
  inputPlaceholder: string;
  action: (choice: any) => void;
  customSearchFunction?: (search: any) => any[] | Promise<any[]>;
  choices: {
    id: string;
    name: string;
    disabled?: boolean;
    [key: string]: any;
  }[];
  allowNewValue?: boolean;
  disabled?: boolean;
};

export const Autocomplete = ({
  inputName,
  inputLabel,
  inputPlaceholder,
  choices,
  action,
  customSearch = false,
  required = false,
  customSearchFunction,
  allowNewValue = false,
  disabled = false,
}: TAutocomplete) => {
  const [options, setOptions] = useState<any[]>(choices);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const { setValue, trigger, watch } = useFormContext();
  const inputNameWatch = watch(inputName);
  const [t] = useTranslation("global");
  const fixedDivRef = useRef<HTMLDivElement>(null);

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(async () => {
      setShowOptions(true);
      if (allowNewValue) {
        action(null);
      }

      if (customSearch) {
        setOptions((await customSearchFunction?.(event.target.value)) ?? []);
      } else {
        getFilterOptions(event.target.value);
      }
    }, 250);
  };

  const getFilterOptions = async (name: string) => {
    const content = choices.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase())
    );
    setOptions(content ?? []);
    setShowOptions(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node) &&
        !fixedDivRef.current?.contains(event.target as Node)
      ) {
        if (!allowNewValue) {
          if (!inputNameWatch) {
            setValue(inputName, "");
            action(null);
          }
        }
        setShowOptions(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        if (!allowNewValue) {
          setValue(inputName, "");
          action(null);
        }
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.containerInput}>
        <div ref={fixedDivRef}>
          <InputV2
            type="text"
            name={inputName}
            label={inputLabel}
            required={required}
            placeholder={inputPlaceholder}
            onChange={onProductNameChange}
            onFocus={onProductNameChange}
            disabled={disabled}
          />
        </div>
        {showOptions &&
          createPortal(
            <div
              ref={optionsRef}
              style={{
                top: fixedDivRef.current
                  ? `${
                      fixedDivRef.current.getBoundingClientRect().top +
                      (inputLabel ? 80 : 50) +
                      (window.pageYOffset || document.documentElement.scrollTop)
                    }px`
                  : "0",
                width: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                  : "100%",
                left: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                  : "100%",
              }}
              className={styles.list}
            >
              <ul>
                {options?.map(option => (
                  <li
                    key={option.id}
                    onClick={() => {
                      action(option);
                      setShowOptions(false);
                      trigger(inputName);
                    }}
                    className={option.disabled ? styles.disabledProduct : ""}
                  >
                    <div className={styles.description}>{option.name}</div>
                  </li>
                ))}
                {!options?.length && !allowNewValue && (
                  <li>{t("NO_OPTIONS")}</li>
                )}
              </ul>
            </div>,
            document.body
          )}
      </div>
    </>
  );
};

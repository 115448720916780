import { Checkbox } from "@/components/shared/Input/Checkbox";
import { Modal } from "@/components/shared/Modal/Modal";
import {
  TPurchaseProduct,
  InvoicePayload,
  getInvoiceTypes,
  createInvoice,
  isAxiosError,
  getInvoicePDFLink,
  getInvoicesByIds,
  TPurchase,
} from "@/http";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { logError } from "@/utils/errors";
import clsx from "clsx";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { MdLocalPrintshop, MdOutlineSync } from "react-icons/md";
import { getAccountingIntegration } from "@/hooks/useIntegrations";

type Props = {
  purchase: TPurchase;
  id: string;
  type: string;
};

export const InvoiceButton = ({ purchase, id, type }: Props) => {
  const products = purchase.products;
  let invoiceId: string | undefined;
  if (products.length == products.filter(p => p.invoiceId != null).length) {
    const invoices = [...new Set(products.map(p => p.invoiceId))];
    if (invoices.length > 0 && invoices[0]) {
      invoiceId = invoices[0];
    }
  }
  return (
    <>
      {invoiceId ? (
        <>
          <DownloadInvoiceAction
            invoiceId={invoiceId}
            referenceId={id}
            type={type}
          />
        </>
      ) : (
        <>
          <GenerateInvoiceAction
            products={products}
            referenceId={id}
            type={type}
          />
        </>
      )}
    </>
  );
};

const GenerateInvoiceAction = ({
  products,
  referenceId,
  type,
}: {
  products: TPurchaseProduct[];
  referenceId: string;
  type?: string;
}) => {
  const accountingIntegration = getAccountingIntegration();

  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { getOrderDetail } = useOrderDetails();

  const onSubmitAll = async (values: any) => {
    const payload: InvoicePayload = {
      ...values,
      productIds: products.map(p => p.id),
    };

    if (submitting) return;

    setSubmitting(true);

    try {
      await toast.promise(
        createInvoice(referenceId, payload, type).then(() => {
          getOrderDetail(referenceId, user.id);
          setOpen(false);
        }),
        {
          pending: t("GENERATING"),
          success: t("INVOICE_GENERATED"),
          error: {
            render(error) {
              let msg = t("ERROR_GENERATING_INVOICE");
              if (isAxiosError(error.data)) {
                msg = error.data.response?.data.message || msg;
              }
              return msg;
            },
          },
        }
      );
    } catch (error) {
      logError(error);
    }

    setSubmitting(false);
  };

  const onSubmit = async (values: any) => {
    const payload: InvoicePayload = {
      ...values,
      productIds: [...new Set(Object.values(values.productIds))],
    };

    if (!payload.productIds.length) {
      toast.warn(t("CHOOSE_ONE_PRODUCT") as string, {
        position: "bottom-center",
        toastId: "choose-at-least-one-product",
        autoClose: 2000,
      });
      return;
    }

    if (submitting) return;

    setSubmitting(true);

    try {
      await toast.promise(
        createInvoice(referenceId, payload, type).then(() => {
          getOrderDetail(referenceId, user.id);
          setOpen(false);
        }),
        {
          pending: t("GENERATING"),
          success: t("INVOICE_GENERATED"),
          error: {
            render(error) {
              let msg = t("ERROR_GENERATING_INVOICE");
              if (isAxiosError(error.data)) {
                msg = error.data.response?.data.message || msg;
              }
              return msg;
            },
          },
        }
      );
    } catch (error) {
      logError(error);
    }

    setSubmitting(false);
  };

  const { data: types, isValidating } = useSWR(
    [user.id, "invoice-types"],
    getInvoiceTypes
  );

  const methods = useForm<InvoicePayload>({});
  return (
    <>
      {accountingIntegration?.supplier == "QUICKBOOKS" ? (
        <button
          className="outlined"
          onClick={() => onSubmitAll(true)}
          disabled={submitting}
        >
          <img
            className="imageIcon"
            src={accountingIntegration.imageUrl}
            alt="Integracion Quickbooks"
          ></img>
          <MdOutlineSync />
        </button>
      ) : (
        <button
          className="outlined"
          onClick={() => setOpen(true)}
          disabled={submitting}
        >
          <img
            className="imageIcon"
            src={accountingIntegration?.imageUrl}
            alt="Integracion Quickbooks"
          ></img>
          <MdOutlineSync />
        </button>
      )}

      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        size="sm"
        footer={
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={submitting}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("GENERATE_INVOICE")}
            </button>
          </div>
        }
      >
        <h3 className="bolder">{t("GENERATE_INVOICE")}</h3>
        <div className="divider" />
        <FormProvider {...methods}>
          <form className={styles.generateInvoiceForm}>
            {!!types && types.length > 1 && (
              <SelectV2
                name="invoiceTypeId"
                label={t("INVOICE_TYPE")}
                required
                disabled={isValidating}
                choices={types?.map(t => ({
                  value: t.value,
                  label: t.text,
                }))}
              />
            )}

            <div>
              <p className="bold">{t("SELECT_INVOICE_PRODUCTS")}</p>
              <div className="spacer" />
              {products.map((product, index) => (
                <div key={product.id}>
                  <Checkbox
                    defaultChecked={true}
                    name={`productIds[${index}]`}
                    label={product.name}
                    key={product.id}
                    value={product.id}
                    disabled={isValidating}
                  />
                </div>
              ))}
            </div>

            {accountingIntegration?.supplier == "SIIGO" && (
              <div>
                <p className="bold">{t("ADDITIONA_OPTIONS")}</p>
                <div className="spacer" />
                <Checkbox name="sendToTaxCollector" label={t("SEND_TAXES")} />
                <Checkbox
                  name="notifyClientByEmail"
                  label={t("NOTIFY_CLIENT_INVOICE")}
                />
              </div>
            )}
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

const DownloadInvoiceAction = ({
  invoiceId,
  referenceId,
  type,
}: {
  invoiceId: string;
  referenceId: string;
  type: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("orders-page");
  const accountingIntegration = getAccountingIntegration();
  const { data } = useSWR([[invoiceId], "invoiceByIds"], getInvoicesByIds);
  const invoice = data?.find(invoice => invoice.id === invoiceId);

  const handleClick = async () => {
    setLoading(true);
    try {
      await toast.promise(getInvoicePDFLink(referenceId, invoiceId, type), {
        pending: t("GENERATING_LINK") as string,
        success: {
          render: ({ data, closeToast }) => {
            return (
              <>
                {t("LINK")}:{" "}
                <a
                  className={clsx(styles.downloadLink)}
                  href={data!.url}
                  download={invoiceId + ".pdf"}
                  target="_blank"
                  rel="noreferrer"
                  onClick={closeToast}
                >
                  {t("DOWNLOAD_PDF")}
                </a>
              </>
            );
          },
          autoClose: false,
          closeOnClick: false,
        },
        error: t("ERROR_FILE") as string,
      });
    } catch (error) {
      logError(error);
    }
    setLoading(false);
  };
  return (
    <button
      className="outlined"
      onClick={() => handleClick()}
      disabled={loading}
    >
      <span>{invoice?.name} </span>
      <img
        className="imageIcon"
        src={accountingIntegration?.imageUrl}
        alt="Integracion Quickbooks"
      ></img>
      <MdLocalPrintshop />
    </button>
  );
};

import { Modal } from "@/components/shared/Modal/Modal";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getPurchaseInvoicePDF, QUOTED, TPurchase } from "@/http";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { showToastError } from "@/hooks/useToast";
import { TermsAndConditios } from "@/components/profile/TermsAndConditions/TermsAndConditions";

type TDownloadInvoicePDF = {
  purchase: TPurchase;
};

export const DownloadInvoicePDF = ({ purchase }: TDownloadInvoicePDF) => {
  const [t] = useTranslation("global");
  const type = purchase.status === QUOTED ? "QUOTATION" : "ORDER";
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const defaultValues = {
    hasPricing: user.showPricingInfoOnInvoice,
    hasImages: false,
    sendToClient: true,
  };
  const methods = useForm({ defaultValues });

  const onClose = () => {
    setIsOpen(false);
    methods.reset(defaultValues);
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const file = await getPurchaseInvoicePDF(purchase.id, {
        ...values,
        termAndConditions: user.termsAndCondition ?? "",
        type,
      });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = purchase.purchaseNumber + "_invoice";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
      onClose();
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button className="outlined" onClick={() => setIsOpen(true)}>
        {t("DOWNLOAD_PDF")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("DOWNLOAD_PDF")}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("DOWNLOAD")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <Checkbox name="hasPricing" label={t("INVOICE_PRICING")} />
          <Checkbox name="hasImages" label={t("INVOICE_IMAGES")} />
          <Checkbox name="sendToClient" label={t("INVOICE_SENT_CLIENT")} />
          <TermsAndConditios />
          <div className="spacer" />
        </FormProvider>
      </Modal>
    </div>
  );
};

import { FC } from "react";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import styles from "./styles.module.scss";
import { Table, TableCell } from "../clients/ui/Table/Table";
import { useCommercials } from "@/store/commercials";
import { CommercialModal } from "./CommercialModal";
import ToolTip from "../shared/Tooltip/Tooltip";
import { CommercialDelete } from "./CommercialDelete";
import { useAuth } from "@/store/auth";
import { useSupplierRoleByID } from "@/hooks/useRole";
import { useTranslation } from "react-i18next";
import { CommercialStatus } from "@/pages/Commercials/CommercialStatus";

export const CommercialsTable: FC = () => {
  const [t] = useTranslation("global");
  const { commercials, currentPage, totalPages, setCurrentPage } =
    useCommercials();

  return (
    <>
      <Table records={commercials}>
        <TableCell label={t("COMMERCIALS.FIRST_NAME")} source="firstName" />
        <TableCell label={t("COMMERCIALS.LAST_NAME")} source="lastName" />
        <TableCell label={t("COMMERCIALS.EMAIL")} source="email" />
        <TableCell label={t("COMMERCIALS.PHONE")} source="phone" />
        <TableCell
          label={t("COMMERCIALS.ROLE_NAME")}
          render={commercial => <RoleName roleId={commercial.roleId} />}
        />
        <TableCell
          label={t("STATUS")}
          render={commercial => <CommercialStatus commercial={commercial} />}
        />
        <TableCell
          label={t("ACTIONS")}
          render={commercial => (
            <div className={styles.actions}>
              <CommercialModal
                commercial={commercial}
                button={
                  <ToolTip
                    title={t("COMMERCIALS.COMMERCIAL_EDIT")}
                    position="Left"
                  >
                    <BiEdit />
                  </ToolTip>
                }
              />
              <CommercialDelete commercial={commercial} />
            </div>
          )}
        />
      </Table>
      <div className={styles.paginate}>
        <ReactPaginate
          forcePage={currentPage}
          onPageChange={p => setCurrentPage(p.selected)}
          pageCount={totalPages}
          previousLabel="←"
          nextLabel="→"
          marginPagesDisplayed={3}
          containerClassName={styles.pagination}
          previousLinkClassName={styles.previous}
          nextLinkClassName={styles.next}
          disabledClassName={styles.disabled}
          activeClassName={styles.selected}
          breakClassName={styles.break}
        />
      </div>
    </>
  );
};

const RoleName = ({ roleId }: { roleId: string }) => {
  const { user } = useAuth();
  const role = useSupplierRoleByID(user.id, roleId);
  return <>{role?.name}</>;
};

import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { Card } from "@/components/shared/Card/Card";
import { isUSA } from "@/constants/core";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSupplierVariantV2, updateProductV2 } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { uploadImageProductObject } from "@/utils/image-upload";
import { generateSlug } from "@/utils/slug";
import { get } from "lodash-es";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ProductStockDetail } from "../ProductDetail/ProductStockDetail";
import { ImagesInputs } from "./ImagesInputs";
import { NameInputs } from "./NameInputs";
import { PriceInputs } from "./PriceInputs";
import styles from "./styles.module.scss";

export const ProductCreationForm = ({
  productId = "",
}: {
  productId?: string;
}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const methods = useFormContext();
  const { selectedVariantEdit } = useProducts();
  const [t] = useTranslation("products-page");
  const { user } = useAuth();
  const { id: supplierId, marginSeeri = 0 } = user;

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const payload: Record<string, any> = {
        ...values,
        longDescription: values?.description,
        status: true,
        images: values?.images ?? [],
        slug: generateSlug(values?.name),
        taxesType:
          values?.chargeTaxes && values?.taxesType
            ? [values?.taxesType]
            : ["IVA_0"],
        supplierId,
        supplierPrice: 0,
        unitPrice: isUSA ? values?.unitPrice : null,
        marginSeeri,
        disableInStockOut: true,
        securityStock: values?.useSecurityStock ? values.securityStock : 0,
        availableStock: values?.stock,
      };

      if (payload?.images?.length) {
        await Promise.all(payload?.images?.map(uploadImageProductObject)).then(
          images => {
            const priorities = values.imagesPriority || [];
            const mappedImages = images?.map((i: any, idx: number) => ({
              ...i,
              priority: priorities?.[idx],
            }));
            payload.media = mappedImages;
            payload.images = mappedImages;
          }
        );
      }

      if (productId) {
        const warehouses = Object.keys(values?.overStock ?? {}).map(id => ({
          id: Number(id),
          ...get(values, `overStock.${id}`, {
            overStock: false,
          }),
        }));
        payload.availableWarehouses = warehouses;
        await updateProductV2(productId, payload as any);
        showToastSuccess(t("PRODUCT_UPDATED"));
      } else {
        await createSupplierVariantV2(payload);
        showToastSuccess(t("PRODUCT_CREATED"));
      }
      navigate(-1);
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  const formId = "ProductCreationForm";

  return (
    <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
      <NameInputs />
      <div className="spacer" />
      <ImagesInputs />
      <div className="spacer" />
      <PriceInputs isEdit={!!productId} />
      <div className="spacer" />

      <Card>
        <RecordCustomAttributes appliesTo="PRODUCTS" forForm />
      </Card>

      {!!productId && selectedVariantEdit?.suppliers?.length > 0 && (
        <>
          <div className="spacer" />
          <Card>
            <div className="col-12 noPadding">
              <ProductStockDetail
                suppliers={selectedVariantEdit?.suppliers || []}
                isSupplierBrand
                isEdit
              />
            </div>
          </Card>
          <div className="spacer" />
        </>
      )}

      {createPortal(
        <div className={styles.footer}>
          <div className="spacerVertical" />
          <button
            type="button"
            className="outlined md"
            onClick={() => navigate(-1)}
            disabled={isLoading}
          >
            {t("CANCEL")}
          </button>
          <button
            type="submit"
            form={formId}
            className="primary md"
            disabled={isLoading}
          >
            {t(productId ? "SAVE" : "CREATE")}
          </button>
        </div>,
        document.body
      )}
    </form>
  );
};

import { Checkbox } from "@/components/shared/Input/Checkbox";
import { ModalSide } from "@/components/shared/Modal/ModalSide/ModalSide";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierRole,
  getSupplierPermissions,
  TSupplierRole,
  updateSupplierRole,
} from "@/http";
import { useAuth } from "@/store/auth";
import { useRoles } from "@/store/roles";
import { get } from "lodash-es";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputV2 } from "../../shared/Input/InputV2";
import styles from "../styles.module.scss";

type Permission = {
  description: string;
  id: string;
  key: string;
  name: string;
  selected: boolean;
};

type TRoleModal = {
  role?: any;
  onDone?: (role: TSupplierRole) => void;
  button?: ReactNode;
};
export const RoleModal = ({ role, onDone, button }: TRoleModal) => {
  const [t] = useTranslation("global");
  const isEdit = !!role?.id;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const defaultValues = role?.id
    ? {
        ...role,
        permissionsMap: (role.permissions ?? []).reduce(
          (acc: any, currentValue: any) => {
            const [permissionType, module, action] = currentValue.split(".");
            if (!acc[permissionType]) {
              acc[permissionType] = {};
            }
            if (!acc[permissionType][module]) {
              acc[permissionType][module] = {};
            }
            acc[permissionType][module][action] = true;

            return acc;
          },
          {} as { [key: string]: { [key: string]: { [key: string]: boolean } } }
        ),
      }
    : {};
  const methods = useForm({ defaultValues });
  const { getRoles } = useRoles();
  const { user } = useAuth();
  const rolePermissionsOrigin = get(role, "permissions", []);
  const [permissionsBuilded, setPermissionsBuilded] = useState<any>({});

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { name, description, permissionsMap = {} } = values;
      let newRole;

      const permissions = destructPermissions(permissionsMap);

      if (isEdit) {
        newRole = await updateSupplierRole(user.id, role.id, permissions);
      } else {
        newRole = await createSupplierRole(user.id, {
          name,
          description,
          key: name,
          permissions,
        });
      }

      getRoles(user.id);
      setIsLoading(false);
      methods.reset(defaultValues);
      onDone?.(newRole);
      showToastSuccess(
        t(isEdit ? "COMMERCIALS.UPDATED_ROLE" : "COMMERCIALS.CREATED_ROLE")
      );
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  const buildPermissions = (content: any[] = []) => {
    const permissionsObject: any = {};
    content.forEach(p => {
      const [type, module, action] = p.key.split(".");

      if (!permissionsObject[type]) {
        permissionsObject[type] = {};
      }

      if (!permissionsObject[type][module]) {
        permissionsObject[type][module] = {};
      }

      permissionsObject[type][module][action] = {
        ...p,
        selected: rolePermissionsOrigin.includes(p.key),
      };
    });

    setPermissionsBuilded(permissionsObject);
  };

  const destructPermissions = (obj: any, path: string[] = []): string[] => {
    let result: string[] = [];

    for (const key in obj) {
      const newPath = [...path, key];

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        result = result.concat(destructPermissions(obj[key], newPath));
      } else if (obj[key] === true) {
        result.push(newPath.join("."));
      }
    }

    return result;
  };

  useEffect(() => {
    setIsValidating(true);
    getSupplierPermissions({ page: 0, size: 1000 })
      .then(({ content }) => {
        buildPermissions(content);
      })
      .catch(() => {
        buildPermissions([]);
      })
      .finally(() => setIsValidating(false));
  }, []);

  return (
    <>
      {button ? (
        <div
          className="pointer"
          onClick={() => {
            methods.reset(defaultValues);
            setIsOpen(true);
          }}
        >
          {button}
        </div>
      ) : (
        <button
          className="primary lg"
          onClick={() => {
            methods.reset(defaultValues);
            setIsOpen(true);
          }}
        >
          {t(isEdit ? "COMMERCIALS.EDIT_ROLE" : "COMMERCIALS.CREATE_ROLE")}
        </button>
      )}
      <ModalSide
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          methods.reset(defaultValues);
        }}
        title={t(isEdit ? "COMMERCIALS.EDIT_ROLE" : "COMMERCIALS.CREATE_ROLE")}
      >
        <div className="leftAlign">
          {isValidating ? (
            <Spinner />
          ) : (
            <>
              <FormProvider {...methods}>
                <div className="flexColumn pw1">
                  <InputV2
                    name="name"
                    label={t("COMMERCIALS.NAME")}
                    required
                    disabled={isEdit}
                  />

                  <InputV2
                    name="description"
                    label={t("COMMERCIALS.DESCRIPTION")}
                    required
                    disabled={isEdit}
                  />
                  <p className="bold">{t("COMMERCIALS.SELECT_PERMISSIONS")}</p>
                </div>
                {Object.keys(permissionsBuilded).map(t => (
                  <PermissionType
                    key={t}
                    permissionType={t}
                    modules={permissionsBuilded[t]}
                  />
                ))}
              </FormProvider>
            </>
          )}
        </div>
        <div className="rigthAlign pw1">
          <div className="spacer" />
          <button
            disabled={isLoading || isValidating}
            onClick={() => {
              setIsOpen(false);
              methods.reset(defaultValues);
            }}
          >
            {t("CLOSE")}
          </button>
          <button
            disabled={isLoading || isValidating}
            className="purple lg"
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t(isEdit ? "SAVE" : "CREATE")}
          </button>
          <div className="spacer" />
        </div>
      </ModalSide>
    </>
  );
};

type TPermissionType = {
  permissionType: string;
  modules: { [key: string]: { [key: string]: Permission } };
};

const PermissionType = ({ permissionType, modules }: TPermissionType) => {
  const [t] = useTranslation("global");

  return (
    <div>
      <div className={styles.typeContainer}>
        <p className="bold">
          {t(`PERMISSION.TYPE.${permissionType.toUpperCase()}`)}
        </p>
      </div>
      {Object.keys(modules).map(m => (
        <PermissionModule
          key={m}
          module={m}
          actions={modules[m]}
          permissionName={`permissionsMap.${permissionType}`}
        />
      ))}
    </div>
  );
};

type TPermissionModule = {
  module: string;
  actions: { [key: string]: Permission };
  permissionName: string;
};

const PermissionModule = ({
  module,
  actions,
  permissionName,
}: TPermissionModule) => {
  const [t] = useTranslation("global");

  return (
    <div className="pw1">
      <p className="bold">{t(`PERMISSION.MODULE.${module.toUpperCase()}`)}</p>
      {Object.keys(actions).map((a, idx) => (
        <>
          <PermissionAction
            key={a}
            action={a}
            permission={actions[a]}
            permissionName={`${permissionName}.${module}`}
          />
          {idx === Object.keys(actions)?.length - 1 && (
            <div className="divider" />
          )}
        </>
      ))}
    </div>
  );
};

type TPermissionAction = {
  action: string;
  permission: Permission;
  permissionName: string;
};

const PermissionAction = ({
  action,
  permission,
  permissionName,
}: TPermissionAction) => {
  return (
    <div>
      <Checkbox
        name={`${permissionName}.${action}`}
        label={
          <>
            <p className="bold mdFont">{permission.name}</p>
            <span className="mdFont">{permission.description}</span>
          </>
        }
      />
    </div>
  );
};
